<template>
    <div
      class="user-increase flex-center"
      @mouseout="play"
      @mouseenter="pause"
      @mouseover="pause"
    >
      <div class="chart-box" v-if="!isEmpty">
        <!-- 左边提示 -->
        <div class="left-tips">
          <!-- 正式会员 -->
          <div class="label-box">
            <div class="top formal">
              总收入<span>{{ this.responseData.compare_data.total }}</span
              >元
            </div>
            <div class="bottom">
              <div class="ratio">
                环比
                <!-- 下降  1 -->
                <!-- 上升  2 -->
                <span
                  v-if="this.responseData.compare_data.status"
                  :style="{
                    color:
                      this.responseData.compare_data.status == 1
                        ? '#f0c475'
                        : '#fc9697',
                  }"
                  >{{ this.responseData.compare_data.diff_count }}%</span
                >
                <!-- 无变化  0 -->
                <span v-else
                  >{{ this.responseData.compare_data.diff_count }}%</span
                >
                <img
                  v-if="this.responseData.compare_data.status === 2"
                  class="down-icon"
                  src="../../../assets/users-stat/down.png"
                /><img
                  v-else-if="this.responseData.compare_data.status === 1"
                  class="up-icon"
                  src="../../../assets/users-stat/up.png"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- 会员统计 -->
        <div class="chart" :id="randomId" />
      </div>
      <div v-if="isEmpty" class="flex-1 flex-center">
        <data-empty />
      </div>
    </div>
  </template>
  
  <script>
  import * as echarts from "echarts/core";
  import { GridComponent, LegendComponent } from "echarts/components";
  import { LineChart } from "echarts/charts";
  import { UniversalTransition } from "echarts/features";
  import { CanvasRenderer } from "echarts/renderers";
  import DataEmpty from "../../DataEmpty";
  import { getMemberFeeStats } from "../../../api/templates/order-stats";
  import {
    getRecentDay_Date,
    getRecentMonth_Date,
  } from "../../../tool/time-range";
  import template from "@/modules/big-data/mixins/list-template";
  
  echarts.use([
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent,
    UniversalTransition,
  ]);
 
  export default {
    name: "MemberFeeStats",
    components: { DataEmpty },
    mixins: [template],
    inject: ["getData", "grid", "disabled"],
    data() {
      return {
      
      };
    },
    computed: {
      config() {
        return (
          this.getData().config || {
            chart_type: {
              value: {
                key: "line_chart",
                name: "折线图",
              },
            },
          }
        );
      },
      
      isEmpty() {
        return !this.responseData;
      },
    },
    watch: {
      config: {
        handler(val) {
          if (this.lastType && val.chart_type.value.key !== this.lastType) {
            // console.log('member fee chart_type change', val.chart_type.value)
            this.myChart.clear();
            this.myChart = null;
            this.initOptions();
          }
        },
        deep: true,
      },
    },
    created() {
      // console.log(
      //   getRecentDay_Date(7),
      //   getRecentDay_Date(-1),
      //   getRecentMonth_Date(-1)
      // );
    },
    beforeDestroy() {
      this.stopAnimation();
    },
    methods: {
      getShowData(query, callback = () => {}) {
        this.stopAnimation();
  
        getMemberFeeStats({
          ...query,
        })
          .then((res) => {
            this.responseData = res.data;
  
            this.$nextTick(() => {
              this.initOptions();
              // console.log(this.responseData, "会员统计");
            });
  
            callback();
          })
          .catch((err) => {
            console.log(err);
            callback();
          });
      },
      initOptions() {
   
        const type = this.config.chart_type.value?.key || "line_chart";
        this.lastType = type;
        console.log(type);
        var chartDom = document.getElementById(this.randomId);
        if (this.myChart) {
          this.myChart.off("mouseover");
          this.myChart.off("mouseoout");
          this.myChart.off("mouseenter");
        }
        var myChart = echarts.init(chartDom, "dark");
        var option;
  
        if (type === "bar_chart") {
          option = this.getBarOption();
        } else if (type === "line_chart") {
          option = this.getLineOption();
        }
        // console.log(option, myChart);
        option && myChart.setOption(option);
        this.myChart = myChart;
        let that = this;
        myChart.on("mouseover", function () {
          console.log("echarts mouseover");
          that.pause();
        });
        myChart.on("mouseenter", function () {
          console.log("echarts mouseenter");
          that.pause();
        });
        // myChart.on('mouseout', function() {
        //   console.log('echarts mouseout')
        //   that.play()
        // })
  
        // TODO: 动画开关
        setTimeout(() => {
          if (!this.handle) this.animate();
        }, 3000);
      },
      animate() {
        if (this.grid && !this.disabled) return;
        if (this.grid) {
          this.buildTimer({
            each: (anchor) => {
              this.myChart.dispatchAction({
                type: "showTip",
                // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
                seriesIndex: 0,
                // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
                dataIndex: anchor,
                gridIndex: 0,
              });
            },
            getEndStatus: (anchor) => {
              let flag = anchor === this.responseData?.list?.length
              // console.log(flag)
              return flag;
            },
          });
        }
      },
      getLineOption() {
        return {
          backgroundColor: "rgba(3, 23, 26, .5)",
          title: {
            text: "",
          },
          tooltip: {
            trigger: "axis",
          },
          // dataZoom: [{
          //   type: 'slider',
          //   show: true, //flase直接隐藏图形
          //   xAxisIndex: [0],
          //   left: '9%', //滚动条靠左侧的百分比
          //   bottom: 5,
          //   start: 0,//滚动条的起始位置
          //   end: 50 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
          // }],
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          toolbox: {
            show: false,
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            data: this.responseData.list.map((el) => el.day_time),
          },
          yAxis: {
            type: "value",
  
          },
          legend: {
            data: ["会费统计"],
          },
          series: (() => {
            // const color = [
            //   "#5470c6",
            //   "#91cc75",
            //   "#fac858",
            //   "#ee6666",
            //   "#73c0de",
            //   "#3ba272",
            //   "#fc8452",
            //   "#9a60b4",
            //   "#ea7ccc",
            // ];
            // return this.responseData.list.map((r, rI) => {
            //   const dataList = r.data.map((el) => el.count);
            //   return {
            //     name: this.config.user_type[rI].name,
            //     type: "line",
            //     // smooth: true,
            //     data: dataList,
            //     areaStyle: {
            //       opacity: 0.8,
            //       color: new echarts.graphic.LinearGradient(0, 0, 0, 2, [
            //         {
            //           offset: 0,
            //           color: color[rI],
            //         },
            //         {
            //           offset: 1,
            //           // color: 'rgba(255, 255, 255, 0)'
            //           color: "transparent",
            //         },
            //       ]),
            //     },
            //   };
            // });
            return [
              {
              
                type: "line",
                // smooth: true,
                data: this.responseData.list.map((el) => el.count),
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 2, [
                    {
                      offset: 0,
                      color: "#4bd2ed",
                    },
                    {
                      offset: 1,
                      // color: 'rgba(255, 255, 255, 0)'
                      color: "transparent",
                    },
                  ]),
                },
              },
              ,
            ];
          })(),
        };
      },
      getBarOption() {
        const color = [
          "#4bd2ed",
          "#4bed9c",
          "#fac858",
          "#ee6666",
          "#73c0de",
          "#3ba272",
          "#fc8452",
          "#9a60b4",
          "#ea7ccc",
        ];
        return {
          backgroundColor: "rgba(3, 23, 26, .5)",
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            bottom: "15%",
            left: 70,
            right: 30,
          
          },
          // color: [
          //   '#5470c6', '#91cc75', '#fac858',
          //   '#ee6666', '#73c0de', '#3ba272',
          //   '#fc8452', '#9a60b4', '#ea7ccc'
          // ],
          // dataZoom: [{
          //   type: 'slider',
          //   show: true, //false直接隐藏图形
          //   xAxisIndex: [0],
          //   left: '9%', //滚动条靠左侧的百分比
          //   bottom: -5,
          //   start: 0,//滚动条的起始位置
          //   end: 50 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
          // }],
          xAxis: {
            type: "category",
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            data: (() => {
              return this.responseData.list.map((r) => {
                return r.day_time;
              });
            })(),
          },
          yAxis: {
            type: "value",
          },
          
          series: (() => {
            //
            let Arr = () => {
              let dataList = this.responseData.list.map((item, index) => {
                return {
                  value: item.count,
                  itemStyle: {
                    color: color[index % color.length-1],
                  },
                };
              });
              return {
                type: "bar",
                data: dataList,

              };
            };
           
            return [Arr()];
          })(),
        };
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .user-increase {
    flex: 1;
    min-height: 0;
  }
  .chart-box {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0.16em;
    .left-tips {
      margin-right: 0.16em;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .label-box {
        min-width: 2.18em;
        height: 1em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: url("~@/modules/big-data/assets/users-stat/bg.png") center;
        background-size: 100% auto;
        padding-left: 0.38em;
        padding-top: 0.19em;
        padding-bottom: 0.19em;
  
        .top {
          font-size: 0.14em;
          font-weight: 500;
          color: #fff;
          position: relative;
          &::before {
            content: "";
            display: block;
            position: absolute;
            width: 0.42em;
            height: 0.42em;
            top: 50%;
            left: -0.85em;
            transform: translateY(-50%);
            background: #4bd2ed;
            box-shadow: 0 0 0.42em 0 #4bd2ed;
            border-radius: 50%;
          }
          &.pre::before {
            background: #4bed9c;
            box-shadow: 0 0 0.06rem 0 #4bed9c;
          }
          span {
            font-size: 1.28em;
            padding: 0 0.85em;
          }
        }

        .bottom {
          display: flex;
          align-items: center;
          .ratio {
            font-size: 0.14em;
            font-weight: 500;
            color: #fff;
            display: flex;
            align-items: center;
            span {
              color: #f1c56f;
              font-size: 1.28em;
              padding: 0 0.66em;
            }
            .up-icon {
              width: 0.85em;
              height: 0.85em;
              animation: 0.7s up infinite;
            }
  
            .down-icon {
              width: 0.85em;
              height: 0.85em;
              animation: 0.7s down infinite;
            }
  
            @keyframes up {
              from {
                transform: translateY(0%);
                opacity: 1;
              }
  
              to {
                transform: translateY(-25%);
                opacity: 0.85;
              }
            }
  
            @keyframes down {
              from {
                transform: translateY(0%);
                opacity: 1;
              }
  
              to {
                transform: translateY(25%);
                opacity: 0.85;
              }
            }
          }
        }
      }
    }
    .chart {
      flex: 1;
    }
  }
  </style>
  