import store from '@/base/store'
import axios from 'axios'

// 如果需要"全国"选项
// {
//   "value": "100000",
//   "label": "全国"
// },
// const nativePlace = require("@/modules/big-data/data/address.json");
let nativePlace = []

export default {
  index: 'member_map',
  form: {
    map_config: [['全国']],
    data_type: 'personal_user',
    data_show: {
      personal_user: {
        label: '',
        options: [''],
        value: '',
      },
      unit_user: {
        label: '',
        options: [''],
        value: '',
      },
    },
  },
  async getRules(value) {
    /* 禁用第三级 */
    // nativePlace.forEach(n => {
    //   if (n.children?.length) {
    //     const levelTwo = n.children
    //     levelTwo.forEach(nn => {
    //       if (nn.children?.length) {
    //         const levelThree = nn.children
    //         levelThree.forEach(nnn => {
    //           nnn.disabled = true
    //         })
    //       }
    //     })
    //   }
    // })

    const baseUrl = window.serverConfig.VUE_APP_ADMINURL

    await new Promise((resolve) => {
      axios
        .get(baseUrl + '/big-data/data/address.json?key=' + this.$store.state.tenant.id)
        .then(({ data }) => {
          resolve(data)
          nativePlace = data
        })
        .catch((err) => {
          resolve(err)
        })
    })

    /*
     * Promise.resolve
     * */
    return [
      // {
      //   type: "div",
      //   props: {
      //     class: 'group-title'
      //   },
      //   children: [
      //     '地图设置'
      //   ]
      // },
      {
        type: 'ElCascader',
        field: 'map_config',
        title: '地图区域',
        display: true,
        props: {
          style: 'width: 100%;',
          options: nativePlace,
          props: {
            // expandTrigger: "hover",
            label: 'label',
            value: 'label',
            // 为 true 表示每一级都可单独勾选，false 可以全选下一级
            // checkStrictly: true,
            multiple: true,
          },
          collapseTags: true,
        },
        on: {
          change: () => {
            // console.log(`change!!"${JSON.stringify(value().form)}"`);
            const map_config = value().form.map_config
            if (map_config.length > 1 && map_config[0][0] === '全国') {
              value().form.map_config = map_config.slice(0, 1)
            }
          },
        },
      },
      {
        type: 'select',
        field: 'data_type',
        title: '会员类型' || '地图区域数据类型',
        props: {
          style: 'width: 100%;',
        },
        children: (() => {
          const options = [
            {
              label: '个人会员',
              value: 'personal_user',
            },
            {
              label: '单位会员',
              value: 'unit_user',
            },
          ]
          return options.map((op) => {
            return {
              type: 'ElOption',
              props: {
                label: op.label,
                value: op.value,
              },
            }
          })
        })(),
        update: (v) => {
          const fApi = value()
          const dataShow = fApi.rule[2]
          dataShow.props.rule[0].display = v === 'personal_user'
          dataShow.props.rule[1].display = v === 'unit_user'
        },
      },
      {
        type: 'object',
        field: 'data_show',
        value: { personal_user: {}, unit_user: {} },
        display: true,
        props: {
          rule: [
            // {
            //   type: "div",
            //   props: {
            //     class: 'group-title'
            //   },
            //   children: [
            //     '个人会员数据设置'
            //   ]
            // },
            {
              type: 'select',
              field: 'personal_user',
              title: '数据设置' || '个人会员数据设置' || '数据展示',
              display: true,
              props: {
                style: 'width: 100%;',
                valueKey: 'value',
              },
              children: (() => {
                const data_show = store.state.bigDataDesign.data_show
                const options = data_show.member_map_personal_user?.list || []
                return options.map((op) => {
                  return {
                    type: 'ElOption',
                    props: {
                      label: op.label,
                      value: op,
                      key: op.value,
                    },
                  }
                })
              })(),
            },
            // {
            //   type: "div",
            //   props: {
            //     class: 'group-title'
            //   },
            //   children: [
            //     '单位会员数据设置'
            //   ]
            // },
            {
              type: 'select',
              field: 'unit_user',
              title: '数据设置' || '单位会员数据设置' || '数据展示',
              display: false,
              props: {
                style: 'width: 100%;',
                valueKey: 'value',
              },
              children: (() => {
                const data_show = store.state.bigDataDesign.data_show
                const options = data_show.member_map_unit_user?.list || []
                return options.map((op) => {
                  return {
                    type: 'ElOption',
                    props: {
                      label: op.label,
                      value: op,
                      key: op.value,
                    },
                  }
                })
              })(),
            },
          ],
        },
      },
    ]
  },
}
