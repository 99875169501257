/* 列表模板通用 vue 选项属性 */

import { randomString } from "@/base/utils/tool";

export default {
  data() {
    return {
      responseData: null,
      randomId: randomString(16),
    };
  },
  computed: {
    listData() {
      return this.responseData?.list?.data || this.responseData?.list || this.responseData || [];
    },
    isEmpty() {
      return !this.listData.length;
    },
  },
  created() {
    this.timer = null;
    this.anchor = 0;
  },
  beforeDestroy() {
    console.log("before destroy");
    this.stopAnimation();
  },
  methods: {
    resize() {
      if (this.myChart) {
        setTimeout(() => {
          this.myChart.resize();
        }, 500);
      }
    },
    pause() {
      // 手动切换状态
      this.handle = true;
      // console.log("动画被鼠标事件打断");
      // TODO: 暂停动画，但不想将 anchor 进度清空
      if (this.timer) {
        clearInterval(this.timer);
        console.log("clear interval");
      }
      this.myChart?.dispatchAction({
        type: "hideTip",
      });
    },
    play() {
      this.handle = false;
      // console.log('动画被鼠标事件打断后恢复')
      this.animate();
    },
    toggleDesc(e) {
      if (!this.grid) return;
      this.$emit("update", { is_desc: e });
    },
    // 停止动画，重置锚点
    stopAnimation() {
      if (this.grid && this.disabled) this.scrollIntoViewByIndex(0);
      this.myChart?.dispatchAction({
        type: "hideTip",
      });
      let timer = this.timer;
      if (timer) {
        clearInterval(timer);
        this.timer = null;
      }
      this.anchor = 0;
    },
    // 滑动到对应索引的元素
    scrollIntoViewByIndex(index = 0) {
      if (this.isEmpty) return;
      if (this.listData[index]?.id) {
        // 根据id获取元素 适配活跃会员
        let curId = this.listData[index]?.id;
        const element = document.getElementById(curId);
        element?.scrollIntoView({
          behavior: "smooth",
        });
      }
    },
    buildTimer(options = {}) {
      options = Object.assign(
        {
          interval: 1500,
          end: () => {
            this.stopAnimation();
            this.scrollIntoViewByIndex();
            // this.$emit('update')
            setTimeout(() => {
              this.animate();
            }, 1500);
          },
          getEndStatus: (anchor) => {
            return anchor === this.listData.length;
          },
          each: (anchor) => {
            this.scrollIntoViewByIndex(anchor);
          },
        },
        options
      );
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        // 鼠标经过停止
        if (this.handle) {
          this.pause();
          return;
        }
        // 到最后一个了，不滚了
        if (options.getEndStatus(this.anchor)) {
          options.end(this.anchor);
          return;
        }
        // if (this.getData().index === 'new_member') {
        //   console.log('anchor********', this.anchor)
        // }
        options.each(this.anchor);
        this.anchor++;
      }, options.interval);
    },
  },
};
