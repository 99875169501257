<template>
  <div
    class="template-card flex-col"
    :class="[`template-card-${index}`]"
    :style="{
      background:
        false && data.index === 'member_map' ? 'unset !important' : '',
    }"
  >
    <div class="preview" />
    <div
      v-if="width"
      class="preview absolute"
      :class="{ grid }"
      :style="{
        transform: w ? `scale(${width / w})` : '',
        top: grid ? 0 : '12px',
        width: grid ? `${w}px` : '',
        height: grid ? `${h}px` : '',
      }"
      v-loading="loading"
    >
      <div
        class="content flex-col go-border-box"
        :style="{
          // 方便子元素用 em 做自适应单位
          fontSize: false && grid ? 100 * (width / 460) + 'px' : '100px',
        }"
        :class="{
          fullscreen: false && data.index === 'member_map' && data.w >= 2,
        }"
      >
        <!--        <member-map-border v-if="data.index === 'member_map' && data.w >= 2 && w && h" :w="w" :h="h" />-->

        <div
          v-if="true || !(data.index === 'member_map' && data.w >= 2)"
          class="bg-left"
        />
        <div class="content-head flex-between">
          <div
            class="title-box"
            :style="{
              visibility:
                true || !(data.index === 'member_map' && data.w >= 2)
                  ? 'visible'
                  : 'hidden',
            }"
          >
            <h2 class="title">{{ data.name }}</h2>
          </div>
          <div class="content-head-right flex">
            <div
              v-if="grid && !disabled"
              class="btn remover flex-center cursor-pointer"
              @click.stop="$emit('delete')"
            >
              <img
                class="btn-icon"
                :src="require('../assets/delete-template.png')"
              />
              <span class="btn-name">删除</span>
            </div>
            <!-- 用户增长 -->
            <div
              v-if="
                ['user_increase',
                  'users_stat',
                  'non_users_stat',
                  'organization_users',
                  'position_users',
                  'incomes_stats',
                  'expend_stats',
                  'cyc_circle_pay_read',
                  'activity_signup_fee',
                  'activity_service_fee',
                  'cyc_circle_top_fee',
                  'cyc_organization_fee',
                  'member_fee_stats',
                ].includes(data.index) && data.w >= 2
              "
              v-for="time in myTimeArr"
              :key="time.type"
              class="btn single flex-center"
              :class="{
                'cursor-pointer': activeTime !== time.type,
                active: activeTime === time.type,
              }"
              @click="selectTime(time)"
            >
              <!--              <span v-if="activeTime === time.type && activeTime === 'custom' && customTime" class="btn-name single-name">{{customTimeStr || time.name}}</span>-->
              <!--              <span v-else class="btn-name single-name">{{time.name}}</span>-->
              <span class="btn-name single-name">{{ time.name }}</span>
            </div>
            <!-- 时间选择 -->
            <div
              v-if="
                [
                  'member_fee',
                  'activity_host',
                  'cyc_circle_data',
                  'classroom_data',
                  'circle_topic',
                  'user_increase',
                  'new_member',
                  'home_overview',
                  'circle_post',
                  'home_overview',
                  'users_stat',
                  'non_users_stat',
                  'organization_users',
                  'position_users',
                  'incomes_stats',
                  'expend_stats',
                  'activity_signup_fee',
                  'activity_service_fee',
                  'cyc_circle_pay_read',
                  'cyc_circle_top_fee',
                  'cyc_organization_fee',
                  'member_fee_stats',
                ].includes(data.index) &&
                activeTime &&
                !(
                  [
                    'user_increase',
                    'users_stat',
                    'non_users_stat',
                    'organization_users',
                    'position_users',
                    'incomes_stats',
                    'expend_stats',
                    'activity_signup_fee',
                    'activity_service_fee',
                    'cyc_circle_pay_read',
                    'cyc_circle_top_fee',
                    'cyc_organization_fee',
                    'member_fee_stats',
                  ].includes(data.index) && data.w >= 2
                )
              "
              class="btn selector flex-center cursor-pointer"
              :class="{ disabled: !grid }"
              @click.stop="showTime = !showTime"
            >
              <span
                v-if="activeTime !== 'custom'"
                class="btn-name selector-name"
                :style="{
                  transform: false && scaleRatio ? `scale(${scaleRatio})` : '',
                }"
                >{{
                  myTimeArr.find((t) => t.type === activeTime)
                    ? myTimeArr.find((t) => t.type === activeTime).name
                    : ""
                }}</span
              >
              <span
                v-else
                class="btn-name selector-name"
                :style="{
                  transform: false && scaleRatio ? `scale(${scaleRatio})` : '',
                }"
                >{{ customTimeStr }}</span
              >
              <i class="el-icon-caret-bottom" />
              <span class="black-corner" />
              <div v-if="grid" v-show="showTime" class="dropdown flex-col">
                <template v-for="t in myTimeArr">
                  <span
                    v-if="!t.enable || t.enable(data) !== false"
                    class="dropdown-item btn-name"
                    :key="t.type"
                    :class="{
                      checked: t.type === activeTime,
                      'cursor-pointer': t.type !== activeTime,
                    }"
                    @click="selectTime(t)"
                    >{{ t.name }}</span
                  >
                </template>
              </div>
            </div>
            <!-- 活跃会员筛选 -->
            <div
              v-if="['member_interact'].includes(data.index)"
              class="btn selector flex-center cursor-pointer"
              :class="{ disabled: !grid }"
              @click.stop="showTime = !showTime"
            >
              <span
                v-if="activeTime !== 'custom'"
                class="btn-name selector-name"
                :style="{
                  transform: false && scaleRatio ? `scale(${scaleRatio})` : '',
                }"
                >{{
                  data.config.data_show_options.find(
                    (t) => t.key === activeMemberInteract
                  )
                    ? data.config.data_show_options.find(
                        (t) => t.key === activeMemberInteract
                      ).name
                    : ""
                }}</span
              >

              <i class="el-icon-caret-bottom" />
              <span class="black-corner" />
              <div v-if="grid" v-show="showTime" class="dropdown flex-col">
                <template v-for="t in memberInteractFilterShowOptions">
                  <span
                    class="dropdown-item btn-name"
                    :key="t.key"
                    :class="{
                      checked: t.key === activeMemberInteract,
                      'cursor-pointer': t.key !== activeMemberInteract,
                    }"
                    @click="selectMemberInteract(t)"
                    >{{ t.name }}</span
                  >
                </template>
              </div>
            </div>
          </div>
        </div>
        <slot>
          <home-overview
            v-if="data.index === 'home_overview'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <member-fee
            v-else-if="data.index === 'member_fee'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <member-map
            v-else-if="data.index === 'member_map'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <company-category
            v-else-if="data.index === 'company_category'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <article-post
            v-else-if="data.index === 'article_post'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <classroom-data
            v-else-if="data.index === 'classroom_data'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <activity-host
            v-else-if="data.index === 'activity_host'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <cyc-circle-data
            v-else-if="data.index === 'cyc_circle_data'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <circle-topic
            v-else-if="data.index === 'circle_topic'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <user-increase
            v-else-if="data.index === 'user_increase'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <new-member
            v-else-if="data.index === 'new_member'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <member-photo
            v-else-if="data.index === 'member_photo'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <circle-post
            v-else-if="data.index === 'circle_post'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <!-- 活跃会员 -->
          <member-interact
            v-else-if="data.index === 'member_interact'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <!-- 会员统计 -->
          <users-stat
            v-else-if="data.index === 'users_stat'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <!-- 游客统计 -->
          <non-users-stat
            v-else-if="data.index === 'non_users_stat'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <!-- 架构人数 -->
          <organization-users
            v-else-if="data.index === 'organization_users'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <!-- 职务人数 -->
          <position-users
            v-else-if="data.index === 'position_users'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <!-- 收入统计, 支出统计-->
          <income-and-expend-stats
            v-else-if="
              data.index === 'incomes_stats' || data.index === 'expend_stats'
            "
            ref="cardContent"
            @update="handleUpdate"
          />
          <!-- 活动服务费统计, 活动服务费统计-->
          <activity-service-and-signup
            v-else-if="
              data.index === 'activity_signup_fee' ||
              data.index === 'activity_service_fee'
            "
            ref="cardContent"
            @update="handleUpdate"
          />
          <!-- 供求帖子付费置顶 ,供求帖子付费置顶统计 -->
          <cyc-circle-pay-and-top
            v-else-if="
              data.index === 'cyc_circle_pay_read' ||
              data.index === 'cyc_circle_top_fee'
            "
            ref="cardContent"
            @update="handleUpdate"
          />
          <!-- 历届会费统计 -->
          <all-member-fee
            v-else-if="data.index === 'all_member_fee'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <!-- 组织会费统计 -->
          <cyc-organization-fee
            v-else-if="data.index === 'cyc_organization_fee'"
            ref="cardContent"
            @update="handleUpdate"
          />
          <!-- 会费统计 -->
          <member-fee-stats
          v-else-if="data.index === 'member_fee_stats'"
            ref="cardContent"
            @update="handleUpdate"
           />
        </slot>
      </div>
    </div>
    <div class="text flex-between flex-align-center">
      <div class="flex-align-center">
        <!--        <img class="icon"/>-->
        <span class="title">{{ data.name }}</span>
      </div>
      <span class="msg">*图为示例，非真实数据</span>
    </div>
    <el-dialog
      class="dialog-vertical"
      title="自定义时间"
      :visible="showCustom"
      append-to-body
      width="500px"
      @close="showCustom = false"
    >
      <el-form size="medium-form">
        <el-form-item>
          <el-date-picker
            type="datetimerange"
            v-model="customTime"
            format="yyyy-MM-dd HH:mm"
            style="width: 100%"
            value-format="timestamp"
            start-placeholder="起始时间"
            end-placeholder="结束时间"
            @change="handleCustomTime"
          />
        </el-form-item>
      </el-form>
      <!--      <template #footer>-->
      <!--        <el-button size="small" @click="showCustom = false">取消</el-button>-->
      <!--        <el-button size="small" type="primary" @click="handleCustomTime">确定</el-button>-->
      <!--      </template>-->
    </el-dialog>
  </div>
</template>

<script>
import HomeOverview from "./templates/HomeOverview/HomeOverview";
import MemberFee from "@/modules/big-data/components/templates/MemberFee/MemberFee";
import MemberMap from "@/modules/big-data/components/templates/MemberMap/MemberMap";
import {
  getCurrentMonth,
  getCurrentWeek,
  getCurrentYear,
} from "@/modules/big-data/tool/time-range";
import CompanyCategory from "@/modules/big-data/components/templates/CompanyCategory/CompanyCategory";
import ArticlePost from "@/modules/big-data/components/templates/ArticlePost/ArticlePost";
import ActivityHost from "@/modules/big-data/components/templates/ActivityHost/ActivityHost";
import CycCircleData from "@/modules/big-data/components/templates/CycCircleData/CycCircleData";
import ClassroomData from "@/modules/big-data/components/templates/ClassroomData/ClassroomData";
import CircleTopic from "@/modules/big-data/components/templates/CircleTopic/CircleTopic";
import UserIncrease from "./templates/UserIncrease/UserIncrease";
import { getRecentDay_Date, getRecentMonth_Date } from "../tool/time-range";
import { formatDate } from "@/base/utils/tool";
import MemberMapBorder from "./templates/MemberMap/MemberMapBorder";
import NewMember from "./templates/NewMember/NewMember";
import MemberPhoto from "@/modules/big-data/components/templates/MemberPhoto/MemberPhoto";
import CirclePost from "@/modules/big-data/components/templates/CirclePost/CirclePost";
// 活跃会员
import MemberInteract from "@/modules/big-data/components/templates/MemberInteract/MemberInteract";
// 会员统计
import UsersStat from "@/modules/big-data/components/templates/UsersStat/UsersStat";
// 游客统计
import NonUsersStat from "@/modules/big-data/components/templates/NonUsersStat/NonUsersStat";
// 架构人数
import OrganizationUsers from "@/modules/big-data/components/templates/OrganizationUsers/OrganizationUsers";
// 架构人数
import PositionUsers from "@/modules/big-data/components/templates/PositionUsers/PositionUsers";
//收入统计 支出统计
import IncomeAndExpendStats from "@/modules/big-data/components/templates/IncomeAndExpendStats/IncomeAndExpendStats";
// 活动报名 活动服务费
import ActivityServiceAndSignup from "@/modules/big-data/components/templates/ActivityServiceAndSignup/ActivityServiceAndSignup";
//  供求帖子付费置顶 ,供求帖子付费置顶统计
import CycCirclePayAndTop from "@/modules/big-data/components/templates/CycCirclePayAndTop/CycCirclePayAndTop";
// 历届会费统计
import AllMemberFee from "@/modules/big-data/components/templates/AllMemberFee/AllMemberFee";
// 组织会费统计
import CycOrganizationFee from "@/modules/big-data/components/templates/CycOrganizationFee/CycOrganizationFee";
// 会费统计
import MemberFeeStats from '@/modules/big-data/components/templates/MemberFeeStats/MemberFeeStats'
import { format } from "echarts/core";
export default {
  name: "TemplateCard",
  components: {
    CirclePost,
    MemberPhoto,
    NewMember,
    MemberMapBorder,
    UserIncrease,
    CircleTopic,
    ClassroomData,
    CycCircleData,
    ActivityHost,
    ArticlePost,
    CompanyCategory,
    MemberMap,
    MemberFee,
    HomeOverview,
    MemberInteract,
    UsersStat,
    NonUsersStat,
    OrganizationUsers,
    PositionUsers,
    IncomeAndExpendStats,
    ActivityServiceAndSignup,
    CycCirclePayAndTop,
    AllMemberFee,
    CycOrganizationFee,
    MemberFeeStats,
  },
  provide() {
    return {
      getData: () => this.data,
      grid: this.grid,
      // true 演示，不能删除，不能拖拽，不能 resize，false 也就是编辑模式
      disabled: this.disabled,
    };
  },
  props: {
    index: {
      type: Number,
      default: 999,
    },
    // 是否是在渲染在容器里面的卡片，不是的话是渲染在页面装修右侧列表
    grid: {
      type: Boolean,
      default: false,
    },
    data: Object,
    disabled: Boolean,
  },
  data() {
    return {
      loading: false,
      // 单元格宽度，注意零的时候不要设置行内 style
      width: 0,
      scaleRatio: 0,
      showTime: false,
      activeTime: "",
      // 当前的活跃会员筛选字段
      activeMemberInteract: "",
      timeArr: [
        /* 0：周环比，1：月环比，2：年环比 */
        { name: "本周", type: "this_week", compare_type: 0 },
        { name: "本月", type: "this_month", compare_type: 1 },
        { name: "近三个月", type: "three_months", compare_type: 3 },
        {
          name: "今年",
          type: "this_year",
          enable: (data) => {
            return [
              "article_post",
              "activity_host",
              "cyc_circle_data",
              "classroom_data",
              "circle_topic",
              "circle_post",
            ].includes(data.index);
          },
          compare_type: 2,
        },
        { name: "自定义", type: "custom", compare_type: 4 },
      ],
      timeArr2: [
        { name: "昨天", type: "yesterday" },
        { name: "最近7天", type: "week" },
        { name: "最近30天", type: "month" },
        { name: "最近三个月", type: "three_months" },
        { name: "最近一年", type: "year" },
        { name: "自定义", type: "custom" },
      ],
      // 只有首页概览用吧？
      typeArr: [
        { name: "至今", type: "total", compare_type: 5 },
        { name: "周环比", type: "week_percent", compare_type: 0 },
        { name: "月环比", type: "month_percent", compare_type: 1 },
        { name: "近三个月环比", type: "three_month_percent", compare_type: 3 },
        { name: "年环比", type: "year_percent", compare_type: 2 },
      ],
      showCustom: false,
      customTime: "",
      // svg below
      w: 0,
      h: 0,
      gap: 9 || 16,
    };
  },
  computed: {
    myTimeArr() {
      if (
        [
          "user_increase",
          "users_stat",
          "non_users_stat",
          "organization_users",
          "position_users",
          "incomes_stats",
          "expend_stats",
          "activity_signup_fee",
          "activity_service_fee",
          "cyc_circle_pay_read",
          "cyc_circle_top_fee",
          "cyc_organization_fee",
          'member_fee_stats',
        ].includes(this.data.index)
      )
        return this.timeArr2;
      if (["home_overview"].includes(this.data.index)) return this.typeArr;
      return this.timeArr;
    },
    customTimeStr() {
      if (!this.customTime) return "";
      const startT = this.customTime[0];
      const endT = this.customTime[1];
      /* 省略年份 */
      if (new Date(startT).getFullYear() === new Date().getFullYear())
        return `${formatDate(startT, "MM/DD")} - ${formatDate(endT, "MM/DD")}`;
      return `${formatDate(startT, "YYYY/MM/DD")} - ${formatDate(
        endT,
        "YYYY/MM/DD"
      )}`;
    },
    // 活跃会员filter 之后的配置
    memberInteractFilterShowOptions() {
      if (this.data.index !== "member_interact") {
        return [];
      }
      // 在全部options 里面返回 data_show 包含的key
      return this.data.config.data_show_options.filter((item) => {
        let isHave = false;
        this.data.config.data_show.forEach((key) => {
          if (key === item.key) {
            isHave = true;
          }
        });
        return isHave;
      });
    },
  },
  watch: {
    // 活跃会员组件修改数据展示配置
    memberInteractFilterShowOptions: {
      handler(val, oldVal) {
        // 虽然改变了options,但是 activeMemberInteract 的值还是能找到的，能找到就不更新，找不到就默认选择第一个 options key
        if (
          val.findIndex((item) => item.key === this.activeMemberInteract) !== -1
        ) {
          return;
        }
          this.selectMemberInteract();
      },
      
    },
  },
  mounted() {
    setTimeout(() => {
      this.init();
      this.$nextTick(() => {
        if (
          [
            "member_fee",
            "article_post",
            "activity_host",
            "cyc_circle_data",
            "classroom_data",
            "circle_topic",
            "user_increase",
            "new_member",
            "circle_post",
            "home_overview",
            "users_stat",
            "non_users_stat",
            "organization_users",
            "position_users",
            "incomes_stats",
            "expend_stats",
            "activity_signup_fee",
            "activity_service_fee",
            "cyc_circle_pay_read",
            "cyc_circle_top_fee",
            "cyc_organization_fee",
            "all_member_fee",
            'member_fee_stats',
          ].includes(this.data.index)
        ) {
          this.selectTime(this.myTimeArr[0]); // 自动选择下拉框第一项来默认展示
        } else if (["member_interact"].includes(this.data.index)) {
          // 活跃会员默认选中
            this.selectMemberInteract();
        } else if (
          ["company_category", "member_photo", "member_map"].includes(
            this.data.index
          )
        ) {
          this.handleUpdate();
        }
      });
    }, 150);
    // console.log(this.data, "this.data");
  },
  methods: {
    init() {
      // 获取到当前的格子
      const card = document.querySelector(".template-card-" + this.index);
      // 获取格子的内容宽度
      const width = card?.clientWidth;
      // 获取占位格子宽高数量
      const w = this.data.w || 1;
      const h = this.data.h || 1;

      // 边框尺寸
      this.w = 460 * w + (w - 1) * this.gap;
      this.h = 306 * h + (h - 1) * this.gap;

      if (this.grid) {
        this.width = width;
      } else {
        this.width = width - 12 * 2;
      }
    },
    /**
     * 检测窗口变化，重新计算
     */
    resize() {
      setTimeout(() => {
        this.init();
        const charts = this.$refs.cardContent;
        if (charts?.length) {
          charts.forEach((el) => {
            if (el.resize) el.resize();
          });
        } else if (charts) {
          if (charts.resize) charts.resize();
        }
      }, 150);
    },
    // 自定义处理
    handleCustomTime() {
      this.activeTime = "custom";
      this.handleUpdate();
      this.showCustom = false;
    },
    // 获取时间范围
    getTimeRange(type) {
      switch (type) {
        case "this_week":
          return getCurrentWeek();
        case "this_month":
          return getCurrentMonth();
        case "this_year":
          return getCurrentYear();
        case "yesterday":
          return getRecentDay_Date(-1);
        case "week":
          return getRecentDay_Date(-7);
        case "month":
          return getRecentDay_Date(-30);
        case "year":
          return getRecentMonth_Date(-12);
        case "three_months":
          return getRecentMonth_Date(-3);
        case "custom":
          if (this.customTime) {
            return {
              start_time: this.customTime[0] / 1000,
              end_time: this.customTime[1] / 1000,
            };
          } else {
            return;
          }
        default:
          return {};
      }
    },
    selectTime(obj) {
      if (obj.type === "custom") {
        this.customTime = "";
        this.showCustom = true;
        return;
      }

      obj = obj || this.myTimeArr[0];
      // 当前的时间类型
      this.activeTime = obj.type;

      if (obj.compare_type !== undefined) {
        if (["home_overview"].includes(this.data.index)) {
          this.handleUpdate();
        } else {
          this.handleUpdate({
            compare_type: obj.compare_type,
          });
        }
      } else if (["user_increase"].includes(this.data.index)) {
        this.handleUpdate({
          // user_type: 'personal_user' // 用户类型 personal_user：个人，unit_user：单位，non_user：游客
        });
      } else if (
        [
          "users_stat",
          "non_users_stat",
          "organization_users",
          "position_users",
          "incomes_stats",
          "expend_stats",
          "activity_signup_fee",
          "activity_service_fee",
          "cyc_circle_pay_read",
          "cyc_circle_top_fee",
          "cyc_organization_fee",
          'member_fee_stats',
        ].includes(this.data.index)
      ) {
        // 会员统计，游客统计都是自定义的
        this.handleUpdate({
          compare_type: 4,
        });
      }
    },
    // 选择活跃会员排行筛选
    selectMemberInteract(obj) {
      // 默认用第一个
      obj = obj || this.memberInteractFilterShowOptions[0];
      // 当前的筛选
      this.activeMemberInteract = obj?.key;
      this.handleUpdate({ data_key: this.activeMemberInteract });
    },
    // 更新格子内容，时间范围
    handleUpdate: function (obj = {}) {
      // return console.log(obj)
      if (obj.compare_type !== undefined) {
        const type = this.myTimeArr.find(
          (t) => t.compare_type === obj.compare_type
        );
        if (type && obj.compare_type !== 1) this.activeTime = type.type;
        if (!["home_overview"].includes(this.data.index)) {
          if (this.activeTime) {
            /* {start_time: 0, end_time: 0} */
            obj = Object.assign(obj, this.getTimeRange(this.activeTime));
          }
        }
      } else if (this.activeTime) {
        obj.compare_type =
          this.myTimeArr.find((t) => t.type === this.activeTime)
            ?.compare_type || 0;
        /** 概览不需要传时间戳 */
        if (!["home_overview"].includes(this.data.index)) {
          /* {start_time: 0, end_time: 0} */
          // 更新 start_time 和 end_time
          obj = Object.assign(obj, this.getTimeRange(this.activeTime));
        }
      }
      // 根据新参数重新请求对应卡片数据
      const charts = this.$refs.cardContent;
      if (charts?.length) {
        charts.forEach((el) => {
          this.loading = true;
          if (el.getShowData) {
            el.getShowData({ ...obj }, () => {
              this.loading = false;
            });
          } else if (el.initOptions) {
            el.initOptions(true, () => {
              // this.loading = false
            });
          }
        });
      } else if (charts) {
        if (charts.getShowData) {
          this.loading = true;
          charts.getShowData({ ...obj }, () => {
            this.loading = false;
          });
        } else if (charts.initOptions) {
          // this.loading = true;
          charts.initOptions(true, () => {
            // this.loading = false
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.template-card {
  /*width: 94%;*/
  width: 100%;
  border-radius: 6px;
  border: 1px solid rgba(233, 235, 242, 1);
  background: rgba(248, 248, 248, 1);
  padding: 12px;
  color: white;
  position: relative;
}

.preview {
  background-color: var(--bg-color);
  border-radius: 4px;
  @include fixed-ratio-box(67.39%);

  &:hover {
    .remover {
      visibility: visible;
    }
  }

  &.grid {
    transform-origin: left top;
    flex: 1;
    background-color: unset !important;
  }

  &.absolute {
    width: 460px;
    transform-origin: left top;
    position: absolute;
    top: 12px;
  }

  .content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.icon {
  width: 8px;
  height: 8px;
  margin-right: 4px;
}

.title {
  color: rgba(63, 83, 110, 1);
  font-size: 14px;
  font-weight: 500;
  line-height: 1.57;
}

.msg {
  color: rgba(213, 216, 222, 1);
  font-size: 12px;
  line-height: 1.5;
}

.text {
  margin-top: 13px;
}

$bgH: 0.62em;
$bgLW: 0.0425em;
$bgRW: 4.435em * 1;

.content {
  background-size: $bgLW $bgH;
  position: relative;

  &.fullscreen {
    &:before,
    &:after {
      display: none;
    }
  }

  /** 利用雪碧图原理构造标题背景，重点利用 background-position 属性 */
  /* 起点标题背景 */
  .bg-left {
    position: absolute;
    background-image: url("~@/modules/big-data/assets/title.png");
    background-repeat: no-repeat;
    width: $bgLW;
    height: $bgH;
    background-size: auto 100%;
  }

  /* 中间无限延长的标题背景 */
  &:before {
    content: "";
    /*background-image: url('~@/modules/big-data/assets/title-slice-2.png');*/
    /*background-repeat: repeat-x;*/
    background-image: url("~@/modules/big-data/assets/title.png");
    background-repeat: no-repeat;
    position: absolute;
    width: calc(100% - #{$bgLW} - #{$bgRW});
    height: $bgH;
    /*      background-size: auto $bgH;*/
    background-size: 500vw $bgH;
    left: $bgLW;
    background-position: -20vw 0;
    z-index: -1;
  }

  /* 终点标题背景 */
  &:after {
    content: "";
    /*background-image: url('~@/modules/big-data/assets/title-slice-right.png');*/
    background-image: url("~@/modules/big-data/assets/title.png");
    background-repeat: no-repeat;
    position: absolute;
    right: 0;
    width: $bgRW;
    height: $bgH;
    background-size: auto $bgH;
    /* 背景图尺寸 920 * 124，920 除以 2 得到 px 再除以 100 转换成 em */
    background-position: calc(#{$bgRW} - 4.6em) 0;
    z-index: -1;
  }

  .title-box {
    padding-top: 0.16em;
    /*padding-left: 0.16em;*/
    padding-bottom: 0.21em;
    flex: 1;
    min-width: 0;
  }

  .title {
    color: rgba(255, 255, 255, 1);
    font-size: 0.18em;
    font-weight: bolder;
    line-height: 1.4;
    /*padding-top: 3.48%;*/
    /*padding-left: 3.48%;*/
    /*padding-bottom: 4.57%;*/
    @include nowrap();
    @include no-select();
  }

  .content-head {
    padding: 0 0.16em;
    align-items: flex-start;
    @include no-select();
    position: relative;
    z-index: 5;
  }

  .selector {
    border: 0.01em solid rgba(75, 210, 237, 0.3);
    background: rgba(4, 39, 45, 0.8);
    box-shadow: inset 0 0 8px 0 rgba(75, 210, 237, 0.12);
    /*padding: 0 5.4%;*/
    position: relative;
    min-width: 92px;

    &:not(.disabled):hover {
      box-shadow: inset 0 0 20px 0 rgba(75, 210, 237, 0.12);
    }

    &.disabled {
      cursor: default;
    }

    &:before,
    &:after,
    .black-corner {
      content: "";
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      top: -0.01em;
      left: -0.01em;
    }

    &:after {
      border-top: 0.07em solid rgba(75, 210, 237, 0.3);
      border-left: 0.07em solid rgba(75, 210, 237, 0.3);
      border-right: 0.07em solid transparent;
      border-bottom: 0.07em solid transparent;
    }

    .black-corner {
      border-top: 0.07em solid #04272d;
      border-left: 0.07em solid #04272d;
      border-right: 0.07em solid transparent;
      border-bottom: 0.07em solid transparent;
      z-index: 1;
      top: -0.0177em;
      left: -0.015em;
    }

    &:before {
      border-top: 0.04em solid rgba(75, 210, 237, 1);
      border-left: 0.04em solid rgba(75, 210, 237, 1);
      border-right: 0.04em solid transparent;
      border-bottom: 0.04em solid transparent;
      z-index: 2;

      /*background: rgba(75,210,237,1);*/
      /*box-shadow: 0 0 4px 0 rgba(75,210,237,0.5);*/
    }

    .el-icon-caret-bottom {
      position: absolute;
      right: 1em;
      top: 1.4em;
      font-size: 0.08em;
    }

    .dropdown {
      position: absolute;
      top: 100%;
      right: 0;
      background-color: black;
      z-index: 5;

      .dropdown-item {
        font-size: 0.14em;
        min-width: 8em;
        text-align: center;
        border-top: 0.01em solid #ccc;

        &:first-child {
          border-top: none;
        }

        &.checked {
          opacity: 0.6;
          cursor: default;
        }

        &.checked,
        &:hover {
          color: var(--hover-color);
        }
      }
    }
  }

  .single {
    border: 0.01em solid rgba(75, 210, 237, 0.3);

    &.active {
      background: rgba(4, 39, 45, 0.8);
      background: linear-gradient(
        180deg,
        rgba(75, 210, 237, 0.4) 0%,
        rgba(75, 210, 237, 0) 100%
      );
      position: relative;

      &:after {
        content: "";
        width: 100%;
        height: 0.02em;
        background: rgba(75, 210, 237, 1);
        box-shadow: 0 0 0.06em 0 rgba(75, 210, 237, 0.5);
        position: absolute;
        bottom: 0;
      }
    }
  }

  .remover {
    border: 0.01em solid rgba(237, 75, 102, 0.6);
    /*background: rgba(4, 39, 45, 0.8);*/
    /*background: linear-gradient(180deg, rgba(237, 75, 102, .4) 0%, rgba(237, 75, 102, 0) 100%);*/
    visibility: hidden;

    background: rgba(4, 39, 45, 0.8), rgba(237, 75, 102, 0.1);

    .btn-name {
      color: rgba(237, 75, 102, 1);
    }
  }
}

.svg-border {
  position: absolute;
  top: 0;
  left: 0;
}

.btn,
::v-deep.template-card-content .btn {
  padding: 0 0.25em;
  white-space: nowrap;
  margin-top: 0.12em;
  min-width: 0.54em;
  /*width: 100%;*/

  & + .btn {
    margin-left: 0.04em;
  }

  .btn-name {
    font-size: 0.14em;
    /*font-size: 14px;*/
    line-height: 2.2857;
    /*&:before {*/
    /*  content: '周环比';*/
    /*  font-size: .14em;*/
    /*  transform: scale(.5);*/
    /*}*/
  }

  .btn-icon {
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }

  &.back {
    border: 0.01em solid rgba(75, 210, 237, 0.3);
    background-color: rgba(4, 39, 45, 0.8);
  }
}
</style>

<style lang="scss">
.el-loading-mask {
  background-color: var(--bg-color);

  .el-loading-spinner .path {
    stroke: var(--hover-color);
  }
}
</style>
