<template>
  <div v-if="responseData" class="template-card-content flex-col">
    <template-card-summary
      v-if="!isEmpty"
      :diff_count="responseData.diff_count"
      :status="responseData.status"
      :compare_type="compareType"
      @sort-by-time="toggleDesc"
    >
      <span class="total"
        >共新增 <span class="emphasis">{{ responseData.total }}</span> 个</span
      >
    </template-card-summary>
    <el-scrollbar v-if="!isEmpty" class="flex-1">
      <div
        class="grid flex-col"
        :class="{
          two: getData().w >= 2,
        }"
        @mouseenter="pause"
        @mouseleave="play"
        @mouseover="pause"
      >
        <div
          v-for="item in grid ? listData : listData.slice(0, 3)"
          :key="item.id"
          class="grid-item flex"
          :id="item.id"
        >
          <single-member :handle="handle" :item="item" @stop="pause" />
        </div>
      </div>
    </el-scrollbar>
    <div v-if="isEmpty" class="flex-1 flex-center">
      <data-empty />
    </div>
    <div v-if="!isEmpty && listData.length > 3" class="blur" />
  </div>
</template>

<script>
import DataEmpty from "@/modules/big-data/components/DataEmpty";
import { getHomeNewMemberData } from "../../../api/templates";
import SingleMember from "./SingleMember";
import TemplateCardSummary from "@/modules/big-data/components/TemplateCardSummary";
import template from "@/modules/big-data/mixins/list-template";

export default {
  name: "NewMember",
  mixins: [template],
  components: { TemplateCardSummary, SingleMember, DataEmpty },
  inject: ["getData", "grid", "disabled"],
  props: {},
  data() {
    return {
      compareType: 0,
      handle: false,
    };
  },
  methods: {
    getShowData(query, callback) {
      this.stopAnimation();
      const queryObj = {
        compare_type: 0, // 周、月、年
        start_time: -1, // 开始时间
        end_time: -1,
        ...(this.queryObj || {}),
        is_desc: this.desc === "ascending" ? 0 : 1, // 是否倒序
        page: 1, // 页码
        ...query,
      };
      getHomeNewMemberData(queryObj)
        .then((res) => {
          this.responseData = res.data;
          this.queryObj = queryObj;
          this.compareType = queryObj.compare_type;

          // TODO: 动画开关
          setTimeout(() => {
            this.animate();
          }, 1500);

          callback();
        })
        .catch((err) => {
          console.log(err);
          callback();
        });
    },
    animate() {
      if (this.grid && !this.disabled) return;
      const list = this.listData;
      if (list.length > 3 && this.grid) {
        this.buildTimer({
          interval: 3000,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/modules/big-data/scss/template.scss";

.grid {
  width: 100%;
  height: 100%;
  padding: 0 0.16em;

  &.two {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 0.12em;
  }

  .grid-item {
    padding: 0.12em;
    margin-top: 0.08em;
    position: relative;
    background-color: rgba(4, 39, 45, 0.7);
    align-items: center;

    &:after {
      content: "";
      position: absolute;
      right: 0.12em;
      top: calc(50% - 0.025em);
      /*border-top: .14em solid #082226;*/
      border-top: 0.05em solid transparent;
      border-left: 0.05em solid transparent;
      border-right: 0.05em solid rgba(75, 237, 156, 1);
      border-bottom: 0.05em solid transparent;
      width: 0;
      height: 0;
      box-sizing: content-box;
    }

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
