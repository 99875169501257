<template>
  <div v-if="responseData" class="template-card-content article-post flex-col"
       @mouseover="pause"
       @mouseout="play"
  >
    <template-card-summary v-if="!isEmpty"
                           :compare_type="compareType"
                           :diff_count="responseData.diff_count"
                           :status="responseData.status"
                           @sort-by-time="toggleDesc"
    >
      <span class="total">共发布 <span class="emphasis">{{responseData.total}}</span> 个</span>
    </template-card-summary>
    <component :is="grid?'el-scrollbar':'div'" v-if="!isEmpty" class="dynamic flex-1">
      <div class="list-wrapper" :class="{
        two: getData().w >= 2,
        grid,
        'flex-col': !grid
      }">
        <div v-for="item in (grid ?responseData.list.data:responseData.list.data.slice(0, 2))" class="grid-item flex"
             :class="{
          beginning: item.activity_status_text_two === 3,
          ending: item.activity_status_text_two === 4,
          signing: item.activity_status_text_two === 5,
          waiting: [1, 2, 6].includes(item.activity_status_text_two)
             }" :id="item.id">
          <div class="flex-col flex-1" style="justify-content: space-between;margin-left: .15em;margin-top: .12em;">
            <div class="line-two flex-between">
              <span class="status flex-center">
                <span class="status-text">{{item.activity_status_text_three}}</span>
              </span>
              <span class="time"><i class="el-icon-time" /> {{item.activity_time_text}}</span>
            </div>
            <div class="line-one flex-col">
              <span class="label">{{item.name}}</span>
            </div>
          </div>
          <div class="icon-box">
            <img class="icon" :src="fixImageUrl(item.cover, 200)" draggable="false" />
          </div>
        </div>
      </div>
    </component>
    <div v-if="isEmpty" class="flex-1 flex-center">
      <data-empty />
    </div>
    <div v-if="!isEmpty" class="blur" />
  </div>
</template>

<script>
import { getHomeActivityData } from "@/modules/big-data/api/templates";
import { randomString } from "@/base/utils/tool";
import DataEmpty from "@/modules/big-data/components/DataEmpty";
import TemplateCardSummary from "@/modules/big-data/components/TemplateCardSummary";
import template from "@/modules/big-data/mixins/list-template";

export default {
  name: 'activity-host',
  mixins: [template],
  components: {TemplateCardSummary, DataEmpty},
  inject: ['getData', 'grid', 'disabled'],
  data() {
    return {
      compareType: 0,
    }
  },
  methods: {
    getShowData(query, callback) {
      this.stopAnimation()
      const queryObj = {
        compare_type: 0, // 周、月、年
        start_time: -1, // 开始时间
        end_time: -1,
        ...(this.queryObj || {}),
        is_desc: this.desc??1,	// 是否倒序
        page: 1, // 页码
        ...query,
      }
      getHomeActivityData(queryObj).then(res => {
        res.data.list.data = res.data.list.data.map(el => ({
          ...el,
          id: randomString(5)
        }))
        this.responseData = res.data
        this.queryObj = queryObj
        this.compareType = queryObj.compare_type

        if (!this.isEmpty) {
          // 动画开关
          setTimeout(() => {
            this.animate()
          }, 500)
          callback()
        } else {
          // const next = (queryObj.compare_type + 1) % 3
          //
          // this.$emit('update', {
          //   compare_type: next
          // })
          callback()
        }
      }).catch(err => {
        console.log(err)
        callback()
      })
    },
    animate() {
      if (this.grid && !this.disabled) return
      const list = this.listData
      if (list.length && this.grid) {
        this.buildTimer({
          interval: 3000
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/modules/big-data/scss/template.scss";

  .list-wrapper {
    width: 100%;
    height: 100%;
    padding: 0 .16em;

    &.two {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .grid-item {
      padding: 0 .12em .12em .12em;
      /*border-left: .02em solid #3CA8BE;*/
      /*background: linear-gradient(90deg, rgba(75,210,237,.15) 0%, rgba(75,210,237,0) 100%);*/
      margin-top: .08em;
      position: relative;

      &:before {
        content: "";
        height: calc(100% - 0.12em * 2);
        width: 0;
        position: absolute;
        left: .12em;
        top: .12em;
      }

      &:after {
        content: "";
        position: absolute;
        left: 58%;
        top: 0;
        /*border-top: .14em solid #082226;*/
        border-top: .14em solid var(--bg-color);
        border-left: .14em solid transparent;
        border-right: .14em solid transparent;
        border-bottom: .14em solid transparent;
        width: 42%;
        height: 0;
        box-sizing: content-box;
      }

      &:first-child {
        margin-top: 0;
      }

      &.beginning {
        background-color: rgba(237,183,75,0.1);

        &:before {
          border: .02em solid rgba(237,183,75,1);
          box-shadow: 0 0 .04em 0 rgba(237,183,75,0.5);
        }

        .status {
          color: #EDB74B;
          background-color: rgba(237,183,75,0.1);
        }
      }
      &.signing {
        background-color: rgba(237,75,102,0.1);

        &:before {
          border: .02em solid rgba(237,75,102,1);
          box-shadow: 0 0 .04em 0 rgba(237,75,102,0.5);
        }

        .status {
          background: rgba(237,75,102,0.1);
          color: #ED4B66;
        }
      }

      &.ending {
        background: rgba(128,128,128,0.1);

        &:before {
          border: .02em solid #808080;
        }

        .status {
          background: rgba(128,128,128,0.1);
          color: #808080;
        }
      }

      &.waiting {
        background: rgba(75,237,223,0.1);

        &:before {
          border: 2px solid rgba(75,237,223,1);
          box-shadow: 0 0 4px 0 rgba(75,237,223,0.5);
        }

        .status {
          color: #4BEDDF;
          background: rgba(75,237,223,0.1);
        }
      }
    }
  }

  .icon-box {
    /*width: 1.39em;*/
    /*width: 32.5% !important;*/
    width: 1.36em !important;
    /*height: fit-content;*/
    margin-left: .12em;
    @include fixed-ratio-box(75%);
    display: flex;
    transform: scale(1);
    border-radius: .04em;
    overflow: hidden;
    z-index: 1;

    &:before {
      width: 100%;
    }
  }

  .icon {
    /*width: .9em;*/
    /*height: .9em;*/
    /*flex-shrink: 0;*/
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .label {
    font-size: .16em;
    line-height: 1.357;
    /* 相对自身的 font-size */
    /*margin-right: 0.857em;*/
    @include clamp(2);
    color: rgba(255,255,255,1);
    font-weight: bold;
  }

  .line-one {
    margin-bottom: .07em;
  }

  .line-two {
    margin-top: .04em;

    .status {
      min-width: .52em;
      padding: 0 .08em;
      height: .24em;
    }

    .status-text {
      font-size: .12em;
      font-weight: bold;
    }

    .time {
      color: rgba(255,255,255,1);
      font-size: .12em;
      font-weight: bolder;
      margin-right: 1.5833em;
    }
  }

</style>
