export const getCurrentWeek = (value = null, separate = "-") => {
  // 如果为null,则格式化当前时间
  if (!value) value = Number(new Date());
  // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
  if (value.toString().length == 10) value *= 1000;
  value = +new Date(Number(value));

  const one_day = 86400000; // 24 * 60 * 60 * 1000;
  const weekDate = new Date(value);
  const day = weekDate.getDay() === 0 ? 7 : weekDate.getDay(); // 返回1-7,7表示周日
  // 设置时间为当天的0点
  weekDate.setHours(0, 0, 0, 0);

  //算出本周开始时间结束时间
  const week_start_time = new Date(weekDate.getTime() - (day - 1) * one_day);
  const week_end_time = new Date(weekDate.getTime() + (7 - day) * one_day);

  //格式化日期
  const startmonth = filters(week_start_time.getMonth() + 1);
  const startDay = filters(week_start_time.getDate());
  const endmonth = filters(week_end_time.getMonth() + 1);
  const endDay = filters(week_end_time.getDate());

  const startDateTime =
    week_start_time.getFullYear() + separate + startmonth + separate + startDay;
  const endDateTime =
    week_end_time.getFullYear() + separate + endmonth + separate + endDay;
  const result = {
    startDateTime: startDateTime + " 00:00:00",
    endDateTime: endDateTime + " 23:59:59",
  };
  // console.log(result, 'this week')
  return {
    start_time: datetimeStrToTimestamp(result.startDateTime),
    end_time: datetimeStrToTimestamp(result.endDateTime),
  };
};

export const getCurrentMonth = (value = null, separate = "-") => {
  // 如果为null,则格式化当前时间
  if (!value) value = Number(new Date());
  // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
  if (value.toString().length == 10) value *= 1000;
  value = +new Date(Number(value));

  const targetTime = new Date(value);
  const year = targetTime.getFullYear();
  let month = targetTime.getMonth() + 1;
  if (month < 10) {
    month = "0" + month;
  }

  const yDate = new Date(year, month, 0);

  const startDateTime = year + separate + month + separate + "01 00:00:00"; //这个月第一天
  const endDateTime =
    year + separate + month + separate + yDate.getDate() + " 23:59:59"; // 这个月最后一天

  const result = {
    startDateTime: startDateTime,
    endDateTime: endDateTime,
  };

  // console.log(result, 'this month')

  return {
    start_time: datetimeStrToTimestamp(result.startDateTime),
    end_time: datetimeStrToTimestamp(result.endDateTime),
  };
};

/**
 * @deprecated
 * @return {{start_time: *, end_time: *}}
 */
const getLast3Months = function () {
  var end = new Date();
  var year = end.getFullYear();
  var month = end.getMonth() + 1; //0-11表示1-12月
  var day = end.getDate();
  var dateObj = {};
  dateObj.end = year + "-" + month + "-" + day;
  var endMonthDay = new Date(year, month, 0).getDate(); //当前月的总天数
  if (month - 3 <= 0) {
    //如果是1、2、3月，年数往前推一年
    var start3MonthDay = new Date(
      year - 1,
      12 - (3 - parseInt(month)),
      0
    ).getDate(); //3个月前所在月的总天数
    if (start3MonthDay < day) {
      //3个月前所在月的总天数小于现在的天日期
      dateObj.start =
        year - 1 + "-" + (12 - (3 - month)) + "-" + start3MonthDay;
    } else {
      dateObj.start = year - 1 + "-" + (12 - (3 - month)) + "-" + day;
    }
  } else {
    var start3MonthDay = new Date(year, parseInt(month) - 3, 0).getDate(); //3个月前所在月的总天数
    if (start3MonthDay < day) {
      //3个月前所在月的总天数小于现在的天日期
      if (day < endMonthDay) {
        //当前天日期小于当前月总天数,2月份比较特殊的月份
        dateObj.start =
          year +
          "-" +
          (month - 3) +
          "-" +
          (start3MonthDay - (endMonthDay - day));
      } else {
        dateObj.start = year + "-" + (month - 3) + "-" + start3MonthDay;
      }
    } else {
      dateObj.start = year + "-" + (month - 3) + "-" + day;
    }
  }

  // console.log(dateObj.start + ' 00:00:00', dateObj.end + ' 23:59:59')

  return {
    start_time: datetimeStrToTimestamp(dateObj.start + " 00:00:00"),
    end_time: datetimeStrToTimestamp(dateObj.end + " 23:59:59"),
  };
};

export const getCurrentYear = function (value, separate = "-") {
  // 如果为null,则格式化当前时间
  if (!value) value = Number(new Date());
  // 如果dateTime长度为10或者13，则为秒和毫秒的时间戳，如果超过13位，则为其他的时间格式
  if (value.toString().length == 10) value *= 1000;
  value = +new Date(Number(value));

  const targetTime = new Date(value);

  const result = {
    startDateTime: targetTime.getFullYear() + "-01-01 00:00:00",
    endDateTime: targetTime.getFullYear() + "-12-31 23:59:59",
  };
  // console.log(result, 'this year')
  return {
    start_time: datetimeStrToTimestamp(result.startDateTime),
    end_time: datetimeStrToTimestamp(result.endDateTime),
  };
};

// 近N天 - new Date()
export function getRecentDay_Date(n) {
  var dd = new Date();
  dd.setHours(23);
  dd.setMinutes(59);
  dd.setSeconds(59);
  const yy = dd.getFullYear();
  const mm = dd.getMonth() + 1;
  const ddd = dd.getDate();
  const hh = dd.getHours();
  const MM = dd.getMinutes();
  let today =
    yy +
    "-" +
    filters(mm) +
    "-" +
    ddd +
    " " +
    filters(hh) +
    ":" +
    filters(MM) +
    ":" +
    "59";

  dd.setDate(dd.getDate() + n); //获取n天后的日期
  dd.setSeconds(60);
  var y = dd.getFullYear();
  var m = dd.getMonth() + 1; //获取当前月份的日期
  var d = dd.getDate();
  var h = dd.getHours();
  var M = dd.getMinutes();
  let day =
    y +
    "-" +
    filters(m) +
    "-" +
    filters(d) +
    " " +
    filters(h) +
    ":" +
    filters(M) +
    ":" +
    "00";

  const result = {
    startDateTime: n > 0 ? today : day,
    endDateTime: n <= 0 ? today : day,
  };
  // console.log(result, '近' + n + '天')
  return {
    start_time: datetimeStrToTimestamp(result.startDateTime),
    end_time: datetimeStrToTimestamp(result.endDateTime),
  };
}

// 近N月 - new Date()
export const getRecentMonth_Date = function (n) {
  const datenow = new Date();
  /* 现在（这个月的月末）- 即下个月月头减 1 秒 */
  // const nextMonthStartDay = new Date()
  // nextMonthStartDay.setMonth(datenow.getMonth() + 1)
  // nextMonthStartDay.setDate(1)
  // nextMonthStartDay.setHours(0)
  // nextMonthStartDay.setMinutes(0)
  // nextMonthStartDay.setSeconds(0)
  // nextMonthStartDay.setMilliseconds(0)

  // console.log(nextMonthStartDay.getFullYear().toString() +
  //   '-' +   filters(nextMonthStartDay.getMonth() + 1).toString() +
  //   '-' +
  //   filters(nextMonthStartDay.getDate()).toString() +
  //   ' ' +
  //   filters(nextMonthStartDay.getHours()) +
  //   ':' +
  //   filters(nextMonthStartDay.getMinutes()) +
  //   ':' +
  //   filters(nextMonthStartDay.getSeconds()), '下个月初')
  //
  // nextMonthStartDay.setSeconds(-1)
  // const dateendDate = nextMonthStartDay
  // console.log(dateendDate.getFullYear().toString() +
  // '-' +   filters(nextMonthStartDay.getMonth() + 1).toString() +
  //   '-' +
  //   filters(nextMonthStartDay.getDate()).toString() +
  //   ' ' +
  //   filters(nextMonthStartDay.getHours()) +
  //   ':' +
  //   filters(nextMonthStartDay.getMinutes()) +
  //   ':' +
  //   filters(nextMonthStartDay.getSeconds()), '月末')

  const dateend =
    datenow.getFullYear().toString() +
    "-" +
    filters(datenow.getMonth() + 1).toString() +
    "-" +
    filters(datenow.getDate()).toString() +
    " " +
    filters(datenow.getHours()) +
    ":" +
    filters(datenow.getMinutes()) +
    ":" +
    "00";

  datenow.setMonth(datenow.getMonth() + n);
  const datestart =
    datenow.getFullYear().toString() +
    "-" +
    filters(datenow.getMonth() + 1).toString() +
    "-" +
    filters(datenow.getDate()).toString() +
    " " +
    filters(datenow.getHours()) +
    ":" +
    filters(datenow.getMinutes()) +
    ":" +
    "00";

  const result = {
    startDateTime: n > 0 ? dateend : datestart,
    endDateTime: n <= 0 ? dateend : datestart,
  };
  // console.log(result, '近' + n + '月')
  return {
    start_time: datetimeStrToTimestamp(result.startDateTime),
    end_time: datetimeStrToTimestamp(result.endDateTime),
  };
};

/**
 *
 * @param{string} str
 */
function datetimeStrToTimestamp(str) {
  /* 裂开 年月日 和 时分 */
  const strArr = str.split(" ");
  /* 裂开 年 月 日 */
  const yearMonthDate = strArr[0].split("-");
  const year = yearMonthDate[0];
  const month = yearMonthDate[1] - 1;
  const date = yearMonthDate[2];
  /* 裂开 时 分 */
  const hourMinute = strArr[1].split(":");
  const hour = hourMinute[0];
  const minute = hourMinute[1];
  const second = hourMinute[2] || 0;
  const DateObj = new Date(year, month, date, hour, minute, second);
  // console.table([{str, year, month, date, hour, minute, second}])
  // console.log('parse comparison: ', DateObj.getTime(), new Date(str).getTime())
  return DateObj.getTime() / 1000;
}

function filters(n) {
  return n < 10 ? (n = "0" + n) : n;
}
