<template>
  <div v-if="responseData" class="template-card-content flex-col">
    <el-scrollbar v-if="!isEmpty" class="flex-1">
      <div
        class="grid flex-col"
        :class="{
          two: getData().w >= 2,
        }"
        @mouseenter="pause"
        @mouseleave="play"
        @mouseover="pause"
      >
        <div
          v-for="(item, itemI) in grid
            ? listData
            : listData.slice(0, 3)"
          :key="item.id"
          class="grid-item flex"
          :id="item.id"
        >
          <single-member
            :handle="handle"
            :item="item"
            :itemI="itemI + 1"
            @stop="pause"
          />
        </div>
      </div>

    </el-scrollbar>
    <div v-if="isEmpty" class="flex-1 flex-center">
      <data-empty />
    </div>
    <div v-if="!isEmpty && listData.length > 3" class="blur" />

  </div>
</template>

<script>
import DataEmpty from "@/modules/big-data/components/DataEmpty";
import { getMemberInteractData } from "@/modules/big-data/api/templates/member-stats";
import SingleMember from "./SingleMember";
import TemplateCardSummary from "@/modules/big-data/components/TemplateCardSummary";
import template from "@/modules/big-data/mixins/list-template";
import localConfig from "./member-interact";

export default {
  name: "MemberInteract",
  mixins: [template],
  components: { TemplateCardSummary, SingleMember, DataEmpty },
  inject: ["getData", "grid", "disabled"],
  props: {},
  data() {
    return {
      handle: false,
    };
  },
  watch: {
    // 配置改变
    config: {
      handler(val, oldVal) {
        console.log(val, oldVal);
        // 限制数量改变重新筛选
        if (val.users_limit !== oldVal.users_limit) {
          // 更新请求
          this.$emit('update',{ users_limit: val.users_limit });
        }
      },
      deep: true,
    },
  },
  methods: {
    getShowData(query, callback) {
      this.stopAnimation();
      console.log(this.config);
      const queryObj = {
        data_key: this.config.data_show[0],
        users_limit: this.config.users_limit, // 页码
        ...(this.queryObj || {}),

        ...query,
      };
      getMemberInteractData(queryObj)
        .then((res) => {
          this.responseData = res.data;
          console.log(this.responseData, "member");
          this.queryObj = queryObj;

          // TODO: 动画开关
          setTimeout(() => {
            this.animate();
          }, 1500);

          callback && callback();
        })
        .catch((err) => {
          console.log(err);
          callback && callback();
        });
    },
    animate() {
      if (this.grid && !this.disabled) return;
      const list = this.listData;
      console.log(list)
      if (list.length > 3 && this.grid) {
        this.buildTimer({
          interval: 1000,
        });
      }
    },
  },

  computed: {
    config() {
      return this.getData().config || localConfig.form;
    },
    isEmpty() {
      return !this.responseData?.list?.length;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/modules/big-data/scss/template.scss";

.grid {
  width: 100%;
  height: 100%;
  padding: 0 0.16em;

  &.two {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 0.12em;
  }

  .grid-item {
    padding: 0.12em;
    margin-top: 0.08em;
    position: relative;
    background-color: rgba(4, 39, 45, 0.7);
    align-items: center;

    // &:after {
    //   content: "";
    //   position: absolute;
    //   right: 0.12em;
    //   top: calc(50% - 0.025em);
    //   /*border-top: .14em solid #082226;*/
    //   border-top: 0.05em solid transparent;
    //   border-left: 0.05em solid transparent;
    //   border-right: 0.05em solid rgba(75, 237, 156, 1);
    //   border-bottom: 0.05em solid transparent;
    //   width: 0;
    //   height: 0;
    //   box-sizing: content-box;
    // }
  }
}
</style>
