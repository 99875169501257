<template>
  <div class="template-card-summary summary flex-between">
    <div class="flex">
      <slot/>
      <!--
       "status": 0,//环比状态，0：无变化，1：上升，2：下降
      "diff_count": 0, //上升or下降的值
      -->
      <span class="percent">{{percentText}} <span class="emphasis" :class="[status === 2 ? 'down' : 'up']">{{diff_count}} %</span><img
          v-if="status === 2" class="down-icon" src="../assets/down.png" /><img
          v-else-if="status === 1" class="up-icon" src="../assets/up.png" /></span>
    </div>
    <div class="summary-right">
      <div class="el-table flex-align-center cursor-pointer" @click.stop="onToggleDesc">
        <span class="order">时间{{desc === 'ascending' ? '升序' : '倒序'}}</span>
        <span class="caret-wrapper is-sortable" :class="[desc]">
          <i class="sort-caret ascending" />
          <i class="sort-caret descending" />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TemplateCardSummary",
  props: ['diff_count', 'status', 'compare_type'],
  data() {
    return {
      desc: 'descending',
      sortArr: ['ascending', 'descending'],
      percentArr: ['周环比', '月环比', '年环比', '近三个月环比', '环比'],
    }
  },
  computed: {
    percentText() {
      return this.percentArr[this.compare_type || 0]
    }
  },
  methods: {
    onToggleDesc() {
      this.desc = this.sortArr.find(el => el !== this.desc)
      this.$emit('sort-by-time', this.desc === 'ascending' ? 0 : 1)
    }
  },
}
</script>

<style lang="scss" scoped>
  .summary {
    color: rgba(255,255,255,1);
    padding: .125em .16em 0.1em;

    .total, .percent, .order {
      font-size: .14em;
      font-weight: bold;
      line-height: 1.5;
    }

    .percent {
      margin-left: calc(32em / 14);
    }

    .emphasis {
      color: var(--hover-color);
      font-weight: bolder;
      font-size: 1.2em;
      transform: scaleX(.8);
      display: inline-block;

      &.down {
        color: $danger;
      }
    }

    .down-icon, .up-icon {
      width: .857em;
      height: .857em;
      /*margin-left: 0.857em;*/
      display: inline-block;
      vertical-align: center;
    }

    .up-icon {
      animation: .7s up infinite;
    }

    .down-icon {
      animation: .7s down infinite;
    }
  }

  @keyframes up {
    from {
      transform: translateY(0%);
      opacity: 1;
    }

    to {
      transform: translateY(-25%);
      opacity: .85;
    }
  }

  @keyframes down {
    from {
      transform: translateY(0%);
      opacity: 1;
    }

    to {
      transform: translateY(25%);
      opacity: 0.85;
    }
  }

  .el-table {
    background-color: unset;
    height: 1.5em;
    @include no-select();
  }

  .el-table .caret-wrapper {
    transform: scaleX(.75);
  }

  .el-table .is-sortable.ascending .sort-caret.ascending {
    border-bottom-color: var(--hover-color);
  }

  .el-table .is-sortable.descending .sort-caret.descending {
    border-top-color: var(--hover-color);
  }

</style>
