import api from "@/base/utils/request";

export const getSettings = () => {
  return api({
    url: '/admin/admin/settings/getUserCheckSettings',
    method: 'post'
  })
}

export const setSettings = (config) => {
  return api({
    url: '/admin/admin/settings/setUserCheckSettings',
    method: 'post',
    data: {
      config
    }
  })
}
