<template>
  <div class="user-increase flex-center"
       @mouseout="play"
       @mouseenter="pause"
       @mouseover="pause">
    <div v-if="!isEmpty" class="chart" :id="randomId"/>
    <div v-if="isEmpty" class="flex-1 flex-center">
      <data-empty/>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core'
import { GridComponent, LegendComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer } from 'echarts/renderers'
import DataEmpty from '../../DataEmpty'
import { getHomeUserIncreaseData } from '../../../api/templates'
import { getRecentDay_Date, getRecentMonth_Date } from '../../../tool/time-range'
import template from "@/modules/big-data/mixins/list-template";

echarts.use([GridComponent, LineChart, CanvasRenderer, LegendComponent, UniversalTransition])

export default {
  name: 'UserIncrease',
  components: { DataEmpty },
  mixins: [template],
  inject: ['getData', 'grid', 'disabled'],
  data() {
    return {
    }
  },
  computed: {
    config() {
      return this.getData().config || {
        chart_type: {
          value: {
            key: 'line_chart',
            name: '折线图'
          }
        }, user_type: [
          {
            name: '个人会员',
            key: 'personal_user'
          }
        ]
      }
    },
  },
  watch: {
    config: {
      handler(val) {
        if (this.lastType && val.chart_type.value.key !== this.lastType) {
          // console.log('member fee chart_type change', val.chart_type.value)
          this.myChart.clear()
          this.myChart = null
          this.initOptions()
        }
      },
      deep: true
    }
  },
  created() {
    // console.log(getRecentDay_Date(7), getRecentDay_Date(-1), getRecentMonth_Date(-1))
  },
  beforeDestroy() {
    this.stopAnimation()
  },
  methods: {
    getShowData(query, callback = () => {
    }) {
      this.stopAnimation()
      const tasks = this.config.user_type.map(t => {
        return new Promise(resolve => {
          getHomeUserIncreaseData({
            ...query,
            user_type: t.key
          }).then(res => {
            resolve({data: res.data, key: t.key})
          }).catch(err => {
            console.log(err)
            resolve([])
          })
        })
      })
      Promise.all(tasks).then(res => {
        this.responseData = res
        if (res.length && res.find(el => el.data.length !== 0)) {
          this.$nextTick(() => {
            this.initOptions()
          })
        }
        callback()
      }).catch(err => {
        console.log(err)
        callback()
      })
    },
    initOptions() {
      const type = this.config.chart_type.value?.key || 'line_chart'
      this.lastType = type

      var chartDom = document.getElementById(this.randomId)
      if (this.myChart) {
        this.myChart.off('mouseover')
        this.myChart.off('mouseoout')
        this.myChart.off('mouseenter')
      }
      var myChart = echarts.init(chartDom, 'dark')
      var option

      if (type === 'bar_chart') {
        option = this.getBarOption()
      } else if (type === 'line_chart') {
        option = this.getLineOption()
      }

      option && myChart.setOption(option)
      this.myChart = myChart
      let that = this
      myChart.on('mouseover', function() {
        console.log('echarts mouseover')
        that.pause()
      })
      myChart.on('mouseenter', function() {
        console.log('echarts mouseenter')
        that.pause()
      })
      // myChart.on('mouseout', function() {
      //   console.log('echarts mouseout')
      //   that.play()
      // })

      // TODO: 动画开关
      setTimeout(() => {
        if (!this.handle) this.animate()
      }, 3000)
    },
    animate() {
      if (this.grid && !this.disabled) return
      if (this.grid) {
        this.buildTimer({
          each: anchor => {
            this.myChart.dispatchAction({
              type: 'showTip',
              // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
              seriesIndex: 0,
              // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
              dataIndex: anchor,
              gridIndex: 0
            })
          },
          getEndStatus: anchor => {
            const list = this.responseData?.find(el => !!el.data.length).data || []
            return anchor === list.length + 1
          }
        })
      }
    },
    getLineOption() {
      return {
        backgroundColor: 'rgba(3, 23, 26, .5)',
        title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        // dataZoom: [{
        //   type: 'slider',
        //   show: true, //flase直接隐藏图形
        //   xAxisIndex: [0],
        //   left: '9%', //滚动条靠左侧的百分比
        //   bottom: 5,
        //   start: 0,//滚动条的起始位置
        //   end: 50 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
        // }],
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: this.responseData[0].data.map(el => el.day_time)
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          data: this.config.user_type.map(el => el.name)
        },
        series: (() => {
          const color = [
            '#5470c6', '#91cc75', '#fac858',
            '#ee6666', '#73c0de', '#3ba272',
            '#fc8452', '#9a60b4', '#ea7ccc'
          ]
          return this.responseData.map((r, rI) => {
            const dataList = r.data.map(el => el.count)
            return {
              name: this.config.user_type[rI].name,
              type: 'line',
              // smooth: true,
              data: dataList,
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 2, [
                  {
                    offset: 0,
                    color: color[rI]
                  },
                  {
                    offset: 1,
                    // color: 'rgba(255, 255, 255, 0)'
                    color: 'transparent'
                  }
                ])
              }
            }
          })
        })()
      }
    },
    getBarOption() {
      const color = [
        '#5470c6', '#91cc75', '#fac858',
        '#ee6666', '#73c0de', '#3ba272',
        '#fc8452', '#9a60b4', '#ea7ccc'
      ]
      return {
        backgroundColor: 'rgba(3, 23, 26, .5)',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        grid: {
          bottom: '15%',
          left: 50,
          right: 30
        },
        // color: [
        //   '#5470c6', '#91cc75', '#fac858',
        //   '#ee6666', '#73c0de', '#3ba272',
        //   '#fc8452', '#9a60b4', '#ea7ccc'
        // ],
        // dataZoom: [{
        //   type: 'slider',
        //   show: true, //false直接隐藏图形
        //   xAxisIndex: [0],
        //   left: '9%', //滚动条靠左侧的百分比
        //   bottom: -5,
        //   start: 0,//滚动条的起始位置
        //   end: 50 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
        // }],
        xAxis: {
          type: 'category',
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: (() => {
            return this.responseData[0].data.map((r) => {
              return r.day_time
            })
          })()
        },
        yAxis: {
          type: 'value'
        },
        legend: {
          data: this.config.user_type.map(el => el.name),
          top: 20
        },
        series: (() => {
          return this.responseData.map((r, rI) => {
            const dataList = r.data.map((item, index) => {
              return ({
                value: item.count,
                itemStyle: {
                  color: color[rI]
                },
              })
            })
            return {
              type: 'bar',
              data: dataList,
              name: this.config.user_type.find(el => el.key === r.key).name
            }
          })
        })(),
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .user-increase {
    flex: 1;
    min-height: 0;
  }

  .chart {
    width: 100%;
    height: 100%;
  }
</style>
