<template>
    <div
      class="user-increase flex-center"
      @mouseout="play"
      @mouseenter="pause"
      @mouseover="pause"
    >
    <div class="chart-box" v-if="!isEmpty">
    <div v-if="!isEmpty" class="chart" :id="randomId"/>
  
  </div>
      <div v-if="isEmpty" class="flex-1 flex-center">
        <data-empty />
      </div>
    </div>
  </template>
    
    <script>
  import * as echarts from "echarts/core";
  import { GridComponent, LegendComponent } from "echarts/components";
  import { LineChart } from "echarts/charts";
  import { UniversalTransition } from "echarts/features";
  import { CanvasRenderer } from "echarts/renderers";
  import DataEmpty from "../../DataEmpty";
  import { getOrganizationFee} from "../../../api/templates/order-stats";
  
  import template from "@/modules/big-data/mixins/list-template";
import { logDocument } from '@/modules/system-admin/api/admin-user-log';
  const color = [
    "#5470c6",
    "#91cc75",
    "#fac858",
    "#ee6666",
    "#73c0de",
    "#3ba272",
    "#fc8452",
    "#9a60b4",
    "#ea7ccc",
  ];
  echarts.use([
    GridComponent,
    LineChart,
    CanvasRenderer,
    LegendComponent,
    UniversalTransition,
  ]);
  
  export default {
    components: { DataEmpty },
    mixins: [template],
    inject: ["getData", "grid", "disabled"],
    data() {
      return {};
    },
    computed: {
     
      config() {
        return (
          this.getData().config || {
            chart_type: {
              value: {
                key: "line_chart",
                name: "折线图",
              },
            },
          }
        );
      },
      isEmpty() {
  
        return !this.responseData?.length;
      },
    },
    watch: {
      config: {
        handler(val) {
          if (this.lastType && val.chart_type.value.key !== this.lastType) {
            // console.log('member fee chart_type change', val.chart_type.value)
            this.myChart.clear();
            this.myChart = null;
            this.initOptions();
          }
        },
        deep: true,
      },
    },
    created() {
      // console.log(
      //   getRecentDay_Date(7),
      //   getRecentDay_Date(-1),
      //   getRecentMonth_Date(-1)
      // );
    },
    beforeDestroy() {
      this.stopAnimation();
    },
    methods: {
      getShowData(query, callback = () => {}) {
        this.stopAnimation();
  

            getOrganizationFee({
          ...query,
        })
          .then((res) => {
            this.responseData = res.data;
            if(!this.isEmpty) { 
              this.$nextTick(() => {
              this.initOptions();
              // console.log(this.responseData, "会员统计");
            });
          }
           
  
            callback();
          })
          .catch((err) => {
            console.log(err);
            callback();
          });

        
      },
      initOptions() {
        console.log(123);
        const type = this.config.chart_type.value?.key || "line_chart";
        this.lastType = type;
  
        var chartDom = document.getElementById(this.randomId);
  
        if (this.myChart) {
          this.myChart.off("mouseover");
          this.myChart.off("mouseoout");
          this.myChart.off("mouseenter");
        }
        var myChart = echarts.init(chartDom, "dark");
        var option;
  
        if (type === "bar_chart") {
          option = this.getBarOption();
        } else if (type === "line_chart") {
          option = this.getLineOption();
        }
        // console.log(option, myChart);
        option && myChart.setOption(option);
        this.myChart = myChart;
        let that = this;
        myChart.on("mouseover", function () {
          console.log("echarts mouseover");
          that.pause();
        });
        myChart.on("mouseenter", function () {
          console.log("echarts mouseenter");
          that.pause();
        });
        myChart.on('mouseout', function() {
          console.log('echarts mouseout')
          that.play()
        })
  
        // TODO: 动画开关
        setTimeout(() => {
          if (!this.handle) this.animate();
        }, 3000);
      },
      animate() {
        if (this.grid && !this.disabled) return;
        if (this.grid) {
          this.buildTimer({
            each: (anchor) => {
              this.myChart.dispatchAction({
                type: "showTip",
                // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
                seriesIndex: 0,
                // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
                dataIndex: anchor,
                gridIndex: 0,
              });
            },
            getEndStatus: (anchor) => {
              let flag = anchor === this.responseData?.list?.login.length;
          
              return flag;
            },
          });
        }
      },
      getLineOption() {
        return {
          backgroundColor: "rgba(3, 23, 26, .5)",
          title: {
            text: "",
          },
          tooltip: {
            trigger: "axis",
          },
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          toolbox: {
            show: false,
            feature: {
              saveAsImage: {},
            },
          },
          xAxis: {
            type: "category",
            data: this.responseData[0]?this.responseData[0].list.map((el) => el.day_time):[],
          },
          yAxis: {
            type: "value",
          },
          legend: {
            data: this.responseData.map((el) => el.name),
          },
          series: (() => {
            return this.responseData.map((el,index) => {
              return {
                name: el.name,
                type: "line",
                data: el.list.map((el) => el.count),
                areaStyle: {
                  opacity: 0.8,
                  color: new echarts.graphic.LinearGradient(0, 0, 0, 2, [
                    {
                      offset: 0,
                      color: color[index],
                    },
                    {
                      offset: 1,
                      color: "transparent",
                    },
                  ]),
                },
              };
            });
          })(),
        };
      },
      getBarOption() {
        return {
          backgroundColor: "rgba(3, 23, 26, .5)",
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          // grid: {
          //   bottom: "15%",
          //   left: 70,
          //   right: 30,
          // },
          grid: {
          left: 70,
          right: "4%",
          bottom: "15%",

        },
          // color: [
          //   '#5470c6', '#91cc75', '#fac858',
          //   '#ee6666', '#73c0de', '#3ba272',
          //   '#fc8452', '#9a60b4', '#ea7ccc'
          // ],
          // dataZoom: [{
          //   type: 'slider',
          //   show: true, //false直接隐藏图形
          //   xAxisIndex: [0],
          //   left: '9%', //滚动条靠左侧的百分比
          //   bottom: -5,
          //   start: 0,//滚动条的起始位置
          //   end: 50 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
          // }],
          xAxis: {
            type: "category",
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            data: (() => {
              return this.responseData[0]?this.responseData[0].list.map((r) => {
                return r.day_time;
              }):[];
            })(),
          },
          yAxis: {
            type: "value",
          },
          legend: {
            data: this.responseData.map((el) => el.name),
          },
          series: (() => {
            return this.responseData.map((el, index) => {
              return {
                type: "bar",
                data: el.list.map((item) => {
                  console.log(el.name);
                  return {
                    value: item.count,
                    itemStyle: {
                      color:color[index],
                    },
                   
                  };
                }),
                name:el.name
              };
            });
       
          })(),
        };
      },
    },
  };
  </script>
    
    <style lang="scss" scoped>
    .user-increase {
      flex: 1;
      min-height: 0;
    }
  
    .chart-box {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 0.16em;
    .chart {
      flex: 1;
    }
  }
  </style>
    