<template>
  <el-dialog :title="tenantVersion === 'school' ? '选择校友' : '选择会员'" :visible.sync="openDialog" width="58%"
    :show-close="false" class="dialog-vertical" @close="cancel">
    <div class="head-filter" :style="tenantVersion === 'school' ? 'width: 80%' : 'width: 50%'">
      <el-cascader v-if="tenantVersion === 'school'" v-model="PeriodData" :options="CampusAndPeriod.campuses" :props="{
        value: 'id',
        label: 'name',
      }" placeholder="学段">
      </el-cascader>
      <el-cascader v-if="tenantVersion === 'school'" v-model="CampusData" :options="CampusAndPeriod.periods" :props="{
        value: 'id',
        label: 'name',
      }" placeholder="校区">
      </el-cascader>
      <el-cascader v-if="tenantVersion === 'school'" v-model="EntranceYearsData" :options="EntranceYears" :props="{
        value: 'value',
        label: 'label',
      }" placeholder="毕业年份">
      </el-cascader>
      <!-- ！ -->
      <el-cascader v-model="positionIds" :options="OrganizationList" :props="props" placeholder="会内职务"> </el-cascader>
      <el-input v-model="filterForm.keyword" placeholder="请输入会员名称" @change="handleFilter">
        <i class="el-icon-search el-input__icon" slot="suffix"></i>
      </el-input>
    </div>
    <div class="member-list" style="margin-top: 35px">
      <el-table :data="memberList" style="width: 100%" :height="350" ref="multipleTable"
        :highlight-current-row="!selectType" @row-click="handleCurrentChange" @selection-change="handleSelectionChange"
        v-loading="loading" v-loadmore="LoadMore">
        <!-- 多选 -->
        <el-table-column ref="selection" fixed type="selection" width="70" :selectable="selectable" v-if="selectType"
          :key="Math.random()"></el-table-column>
        <!-- 单选 -->
        <el-table-column fixed width="70" v-if="!selectType" :key="Math.random()">
          <template slot-scope="scope">
            <el-radio :label="scope.row" v-model="currentRow"
              :disabled="disableMembers.length ? (disableMembers.findIndex(item => item.invite_user_position_id === scope.row.invite_user_position_id) !== -1) : (selectedMembers.findIndex((item) => item.invite_user_position_id === scope.row.invite_user_position_id) !== -1)">
              {{ "&nbsp" }}</el-radio>
          </template>
        </el-table-column>
        <template v-for="(th, index) in thead">
          <el-table-column :prop="th.prop" :label="th.label" :width="th.width || ''" :key="index">
            <template slot-scope="{row}">
              <template v-if="th.prop === 'userPosition'">
                <el-select :disabled="row[th.prop].length<=1" v-model="row.invite_user_position_id" placeholder="请选择">
                  <el-option v-for="item in row[th.prop]" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
              </template>
              <p v-else style="white-space: pre-wrap;">{{ row[th.prop] | placeholder }} </p>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <template #footer>
      <el-button size="small" @click="openDialog = false">取消</el-button>
      <el-button size="small" type="primary" @click="submit">确定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import { SearchMemberList, positionLevel } from "../../api/benefits/member-detail";

import debounce from "lodash/debounce";

export default {
  model: {
    prop: "open",
    event: "closeDialog",
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
    // 已选择会员数据
    Members: {
      type: Array,
      default: () => [],
    },
    // 限制选择的会员数据
    disableMembers: {
      type: Array,
      default: () => [],
    },
    // 0:单选/1:多选模式
    selectType: {
      type: Number,
      default: 1,
    },
    //点击确定后返回数据形式:适用多选(total: 已选中和弹窗选中会员总和/check：弹窗当前选中会员)
    selectedType: {
      type: String,
      default: "total",
    },
    // 当前选中索引
    currentIndex: {
      type: Number,
      default: 0,
    },
    // 选择数量限制
    limit: {
      type: Number,
      default: 99,
    },
    hash: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    if (this.tenantVersion === "school") {
      this.thead.push({ label: "班级", prop: "class_info" });
    }
  },
  data() {
    const thead = [
      { label: "会员名称", prop: "nickname" },
      { label: "会内职务", prop: "userPosition" },
    ];
    return {
      EntranceYears: [],
      EntranceYearsData: [],
      CampusAndPeriod: [],
      CampusData: [],
      PeriodData: [],
      positionIds: [],
      loading: false, //加载
      thead,
      memberList: [],
      //筛选对象
      filterForm: {
        organization_id: "",
        position_id: "",
        keyword: "", //关键词
        page_size: 10, //每页多少条数据
        page: 1,
      },
      getable: true, //是否可以请求列表
      // 联级选择配置
      props: {
        value: "id",
        label: "name",
        expandTrigger: "hover",
        children: "position",
      },
      currentRow: "", // 单选时选中的数据项
      OrganizationList: [],
      selectArr: [], //勾选的数组id
    };
  },
  methods: {
    // 获取会员列表
    getSearchMemberList: debounce(function (pageData) {
      if (this.getable) {
        this.loading = true;
        SearchMemberList({ ...pageData, hash_invite_user_position_id: this.hash ? 1 : 0 })
          .then((res) => {
            let { data } = res;
            console.log(data);
            data.data = data.data.map((item) => {
              let userPosition = item.userPosition.map(item2 => ({
                label: item2.join_organization_name + ' | ' + item2.position_name,
                value: item2.id
              }))
              return {
                ...item,
                userPosition,
                invite_user_position_id: userPosition[0].value,
              }
            })
            console.log(data);
            if (pageData.page === 1) {
              this.memberList = data.data;
            } else {
              this.memberList = [...this.memberList, ...data.data];
            }
            if (data.data.length < this.filterForm.page_size) {
              this.getable = false;
            }
            this.filterForm.page++;
            this.loading = false;
          })
          .catch((err) => { });
      }
    }, 200),
    // 获取架构列表
    getParentToList() {
      if (this.tenantVersion === 'school') this.getSchoolFilterList();
      positionLevel({ is_list_tree: 0, is_show_all: 1, data_auth_filter: 0 })
        .then((res) => {
          const { data } = res;
          this.OrganizationList = data;
        })
        .catch((err) => { });
    },

    // 判断是否可选
    selectable(row, index) {
      let disableMembers = this.disableMembers.length ? this.disableMembers : this.selectedMembers;
      if (disableMembers.findIndex((item) => item.invite_user_position_id === row.invite_user_position_id) !== -1) {
        return false;
      } else {
        return true;
      }
    },
    // 筛选操作
    handleFilter(e, key) {
      const fliterData = { ...this.filterForm };
      fliterData.organization_id = fliterData.organization_id === "" ? -1 : fliterData.organization_id;
      fliterData.position_id = fliterData.position_id === "" ? -1 : fliterData.position_id;
      fliterData.page = key ? fliterData.page : 1;
      this.getable = true;
      this.getSearchMemberList(fliterData);
    },
    // 勾选操作
    handleSelectionChange(selection) {
      console.log(selection,211);
      if (!selection.length) {
        this.selectArr = [];
      } else {
        if (selection.length > this.limit) {
          this.selectArr = selection.slice(0, this.limit);
          const leftArr = selection.slice(this.limit);
          this.$message.error(`最多可选择 ${this.limit} 个会员`);
          leftArr.forEach((el) => {
            this.$refs.multipleTable.toggleRowSelection(el, false);
          });
        } else {
          this.selectArr = [...selection];
        }
      }
    },
    // 点击数据项操作
    handleCurrentChange(val) {
      console.log();
      if (!this.selectType) {
        let disabled
        if (this.disableMembers.length) {
          disabled = this.disableMembers.findIndex(item => item.invite_user_position_id === val.invite_user_position_id) !== -1
        } else {
          disabled = this.selectedMembers.findIndex((item) => item.invite_user_position_id === val.invite_user_position_id) !== -1
        }

        if (!disabled) this.currentRow = val;
      } else {
        if (this.selectedMembers.findIndex((item) => item.invite_user_position_id === val.invite_user_position_id) !== -1) {
          return;
        } else {
          this.memberList.forEach((element) => {
            if (val.invite_user_position_id === element.invite_user_position_id) {
              this.$nextTick(() => {
                this.$refs.multipleTable.toggleRowSelection(element);
              });
            }
          });
        }
      }
    },
    //触底刷新
    LoadMore() {
      if (this.getable) {
        this.handleFilter(this.filterForm, "Load");
      }
    },
    // 确定
    submit() {
      if (this.selectType) {
        if (!this.selectArr.length) {
          this.$message.info("请选择数据项");
        } else {
          const arr = this.selectedType === "total" ? [...this.selectedMembers, ...this.selectArr] : [...this.selectArr];
          this.selectedMembers = arr;
          this.$refs.multipleTable.clearSelection();
          this.selectArr = [];
          this.openDialog = false;
        }
      } else {
        if (!this.currentRow) {
          this.$message.info("请选择数据项");
        } else {
          this.selectedMembers.splice(this.currentIndex, 1, this.currentRow);
          this.$emit("changeSelect", this.selectedMembers);
          this.openDialog = false;
        }
      }
    },
    // 取消
    cancel(formName) {
      this.openDialog = false;
    },
  },
  watch: {
    openDialog(val) {
      if (val) {
        const data = { ...this.filterForm, page: 1 };
        this.getSearchMemberList(data);
        this.getParentToList();
      } else {
        this.$refs.multipleTable.clearSelection();
        this.selectArr = [];
      }
    },
    positionIds(val) {
      this.filterForm.position_id = val[val.length - 1] ? val[val.length - 1] : -1;
      this.filterForm.organization_id = val[0] ? val[0] : -1;
      this.handleFilter(this.filterForm);
    },
    EntranceYearsData(val) {
      this.filterForm.entrance_year = val[val.length - 1] ? val[val.length - 1] : -1;
      this.handleFilter(this.filterForm);
    },
    CampusData(val) {
      this.filterForm.campus_id = val[val.length - 1] ? val[val.length - 1] : "";
      this.handleFilter(this.filterForm);
    },
    PeriodData(val) {
      this.filterForm.period_id = val[val.length - 1] ? val[val.length - 1] : "";
      this.handleFilter(this.filterForm);
    },
    memberList: {
      deep: true,
      handler(val) {
        console.log(this.memberList, "this.memberList");
        this.memberList.forEach((el) => {
          if (this.selectArr.findIndex((elem) => elem.invite_user_position_id === el.invite_user_position_id) !== -1 && this.$refs.multipleTable) {
            this.$nextTick(() => {
              this.$refs.multipleTable.toggleRowSelection(el, true);
            });
          }
        });
      },
    },
  },
  computed: {
    tenantVersion() {
      return this.$store.getters.tenant.version;
    },
    openDialog: {
      get() {
        return this.open;
      },
      set(val) {
        this.$emit("closeDialog", val);
      },
    },
    selectedMembers: {
      get() {
        return this.Members;
      },
      set(val) {
        this.$emit("changeSelect", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.check-post {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.head-filter {
  // width: 80%;
  display: flex;
  position: absolute;
  top: 40px;
  right: 40px;
  justify-content: flex-end;

  .el-select,
  .el-cascader,
  .el-input {
    flex: 1;
    margin-right: 10px;
  }
}

.member-list {
  ::v-deep.el-table__row {
    cursor: pointer;
  }
}
</style>
