<template>
  <div v-loading="loading">
    <h2 class="title">权限配置</h2>
    <div>
      <el-form label-width="120px" label-position="right">
        <el-form-item label="免审用户:" prop="users">
          <el-button type="primary" @click="openSelector = true"
            >弹窗选择会员</el-button
          >
          <!-- {{ this.config.users }} -->
          <div class="users-list">
            <div class="label">已选用户：</div>
            <div class="users-box">
              <div
                class="item"
                v-for="item in this.config.users"
                :key="item.id"
              >
                <!-- 对应会员信息 -->
                <el-tag closable type="primary" @close="removeUser(item)">
                  {{
                    `${item.nickname}  ${item.phone}  ${
                      item.title_name ? item.title_name : ''
                    }`
                  }}
                </el-tag>
              </div>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="免审内容:" prop="content">
          <el-checkbox
            :indeterminate="isIndeterminate"
            v-model="checkAll"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <el-checkbox-group
            v-model="checkedData"
            @change="handleCheckedCitiesChange"
          >
            <div v-for="item in this.config.content" :key="item.key">
              <el-checkbox :label="item.key">{{ item.name }}</el-checkbox>
            </div>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
    </div>
    <fixed-action-bar>
      <el-button
        type="primary"
        :loading="saveLoading"
        :disabled="!config"
        @click="saveMySettings"
        >保存</el-button
      >
    </fixed-action-bar>
    <MemberSelector
      v-model="openSelector"
      @changeSelect="selectMember"
      :selectType="1"
    />
  </div>
</template>

<script>
import {
  getSettings,
  setSettings,
} from '@/modules/user-check-settings/api/settings'
import FixedActionBar from '@/base/layout/FixedActionBar'
import TemplateCard from '@/modules/big-data/components/TemplateCard.vue'
import MemberSelector from '@/modules/invite-members/components/benefits/MemberSelector.vue'

export default {
  name: 'settings',
  components: { FixedActionBar, MemberSelector },
  data() {
    return {
      loading: true,
      // 选择会员
      openSelector: false,
      saveLoading: false,
      config: {
        content: [],
      },
      checkAll: false,
      checkedData: [],
      isIndeterminate: false,
    }
  },
  created() {
    this.getMySettings()
  },
  methods: {
    // 删除会员
    removeUser(item) {
      let index = this.config.users.findIndex((e) => e.id === item.id)
      if (index !== -1) {
        this.config.users.splice(index, 1)
      }
    },
    // 选择会员
    selectMember(data) {
      console.log(data, 'MemberSelector')
      // 添加会员数据
      data.forEach((item) => {
        if (this.config.users.findIndex((e) => e.id === item.id) === -1) {
          this.config.users.push({
            id: item.id,
            nickname: item.nickname,
            phone: item.phone,
            title_name: item.userPosition[0].label,
          })
        }
      })
      console.log(this.config.users, 'this.config.users')
      this.openSelector = false
    },
    handleCheckAllChange(val) {
      console.log(val)
      this.checkedData = val ? this.config.content.map((item) => item.key) : []
      this.isIndeterminate = false
    },
    handleCheckedCitiesChange(value) {
      console.log(value, 'value')
      let checkedCount = value.length
      this.checkAll = checkedCount === this.config.content.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.config.content.length
    },
    getMySettings() {
      getSettings()
        .then((res) => {
          this.checkedData = []
          res.data.content.forEach((item) => {
            if (item.status) {
              this.checkedData.push(item.key)
            }
          })
          this.config = res.data
          this.checkAll = this.checkedData.length === this.config.content.length
          // 选了几个，没选完，显示不确定状态
          if (!this.checkAll && this.checkedData.length) {
            this.isIndeterminate = true
          }
          console.log(this.config, 'this.config')
          this.loading = false
        })
        .catch((err) => {
          this.loading = false
        })
    },
    saveMySettings() {
      this.saveLoading = true
      this.config.content.forEach((item) => {
        if (this.checkedData.includes(item.key)) {
          item.status = 1
        } else {
          item.status = 0
        }
      })
      setSettings(this.config)
        .then((res) => {
          this.$message.success(res.msg)
          this.saveLoading = false
        })
        .catch((err) => {
          this.saveLoading = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  padding-left: 8px;
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  line-height: 1;
  border-left: 3px solid #3479ef;
  margin-bottom: 32px;
}

.el-checkbox:nth-child(1),
.el-checkbox:nth-child(2) {
  min-width: 160px;
}
.users-list {
  display: flex;
  margin-top: 24px;
  .label {
  }
}
</style>
