import api from "@/base/utils/request";
// 获取收入统计组件数据
export const getIncomesStats = (data) => {
  return api({
    url: '/admin/data_view/orderStats/incomesStats',
    method: 'post',
    data,
    notCancel: true
  })
}
// 获取支出统计组件数据
export const getExpendStats = (data) => {
    return api({
      url: '/admin/data_view/orderStats/expendStats',
      method: 'post',
      data,
      notCancel: true
    })
  }
  // 获取会费统计组件数据
export const getMemberFeeStats = (data) => {
    return api({
      url: '/admin/data_view/orderStats/memberFeeStats',
      method: 'post',
      data,
      notCancel: true
    })
  }
    // 获取历届会费统计组件数据
export const getAllMemberFee = () => {
    return api({
      url: '/admin/data_view/orderStats/allMemberFee',
      method: 'post',
      notCancel: true
    })
  }
     // 获取活动报名费统计组件数据
export const getActivitySignupFee= (data) => {
    return api({
      url: '/admin/data_view/orderStats/activitySignupFee',
      method: 'post',
      data,
      notCancel: true
    })
  }
       // 获取活动服务费费统计组件数据
export const getActivityServiceFee= (data) => {
    return api({
      url: '/admin/data_view/orderStats/activityServiceFee',
      method: 'post',
      data,
      notCancel: true
    })
  }
       // 获取组织会费费统计组件数据
export const getOrganizationFee= (data) => {
    return api({
      url: '/admin/data_view/orderStats/organizationFee',
      method: 'post',
      data,
      notCancel: true
    })
  }
         // 获取供求帖子付费统计组件数据
export const getCycCirclePayRead= (data) => {
    return api({
      url: '/admin/data_view/orderStats/cycCirclePayRead',
      method: 'post',
      data,
      notCancel: true
    })
  }
        //  获取供求帖子付费置顶统计组件数据
export const getCycCircleTopFee= (data) => {
    return api({
      url: '/admin/data_view/orderStats/cycCircleTopFee',
      method: 'post',
      data,
      notCancel: true
    })
  }

  