<template>
  <div
    class="template-card-content company-category flex-center"
    @mouseover="pause"
    @mouseout="play"
  >
    <div
      class="chart"
      :id="randomId"
      :class="{ 'flex-center': isConfigEmpty || isEmpty }"
    ></div>
    <div v-if="isEmpty || isConfigEmpty" class="empty flex-1 flex-center">
      <data-empty />
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts/core";
import {
  DataZoomComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
} from "echarts/components";
import { PieChart, RadarChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import { randomString } from "@/base/utils/tool";
import { getHomeMemberPhotoData } from "@/modules/big-data/api/templates";
import DataEmpty from "@/modules/big-data/components/DataEmpty";
import localConfig from "./member-photo";

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  PieChart,
  RadarChart,
  CanvasRenderer,
  UniversalTransition,
  DataZoomComponent,
]);

let timer = null;
let anchor = 0;

export default {
  name: "MemberPhoto",
  components: { DataEmpty },
  inject: ["getData", "grid", "disabled"],
  data() {
    return {
      randomId: randomString(16),
      responseData: [],
      isConfigEmpty: false,
    };
  },
  computed: {
    config() {
      return this.$mergeModel(localConfig.form, this.getData().config);
      return (
        this.getData().config || {
          chart_type: {
            value: {
              key: "pie_chart",
              name: "饼图",
            },
          },
        }
      );
    },
    isEmpty() {
      if (!this.responseData) return false;
      return (
        !this.responseData.length ||
        this.responseData.filter((r) => !!r.list.length).length === 0
      );
    },
  },
  watch: {
    // 配置改变
    config: {
      handler(val, oldVal) {
        console.log(val, "侦听会员画像配置改变###############");
        let isChange = false;
        let changeKey = "";
        // 需要改变图表类型
        if (oldVal && val.data_type !== oldVal.data_type) {
          // 销毁当前echar
          if (this.myChart) echarts.dispose(this.myChart);
          // 重新拿数据
          this.getShowData();
        } else if (
          // this.data_show_personal_user !== undefined &&
          // val.data_show?.personal_user?.value &&
          val.data_show?.personal_user?.value !== this.data_show_personal_user
        ) {
          // TODO: 监测 data_show 变化
          isChange = true;
          changeKey = "personal_user";
          console.log("old value is: ", this.data_show_personal_user);
          this.data_show_personal_user = val.data_show?.personal_user?.value;
        } else if (
          // this.data_show_unit_user !== undefined &&
          // val.data_show?.unit_user?.value &&
          val.data_show?.unit_user?.value !== this.data_show_unit_user
        ) {
          changeKey = "unit_user";
          isChange = true;
          console.log("old value is: ", this.data_show_unit_user);

          this.data_show_unit_user = val.data_show?.unit_user?.value;
        }

        if (isChange) {
          console.log("isChange", changeKey);
          const newValue = val.data_show[changeKey]?.value;
          console.log("new value is: ", newValue);
          /*
          * 这些玩意儿都不起作用，为啥？？？
          * this.myChart?.isDisposed, this.myChart?.isDisposed()
          * this.myChart?.dispose()
            this.myChart?.clear()
            echarts.dispose()
            echarts.clear()
          * */
          /* el-select 的 clear 导致数据清空 */
          if (!newValue) {
            if (this.myChart) echarts.dispose(this.myChart);
            this.responseData = this.responseData.filter(
              (r) => r.type !== changeKey
            );
            console.log(this.responseData, "rrrrrr length");
            if (this.responseData.length === 0) this.isConfigEmpty = true;
            this.initOptions();
            console.log("**************************");
            return;
          }
          this.getShowData();
          return;
        }
        if (this.lastType && val.chart_type.value.key !== this.lastType) {
          this.myChart?.clear();
          this.myChart = null;
          this.initOptions();
        }
      },
      deep: true,
    },
    // 'config.data_type'() {
    //   echarts.dispose(this.myChart)
    //   this.myChart = null
    //   this.getShowData()
    // }
  },
  mounted() {
    // console.log('company category mounted')
  },
  beforeDestroy() {
    this.stopAnimation();
  },
  methods: {
    pause() {
      // 手动切换状态
      this.handle = true;
      // console.log("动画被鼠标事件打断");
      // TODO: 暂停动画，但不想将 anchor 进度清空
      if (timer) {
        clearInterval(timer);
      }
      this.myChart?.dispatchAction({
        type: "hideTip",
      });
    },
    play() {
      this.handle = false;
      console.log("动画被鼠标事件打断后恢复");
      this.animate();
    },
    resize() {
      const type = this.config.chart_type.value?.key || "pie_chart";
      if (type === "pie_chart") {
        // TODO: 是否根据 resize 尺寸来调整 饼图组合形式
        setTimeout(() => {
          this.myChart?.setOption({ radar: this.getPieOption().series });
          if (this.myChart) this.myChart.resize();
        }, 300);
      } else if (type === "radar_chart") {
        setTimeout(() => {
          this.myChart?.setOption({ radar: this.getRadarOption().radar });
          if (this.myChart) this.myChart.resize();
        }, 300);
      } else {
        if (this.myChart) this.myChart.resize();
      }
    },
    // 暂停动画
    stopAnimation() {
      this.myChart?.dispatchAction({
        type: "hideTip",
      });
      if (timer) {
        clearInterval(timer);
        timer = null;
        anchor = 0;
      }
    },
    animate() {
      if (this.grid && !this.disabled) return;
      if (!this.responseData || !this.responseData.length) return;
      let that = this;
      const len1 = this.responseData[0].list.length;
      const len2 =
        (this.responseData[1] && this.responseData[1].list.length) || 0;
      if ((len1 || len2) && this.grid) {
        if (timer) clearInterval(timer);
        timer = setInterval(() => {
          const max = len1 + len2;
          if (anchor === max && timer) {
            this.stopAnimation();
            this.animate();
            return;
          }
          // this.myChart.dispatchAction({
          //   type: 'toggleSelected',
          //   dataIndex: anchor
          // })
          // 先判断到哪个 grid
          const gridIndex = anchor >= len1 ? 1 : 0;
          const dataIndex = gridIndex ? anchor - len1 : anchor;
          // 自动高亮显示
          var chartHover = function () {
            // 取消之前高亮的图形
            if (dataIndex >= 1) {
              that.myChart.dispatchAction({
                type: "downplay",
                seriesIndex: gridIndex,
                dataIndex: dataIndex - 1,
                gridIndex: gridIndex,
              });
            }

            // 高亮当前图形
            that.myChart.dispatchAction({
              type: "highlight",
              seriesIndex: gridIndex,
              dataIndex: dataIndex,
              gridIndex: gridIndex,
            });
            // 显示 tooltip
            that.myChart.dispatchAction({
              type: "showTip",
              seriesIndex: gridIndex,
              dataIndex: dataIndex,
              gridIndex: gridIndex,
            });
          };
          const type = this.config.chart_type.value?.key || "line_chart";
          if (type === "pie_chart") {
            chartHover();
          } else {
            // if (list.length - 1 - anchor >= 5) {
            //   this.myChart.dispatchAction({
            //     type: 'dataZoom',
            //     // 可选，dataZoom 组件的 index，多个 dataZoom 组件时有用，默认为 0
            //     dataZoomIndex: 0,
            //     // 开始位置的百分比，0 - 100
            //     // start: 0,
            //     // 结束位置的百分比，0 - 100
            //     // end: 100,
            //     // 开始位置的数值
            //     startValue: list.length - 1 - anchor,
            //     // 结束位置的数值
            //     endValue: list.length - 1 - 5 - (anchor)
            //   })
            // }

            // 显示 tooltip
            that.myChart.dispatchAction({
              type: "showTip",
              seriesIndex: gridIndex,
              dataIndex: dataIndex,
              gridIndex: gridIndex,
              radarIndex: gridIndex,
              // xAxisIndex: rI,
              // yAxisIndex: rI
            });
          }
          anchor++;
        }, 1500);
      }
    },
    // 获取数据
    getShowData(query = {}, callback = () => {}) {
      this.stopAnimation();

      const allKeys = ["personal_user", "unit_user"];
      const keys = [];
      allKeys.forEach((k) => {
        const data_show = this.config.data_show[k];
        if (data_show?.value && this.config.data_type === k) keys.push(k);
        else {
          // this[`data_show_${k}`] = ''
        }
      });
      this.isConfigEmpty = keys.length === 0;
      if (this.isConfigEmpty) {
        callback();
        return;
      }

      // if (this.responseData?.length) {
      //   this.$nextTick(() => {
      //     this.initOptions()
      //   })
      //   callback()
      //   return
      // }

      const tasks = keys.map((t) => {
        return new Promise((resolve) => {
          getHomeMemberPhotoData({
            form_key: this.config.data_show[t]?.value,
            form_type: t,
            ...query,
          })
            .then((res) => {
              resolve({
                ...res.data,
                type: t,
                name: t === "unit_user" ? "单位会员" : "个人会员",
              });
              this[`data_show_${t}`] = this.config.data_show[t]?.value;
            })
            .catch((err) => {
              console.log(err);
              resolve([]);
            });
        });
      });
      Promise.all(tasks)
        .then((res) => {
          this.responseData = res;
          console.log(this.responseData)
          if (res.length && res.find((el) => el.list.length !== 0)) {
            this.$nextTick(() => {
              this.initOptions();
            });
          }
          callback();
        })
        .catch((err) => {
          console.log(err);
          callback();
        });
    },
    // 初始化图表
    initOptions() {
      // console.log('init chart', this.myChart)
      // if (this.myChart) {
      //   // this.myChart.dispose()
      //   this.myChart.resize()
      //   return
      // }
      const type = this.config.chart_type.value?.key || "pie_chart";
      this.lastType = type;

      var chartDom = document.getElementById(this.randomId);

      let myChart = echarts.getInstanceByDom(chartDom); //有的话就获取已有echarts实例的DOM节点。
      if (myChart == null) {
        // 如果不存在，就进行初始化。
        myChart = echarts.init(chartDom, "dark");
      }

      var option;

      if (type === "bar_chart") {
        option = this.getBarOption();
      } else if (type === "pie_chart") {
        option = this.getPieOption();
      } else {
        option = this.getRadarOption();
      }

      option && myChart.setOption(option);
      this.myChart = myChart;

      // TODO: 动画开关
      setTimeout(() => {
        this.animate();
      }, 3000);
    },
    getPieOption() {
      const color = [
        // '#4BD2ED',
        // '#4BEDDF',
        // '#EDB74B',
        // '#4BED9C',
        // '#8264FA',
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
      ];
      return {
        backgroundColor: "rgba(3, 23, 26, .5)",
        tooltip: {
          trigger: "item",
          position: function (point, params, dom, rect, size) {
            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            // 提示框位置
            var x = 0; // x坐标位置
            var y = 0; // y坐标位置

            // 当前鼠标位置
            var pointX = point[0];
            var pointY = point[1];

            // 外层div大小
            // var viewWidth = size.viewSize[0];
            // var viewHeight = size.viewSize[1];

            // 提示框大小
            var boxWidth = size.contentSize[0];
            var boxHeight = size.contentSize[1];

            // boxWidth > pointX 说明鼠标左边放不下提示框
            if (boxWidth > pointX) {
              x = 5;
            } else {
              // 左边放的下
              x = pointX - boxWidth;
            }

            // boxHeight > pointY 说明鼠标上边放不下提示框
            if (boxHeight > pointY) {
              y = 5;
            } else {
              // 上边放得下
              y = pointY - boxHeight;
            }

            return [x, y];
          },
          //折线（区域）图、柱状（条形）图、K线图 : a（系列名称），b（类目值），c（数值）, d（无）
          //散点图（气泡）图 : a（系列名称），b（数据名称），c（数值数组）, d（无）
          //地图 : a（系列名称），b（区域名称），c（合并数值）, d（无）
          //饼图、雷达图、仪表盘、漏斗图: a（系列名称），b（数据项名称），c（数值）, d（饼图：百分比 | 雷达图：指标名称）
          // formatter: '{a} <br/><span style="min-width: 100px;">{b}</span> {c} <br/> 占比率 {d}%',//默认值null，内容格式器
          formatter: function (parms) {
            return (
              parms.seriesName +
              "</br>" +
              "<div style='display: flex; flex-direction: column;min-width: 120px;'>" +
              "<div style='display: flex; justify-content: space-between;'>" +
              "<span>" +
              parms.marker +
              parms.data.name +
              "</span>" +
              "<span>" +
              parms.data.value +
              "</span>" +
              "</div>" +
              "<div style='display: flex; justify-content: space-between;'>" +
              "<span>占比率</span>" +
              "<span>" +
              parms.percent +
              "%" +
              "</span>" +
              "</div>" +
              "</div>"
            );
          },
        },
        legend: {
          left: "center",
          type: "scroll",
          bottom: "5%",
          right: "5%",
        },
        series: this.responseData.map((r, rI) => {
          const list = r.list;
          const rowW = this.getData().w;
          const resDataLen = this.responseData.filter(
            (r) => !!r.list.length
          ).length;
          // const isContain = false && !(rowW > 1 && resDataLen > 1)
          const isContain = resDataLen === 1;
          return {
            // name: this.getData().name + '-' + r.name,
            // name: r.name + ' - ' + (rI ? this.config.data_show.unit_user.label: this.config.data_show.personal_user.label),
            // name: (rI ? this.config.data_show.unit_user.label: this.config.data_show.personal_user.label),
            name:
              this.getData().config.data_type === "unit_user"
                ? this.config.data_show.unit_user.label
                : this.config.data_show.personal_user.label,
            type: "pie",
            radius:
              true || !isContain
                ? ["40%", "70%"]
                : rI
                ? [0, "30%"]
                : ["50%", "70%"],
            // radius: '50%',
            center: ["50%", "50%"],
            data: list.map((l, lI) => ({
              value: l.count,
              name: l.name,
              itemStyle: {
                color: color[lI % color.length],
              },
            })),
            left: !isContain ? (rI ? "50%" : 0) : 0,
            right: !isContain ? (rI ? 0 : "50%") : 0,
            top: 0,
            bottom: "10%",
            label: {
              show: false,
              //   position: true || !isContain ? 'outer' : (rI ? 'inner' : 'outer'),
              //   alignTo: 'labelLine',
              //   bleedMargin: 5,
              //   fontSize: true || !isContain ? 16 : (rI ? 14 : 16)
            },
            // labelLine: {
            //   show: false
            // },
          };
        }),
      };
    },
    getBarOption() {
      const color = [
        "#5470c6",
        "#91cc75",
        "#fac858",
        "#ee6666",
        "#73c0de",
        "#3ba272",
        "#fc8452",
        "#9a60b4",
        "#ea7ccc",
      ];
      return {
        backgroundColor: "rgba(3, 23, 26, .5)",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        dataZoom: [
          {
            type: "slider",
            show: false, // false直接隐藏图形
            xAxisIndex: [0],
            //   yAxisIndex: [0],
            //   // left: '9%', // 滚动条靠左侧的百分比
            //   // bottom: -5,
            //   startValue: this.responseData.list.length - 1,
            //   endValue: this.responseData.list.length - 1 - 5,
            //   // start: 0,// 滚动条的起始位置
            //   // end: 50 // 滚动条的截止位置（按比例分割你的柱状图x轴长度）
            startValue: 0,
            endValue: 5,
          },
        ],
        yAxis: this.responseData.map((r, rI) => ({
          type: "value",
          gridIndex: rI,
        })),
        xAxis: (() => {
          const hasLongLabel = this.responseData[0].list.filter(
            (el) => el.name.length > 5 && this.responseData[0].list.length > 2
          ).length;
          return this.responseData.map((r, rI) => {
            return {
              type: "category",
              axisLabel: {
                showMaxLabel: true,
                // overflow: 'break',
                overflow: "truncate",
                // inside: true,
                padding: [0, 0, 0, 20],
                rotate: hasLongLabel ? 40 : 0,
              },
              data: (() => {
                return r.list.map((rr) => {
                  return rr.name || "未填";
                });
              })(),
              gridIndex: rI,
            };
          });
        })(),
        series: (() => {
          return this.responseData.map((r, rI) => {
            const list = r.list || [];
            const dataList = list.map((l, lI) => ({
              value: l.count,
              itemStyle: {
                color: color[lI % color.length],
              },
            }));
            return {
              type: "bar",
              data: dataList,
              xAxisIndex: rI,
              yAxisIndex: rI,
              gridIndex: rI,
            };
          });
        })(),
        grid: (() => {
          if (this.responseData.length === 1) {
            return [
              {
                width: "90%",
                left: 10,
                right: 10,
                top: 30,
                bottom: 20,
                containLabel: true,
                name: this.getData().name + "-" + this.responseData[0].name,
              },
            ];
          }
          return this.responseData.map((r, rI) => {
            return {
              top: rI ? "55%" : 50,
              width: "90%",
              bottom: rI ? 20 : "55%",
              left: 10,
              right: 10,
              containLabel: true,
              name: this.getData().name + "-" + r.name,
            };
          });
        })(),
        // title: (() => {
        //   return this.responseData.map((r, rI) => {
        //     return {
        //       text: r.name,
        //       top: rI ? '47%' : 10,
        //       width: '90%',
        //       bottom: rI ? 20 : '40%',
        //       left: 10,
        //       right: 10,
        //       containLabel: true,
        //     }
        //   })
        // })(),
      };
    },
    getRadarOption() {
      return {
        backgroundColor: "rgba(3, 23, 26, .5)",
        tooltip: {
          trigger: "item",
          axisPointer: {
            // type: 'shadow'
          },
        },
        // 配置图例组件
        // legend: {
        //   top: 20,
        //   left: 0,
        //   data: this.responseData.map(el => el.name)
        // },
        radar: (() => {
          const vertical = this.getData().w < this.getData().h;

          function center(rI) {
            return [(rI + 1) * 25 + rI * 25 + "%", "50%"];
          }

          return this.responseData.map((r, rI) => {
            const list = r.list || [];
            return {
              radarIndex: rI,
              indicator: list.map((l) => ({
                name: l.name,
                max: Math.max(...list.map((li) => li.count)) + 1,
                min: 0,
              })),
              shape: "circle",
              center:
                this.responseData.length > 1
                  ? !vertical
                    ? center(rI)
                    : center(rI).reverse()
                  : ["50%", "50%"],
              radius: "45%",
              splitNumber: 5,
              axisName: {
                // color: 'rgb(238, 197, 102)'
              },
              splitArea: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  // color: 'rgba(238, 197, 102, 0.5)'
                },
              },
            };
          });
        })(),
        series: (() => {
          return this.responseData.map((r, rI) => {
            const list = r.list || [];
            const myConfig = this.getData().config;
            return {
              type: "radar",
              data: [
                {
                  name: myConfig.data_show[myConfig.data_type].label || r.name,
                  value: list.map((ll) => ll.count),
                },
              ],
              radarIndex: rI,
            };
          });
        })(),
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import "~@/modules/big-data/scss/template.scss";

.template-card-content {
  position: relative;

  .empty {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }
}
</style>
