<template>
  <div class="member-fee flex-center"
       @mouseover="pause"
       @mouseout="play">
    <div v-if="!isEmpty" class="chart" :id="randomId" />
    <div v-if="isEmpty" class="flex-1 flex-center">
      <data-empty/>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent
} from 'echarts/components';
import { LineChart, BarChart, PieChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  LineChart,
  BarChart,
  PieChart,
  CanvasRenderer,
  UniversalTransition
]);
import { randomString } from "@/base/utils/tool";
import { getHomeMemberFeeData } from "@/modules/big-data/api/templates";
import DataEmpty from '../../DataEmpty'

let timer = null
let anchor = 0

export default {
  name: "MemberFee",
  components: { DataEmpty },
  inject: ['getData', 'grid', 'disabled'],
  data() {
    return {
      randomId: randomString(16),
      responseData: null,
    }
  },
  computed: {
    config() {
      return this.getData().config
    },
    isEmpty() {
      return !this.responseData?.length
    }
  },
  watch: {
    config: {
      handler(val) {
        if (this.lastType && val.chart_type.value.key !== this.lastType) {
          // console.log('member fee chart_type change', val.chart_type.value)
          this.myChart.clear()
          this.myChart = null
          this.initOptions()
        }
      },
      deep: true
    }
  },
  methods: {
    pause() {
      // 手动切换状态
      this.handle = true
      // console.log('动画被鼠标事件打断')
      // TODO: 暂停动画，但不想将 anchor 进度清空
      if (timer) {
        clearInterval(timer)
        timer = null
      }
      this.myChart?.dispatchAction({
        type: 'hideTip'
      })
    },
    play() {
      this.handle = false
      console.log('动画被鼠标事件打断后恢复')
      this.animate()
    },
    getShowData(query = {}, callback = () => {}) {
      this.stopAnimation()
      getHomeMemberFeeData(query).then(res => {
        this.responseData = res.data;
        this.$nextTick(() => {
          this.initOptions()
        })
        callback()
      }).catch(() => {
        callback()
      })
    },
    resize() {
      setTimeout(() => {
        if (this.myChart) {
          this.myChart.resize()
        }
      }, 500)
    },
    stopAnimation() {
      this.myChart?.dispatchAction({
        type: 'hideTip'
      })
      if (timer) {
        clearInterval(timer)
        timer = null
        anchor = 0
      }
    },
    animate() {
      if (this.grid && !this.disabled) return
      const list = this.responseData
      if (list.length && this.grid) {
        if (timer) clearInterval(timer)
        timer = setInterval(() => {
          const max = list.length
          if (anchor === max && timer) {
            this.stopAnimation()
            return
          }
          this.myChart.dispatchAction({
            type: 'showTip',
            // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
            // seriesIndex?: number,
            seriesIndex: 0,
            // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
            // dataIndex?: number,
            dataIndex: anchor,
            legendIndex: 0,
            // 可选，数据项名称，在有 dataIndex 的时候忽略
            // name?: string,
            // 本次显示 tooltip 的位置。只在本次 action 中生效。
            // 缺省则使用 option 中定义的 tooltip 位置。
            // position: number[] | string | Function,
          })
          anchor++
        }, 1500)
      }
    },
    initOptions() {
      // console.log('init chart', this.myChart)

      const type = this.config.chart_type.value?.key || 'line_chart'
      this.lastType = type

      var chartDom = document.getElementById(this.randomId);
      var myChart = echarts.init(chartDom, 'dark');
      var option;

      if (type === 'line_chart') {
        option = this.getLineOption()
      } else if (type === 'bar_chart') {
        option = this.getBarOption()
      } else if (type === 'pie_chart') {
        option = this.getPieOption()
      }

      option && myChart.setOption(option);
      this.myChart = myChart

      // TODO: 动画开关
      // this.$nextTick(() => {
      setTimeout(() => {
        this.animate()
      }, 1500)
      // })
    },
    getLineOption() {
     return  {
       backgroundColor: 'rgba(3, 23, 26, .5)',
       title: {
          text: ''
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: ['当日收入', '累计收入']
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
         show: false,
          feature: {
            saveAsImage: {}
          }
        },
        xAxis: {
          type: 'category',
          data: this.responseData.map(el => el.day_time)
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            name: '当日收入',
            type: 'line',
            // step: 'start',
            data: this.responseData.map(r => r.count)
          },
          // {
          //   name: 'Step Middle',
          //   type: 'line',
          //   step: 'middle',
          //   data: [220, 282, 201, 234, 290, 430, 410]
          // },
          {
            name: '累计收入',
            type: 'line',
            // step: 'end',
            data: this.responseData.map(r => r.accrue_count)
          }
        ]
      };
    },
    getBarOption() {
      return {
        backgroundColor: 'rgba(3, 23, 26, .5)',
        legend: {
          data: ['当日收入', '累计收入']
        },
        tooltip: {
          trigger: 'axis'
        },
        yAxis: {
          type: 'value'
        },
        xAxis: {
          type: 'category',
          data: this.responseData.map(el => el.day_time)
        },
        series: [
          {
            name: '当日收入',
            data: this.responseData.map(r => ({
              value: r.count,
              // itemStyle: {
              //   color: '#0f0'
              // }
            })),
            type: 'bar',
            // legendIndex: 0
          },
          {
            name: '累计收入',
            data: this.responseData.map(r => ({
              value: r.accrue_count,
              // itemStyle: {
              //   color: '#00f'
              // }
            })),
            type: 'bar',
            // legendIndex: 1
          }
        ]
      };
    },
    getPieOption() {
      return {
        backgroundColor: 'rgba(3, 23, 26, .5)',
        tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: 'Access From',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ]
          }
        ]
      }
    }
  },
}
</script>

<style lang="scss" scoped>
  .member-fee {
    flex: 1;
    min-height: 0;
  }

  .chart {
    width: 100%;
    height: 100%;
  }
</style>
