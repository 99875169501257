<template>
  <div class="company-category flex-center"
       @mouseover="pause"
       @mouseout="play"
  >
    <div v-if="!isEmpty" class="chart" :id="randomId"></div>
    <div v-if="isEmpty" class="flex-1 flex-center">
      <data-empty />
    </div>
  </div>
</template>
<script>
import * as echarts from 'echarts/core';
import {
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  DataZoomComponent
} from 'echarts/components';
import { PieChart } from 'echarts/charts';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  GridComponent,
  LegendComponent,
  PieChart,
  CanvasRenderer,
  UniversalTransition,
  DataZoomComponent
]);
import { randomString } from "@/base/utils/tool";
import { getHomeCompanyCategoryData } from "@/modules/big-data/api/templates";
import DataEmpty from "@/modules/big-data/components/DataEmpty";

let timer = null;
let anchor = 0;

export default {
  name: 'CompanyCategory',
  components: {DataEmpty},
  inject: ['getData', 'grid', 'disabled'],
  data() {
    return {
      randomId: randomString(16),
      responseData: null,
    }
  },
  computed: {
    config() {
      return this.getData().config || {chart_type: {
          key: 'line_chart',
          name: '折线图'
        }}
    },
    isEmpty() {
      return !this.responseData?.length;
    }
  },
  watch: {
    config: {
      handler(val) {
        if (this.lastType && val.chart_type.value.key !== this.lastType) {
          // console.log('member fee chart_type change', val.chart_type.value)
          this.myChart.clear()
          this.myChart.off('mouseover')
          this.myChart.off('mouseout')
          this.myChart = null
          this.initOptions()
        }
      },
      deep: true
    }
  },
  mounted() {
    // console.log('company category mounted')
  },
  beforeDestroy() {
    console.log('before destroy')
    this.stopAnimation()
  },
  methods: {
    pause() {
      // 手动切换状态
      this.handle = true
      // console.log('动画被鼠标事件打断')
      // TODO: 暂停动画，但不想将 anchor 进度清空
      if (timer) {
        clearInterval(timer)
      }
      this.myChart?.dispatchAction({
        type: 'hideTip'
      })

      // 取消之前高亮的图形
      if (anchor >= 1) {
        this.myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: anchor - 1
        });
      }
    },
    play() {
      this.handle = false
      // console.log('动画被鼠标事件打断后恢复')
      this.animate()
    },
    resize() {
      if (this.myChart) this.myChart.resize()
    },
    stopAnimation() {
      // 取消之前高亮的图形
      if (anchor >= 1) {
        this.myChart.dispatchAction({
          type: "downplay",
          seriesIndex: 0,
          dataIndex: anchor - 1
        });
      }
      this.myChart?.dispatchAction({
        type: 'hideTip'
      })
      if (timer) {
        clearInterval(timer)
        timer = null
        anchor = 0
      }
    },
    animate() {
      if (this.grid && !this.disabled) return
      let that = this
      const list = this.responseData || []
      if (list.length && this.grid) {
        if (timer) clearInterval(timer)
        timer = setInterval(() => {
          const max = list.length
          if (anchor === max && timer) {
            this.stopAnimation()
            setTimeout(() => {
              this.animate()
            }, 1500)
            return
          }
          // this.myChart.dispatchAction({
          //   type: 'toggleSelected',
          //   dataIndex: anchor
          // })
          // 自动高亮显示
          var chartHover = function() {
            // 取消之前高亮的图形
            if (anchor >= 1) {
              that.myChart.dispatchAction({
                type: "downplay",
                seriesIndex: 0,
                dataIndex: anchor - 1
              });
            }

            // 高亮当前图形
            that.myChart.dispatchAction({
              type: "highlight",
              seriesIndex: 0,
              dataIndex: anchor
            });
            // 显示 tooltip
            that.myChart.dispatchAction({
              type: "showTip",
              seriesIndex: 0,
              dataIndex: anchor
            });
          };
          const type = this.config.chart_type.value?.key || 'line_chart'
          if (type === 'pie_chart') {
            chartHover()
          } else {
            if (list.length - 1 - anchor >= 5) {
              this.myChart.dispatchAction({
                type: 'dataZoom',
                // 可选，dataZoom 组件的 index，多个 dataZoom 组件时有用，默认为 0
                dataZoomIndex: 0,
                // 开始位置的百分比，0 - 100
                // start: 0,
                // 结束位置的百分比，0 - 100
                // end: 100,
                // 开始位置的数值
                startValue: list.length - 1 - anchor,
                // 结束位置的数值
                endValue: list.length - 1 - 5 - (anchor)
              })
            }

            // 显示 tooltip
            that.myChart.dispatchAction({
              type: "showTip",
              seriesIndex: 0,
              dataIndex: list.length - 1 - anchor
            });
          }

          anchor++
        }, 1500)
      }
    },
    getShowData(query, callback = () => {}) {
      this.stopAnimation()
      if (this.responseData?.length) {
        this.$nextTick(() => {
          this.initOptions()
        })
        callback()
        return
      }
      getHomeCompanyCategoryData().then(res => {
        this.responseData = res.data.reverse()
        this.$nextTick(() => {
          this.initOptions()
        })
        callback()
      }).catch(() => {
        callback()
      })
    },
    initOptions() {
      // console.log('init chart', this.myChart)
      // if (this.myChart) {
      //   // this.myChart.dispose()
      //   this.myChart.resize()
      //   return
      // }
      const type = this.config.chart_type.value?.key || 'line_chart'
      this.lastType = type

      var chartDom = document.getElementById(this.randomId);
      this.myChart?.off('mouseover')
      this.myChart?.off('mouseout')
      var myChart = echarts.init(chartDom, 'dark');
      var option;

      if (type === 'bar_chart') {
        option = this.getBarOption()
      } else if (type === 'pie_chart') {
        option = this.getPieOption()
      }

      option && myChart.setOption(option);
      this.myChart = myChart

      let that = this
      myChart.on('mouseover', function() {
        that.pause()
      })
      myChart.on('mouseout', function() {
        that.play()
      })

      // TODO: 动画开关
      setTimeout(() => {
        this.animate()
      }, 3000)
    },
    getPieOption() {
      return {
        backgroundColor: 'rgba(3, 23, 26, .5)',
        tooltip: {
          trigger: 'item',
          position: function (point, params, dom, rect, size) {
            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            // 提示框位置
            var x = 0; // x坐标位置
            var y = 0; // y坐标位置

            // 当前鼠标位置
            var pointX = point[0];
            var pointY = point[1];

            // 外层div大小
            // var viewWidth = size.viewSize[0];
            // var viewHeight = size.viewSize[1];

            // 提示框大小
            var boxWidth = size.contentSize[0];
            var boxHeight = size.contentSize[1];

            // boxWidth > pointX 说明鼠标左边放不下提示框
            if (boxWidth > pointX) {
              x = 5;
            } else { // 左边放的下
              x = pointX - boxWidth;
            }

            // boxHeight > pointY 说明鼠标上边放不下提示框
            if (boxHeight > pointY) {
              y = 5;
            } else { // 上边放得下
              y = pointY - boxHeight;
            }

            return [x, y];
          },
          formatter: function (parms) {
            return parms.seriesName + "</br>" +
              "<div style='display: flex; flex-direction: column;min-width: 120px;'>" +
              "<div style='display: flex; justify-content: space-between;'>" +
              "<span>" + parms.marker + parms.data.name + "</span>" +
              "<span>" + parms.data.value + "家</span>" +
              "</div>" +
              "<div style='display: flex; justify-content: space-between;'>" +
              "<span>占比率</span>" +
              "<span>" + parms.percent + "%" + "</span>" +
              "</div>" +
              "</div>"
              ;
          }
        },
        legend: {
          // top: '5%',
          left: 'center',
          type: 'scroll',
          bottom: '5%',
          right: '5%'
        },
        series: [
          {
            name: this.getData().name,
            type: 'pie',
            radius: ['40%', '70%'],
            bottom: '10%',
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: false,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data:
              // { value: 1048, name: 'Search Engine' },
              // { value: 735, name: 'Direct' },
              // { value: 580, name: 'Email' },
              // { value: 484, name: 'Union Ads' },
              // { value: 300, name: 'Video Ads' }
              (() => {
                return this.responseData.map(r => {
                  return ({
                    value: r.contents_count,
                    name: r.name
                  })
                })
              })()
          }
        ]
      }
    },
    getBarOption() {
      const color = [
        '#5470c6', '#91cc75', '#fac858',
        '#ee6666', '#73c0de', '#3ba272',
        '#fc8452', '#9a60b4', '#ea7ccc'
      ]
      const seriesName = this.getData().name
      return {
        backgroundColor: 'rgba(3, 23, 26, .5)',
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          formatter: function (params) {
            const parms = params[0]
            return seriesName + "</br>" +
              "<div style='display: flex; flex-direction: column;min-width: 120px;'>" +
              "<div style='display: flex; justify-content: space-between;'>" +
              "<span>" + parms.marker + parms.name + "</span>" +
              "<span>" + parms.data.value + "家</span>" +
              "</div>" +
              "<div style='display: flex; justify-content: space-between;'>" +
              "<span>占比率</span>" +
              "<span>" + parms.data.percent + "%" + "</span>" +
              "</div>" +
              "</div>"
              ;
          }
        },
        dataZoom: [{
          type: 'slider',
          show: false, //false直接隐藏图形
          // xAxisIndex: [0],
          yAxisIndex: [0],
          // left: '9%', //滚动条靠左侧的百分比
          // bottom: -5,
          startValue: this.responseData.length - 1,
          endValue: this.responseData.length - 1 - 5,
          // start: 0,//滚动条的起始位置
          // end: 50 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
          // startValue: 0,
          // endValue: 5
        }],
        xAxis: {
          type: 'value'
        },
        yAxis: {
          type: 'category',
          axisLabel: {
            // showMaxLabel: true,
            width: 100,
            // overflow: 'break',
            overflow: 'truncate',
            // color: 'red',
            // inside: true,
            padding: [0, 0, 0, 20]
          },
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          // data: ['1', '2']
          data: (() => {
            return this.responseData.map((r, rI) => {
              return r.name
            })
          })(),
        },
        series: [
          {
            // data: [
            //   120,
            //   {
            //     value: 200,
            //     itemStyle: {
            //       color: '#a90000'
            //     }
            //   },
            //   150,
            //   80,
            //   70,
            //   110,
            //   130
            // ],
            data: (() => {
              return this.responseData.map((r, rI) => {
                return ({
                  value: r.contents_count,
                  itemStyle: {
                    color: color[rI % color.length]
                  },
                  percent: r.percent
                })
                // return r.contents_count
              })
            })(),
            type: 'bar'
          }
        ]
      };
    },
  },
}
</script>
<style lang="scss" scoped>
  .company-category {
    flex: 1;
    min-height: 0;
  }

  .chart {
    width: 100%;
    height: 100%;
  }
</style>
