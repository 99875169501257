<template>
  <div v-if="responseData" class="template-card-content article-post flex-col"
       @mouseover="pause"
       @mouseout="play"
  >
    <template-card-summary v-if="!isEmpty"
                           :diff_count="responseData.diff_count"
                           :status="responseData.status"
                           :compare_type="compareType"
                           @sort-by-time="toggleDesc">
      <span class="total">共发布 <span class="emphasis">{{responseData.total}}</span> 条</span>
    </template-card-summary>
    <component :is="grid ? 'el-scrollbar' : 'div'" v-if="!isEmpty" class="dynamic flex-1">
      <div class="list-wrapper" :class="{
        two: getData().w >= 2,
        grid,
        'flex-col': !grid
      }">
        <div v-for="item in (grid ? responseData.list.data : responseData.list.data.slice(0, 2))" class="grid-item flex" :id="item.id">
          <div class="flex-col flex-1" style="justify-content: space-between;">
            <div class="line-one flex-col">
              <span class="label">{{item.title}}</span>
            </div>
            <div class="line-two flex-between">
              <span class="source">{{item.source_name}}</span>
              <span class="time">{{item.post_time}}</span>
            </div>
          </div>
          <div v-if="item.images && item.images.length" class="icon-box">
            <img class="icon" :src="fixImageUrl(item.images[0].url, 200)" draggable="false" />
          </div>
        </div>
      </div>
    </component>
    <div v-if="isEmpty" class="flex-1 flex-center">
      <data-empty />
    </div>
    <div v-if="!isEmpty" class="blur" />
  </div>
</template>

<script>
import { getHomeArticleData } from "@/modules/big-data/api/templates";
import { randomString } from "@/base/utils/tool";
import DataEmpty from "@/modules/big-data/components/DataEmpty";
import TemplateCardSummary from "@/modules/big-data/components/TemplateCardSummary";
import Compare from '@/base/utils/object-compare.js'
import template from "@/modules/big-data/mixins/list-template";

export default {
  name: 'article-post',
  mixins: [template],
  components: {TemplateCardSummary, DataEmpty},
  inject: ['getData', 'grid', 'disabled'],
  props: {

  },
  data() {
    return {
      compareType: 0,
    }
  },
  methods: {
    getShowData(query, callback) {
      this.stopAnimation()
      const queryObj = {
        compare_type: 0, // 周、月、年
        start_time: -1, // 开始时间
        end_time: -1,
        ...(this.queryObj || {}),
        is_desc: this.desc??1,	// 是否倒序
        page: 1, // 页码
        ...query,
      }
      console.log('请求参数比较【左、右：旧、新】：', Compare(this.queryObj, queryObj))
      getHomeArticleData(queryObj).then(res => {
        res.data.list.data = res.data.list.data.map(el => ({
          ...el,
          id: randomString(5)
        }))
        this.responseData = res.data
        this.queryObj = queryObj
        this.compareType = queryObj.compare_type

        if (res.data.list.data.length) {
          // TODO: 动画开关
          this.animate()
          callback()
        } else {
          // const next = (queryObj.compare_type + 1) % 3
          //
          // this.$emit('update', {
          //   compare_type: next
          // })
          callback()
        }
      }).catch(err => {
        console.log(err)
        callback()
      })
    },
    animate() {
      if (this.grid && !this.disabled) return
      const list = this.listData
      if (list.length && this.grid) {
        this.buildTimer()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/modules/big-data/scss/template.scss";

  .list-wrapper {
    width: 100%;
    height: 100%;
    padding: 0 .16em;

    &.two {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }

    .grid-item {
      padding: .12em;
      border-left: .02em solid #3CA8BE;
      background: linear-gradient(90deg, rgba(75,210,237,.15) 0%, rgba(75,210,237,0) 100%);
      margin-top: .08em;

      &:first-child {
        margin-top: 0;
      }
    }
  }

  .icon-box {
    /*width: 1.39em;*/
    /*width: 32.5% !important;*/
    width: 1.39em !important;
    /*height: fit-content;*/
    margin-left: .12em;
    @include fixed-ratio-box(56%);
    display: flex;
    transform: scale(1);
    border-radius: .04em;
    overflow: hidden;

    &:before {
      width: 100%;
    }
  }

  .icon {
    /*width: .9em;*/
    /*height: .9em;*/
    /*flex-shrink: 0;*/
    object-fit: cover;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .label {
    font-size: .16em;
    line-height: 1.357;
    /* 相对自身的 font-size */
    /*margin-right: 0.857em;*/
    @include clamp(2);
    color: rgba(255,255,255,1);
    font-weight: bold;
  }

  .value {
    font-size: .18em;
    line-height: 1.278;
    font-weight: bolder;
    white-space: nowrap;
  }

  .line-two {
    margin-top: .16em;

    .label {
      font-weight: bold;
    }

    .source {
      opacity: 0.4000000059604645;
      color: rgba(255,255,255,1);
      font-size: .14em;
      font-weight: bold;
    }

    .time {
      opacity: 0.800000011920929;
      color: rgba(255,255,255,1);
      font-size: .14em;
      font-weight: bolder;
    }
  }

</style>
