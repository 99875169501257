<template>
  <div class="single-member">
    <span class="status">
      <div class="num" v-if="itemI > 3">{{ itemI }}</div>
      <template v-else>
        <img
          v-if="itemI === 1"
          class="rank-icon"
          src="../../../assets/member-interact/rank_1.png"
        />
        <img
          v-if="itemI === 2"
          class="rank-icon"
          src="../../../assets/member-interact/rank_2.png"
        />
        <img
          v-if="itemI === 3"
          class="rank-icon"
          src="../../../assets/member-interact/rank_3.png"
        />
      </template>
      <img
        class="avatar"
        :src="fixImageUrl(item.user.avatar || defaultAvatar, 150)"
      />

      <div class="name">{{ item.user.nickname }}</div>
      <div class="title-name">{{ item.user.title_name }}</div>
    </span>
    <div class="label-box" :id="`${item.id}_news`">
      <div class="score">{{ item.data_point }}</div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  name: "SingleMember",
  props: {
    item: Object,
    itemI: {
      type: Number,
      default: () => 1,
    },
    handle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      defaultAvatar:
        window.serverConfig.VUE_APP_ADMINURL +
        "/cyc/images/wxapps/icons/avatar1.png",
    };
  },
  watch: {
    handle(newValue, oldValue) {
      // this.handleMoveDebounce(newValue, oldValue)
      this.handleMove(newValue, oldValue);
    },
  },
  created() {
    // this.handleMoveDebounce = debounce(this.handleMove, 1500)
  },
  mounted() {
    // if (this.item.id === 'VXelknj9') {
    //   this.NewsLeft(); // 与 scrollIntoView 冲突
    // setTimeout(() => {
    this.NewsLeft();
    // console.log(this.$props.itemI)
    // }, 3500)
    // }
  },
  methods: {
    handleMove(newValue, oldValue) {
      console.log("watch handle newValue", newValue, oldValue);
      if (newValue === true) {
        // this.reset()
        // setTimeout(() => {
        //   this.NewsLeft()
        // }, 1000)
      } else {
        // if (this.timer) clearInterval(this.timer)
      }
    },
    NewsLeft() {
      var speed = 12;
      var MyMar = null;
      var news = document.getElementById(this.item.id + "_news");
      // var new1 = document.getElementById(this.item.id + '_' + this.item.name)

      let that = this;
      function marquee() {
        // if (news.scrollLeft + news.offsetWidth >= (new1.offsetWidth * 2)) {
        if (news.scrollLeft >= news.offsetWidth * 3 || !that.handle) {
          news.scrollLeft = 0;
        } else {
          news.scrollLeft++;
        }
      }

      // MyMar = setInterval(marquee, speed)
      news.onmouseover = function (e) {
        console.log("鼠标停留在会员组件", that.handle);
        // e.preventDefault();
        that.reset();
        // news.onmouseleave = null
        // news.onmouseout = null
        if (MyMar) clearInterval(MyMar);
        MyMar = setInterval(marquee, speed);
        that.timer = MyMar;
        that.$emit("stop");
      };
      news.onmouseout = function (e) {
        // e.preventDefault();
        console.log("鼠标离开会员组件");
        that.reset();
        // news.onmouseover = null
        // news.onmouseleave = null
        news.scrollLeft = 0;
        that.$emit("start");
      };
      // news.onmouseleave = function(e) {
      //   console.log('鼠标离开会员组件')
      //   // e.preventDefault();
      //   that.reset()
      //   // news.onmouseover = null
      //   // news.onmouseout = null
      // }

      this.timer = MyMar;
    },
    reset() {
      if (this.timer) clearInterval(this.timer);
      this.timer = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-1 {
  flex: 1;
  min-width: 0;
  width: 100%;
}

.single-member {
  width: 100%;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .status {
    flex: 1;
    display: flex;
    align-items: center;
    .num {
      color: rgba(255, 255, 255, 0.8);
      flex-shrink: 0;
      margin-right: 0.75em;
      font-size: 0.16em;
      font-weight: 900;
      width: 2em;
      text-align: center;
    }
    .rank-icon {
      width: 0.32em;
      height: 0.32em;
      margin-right: 0.12em;
    }
    .avatar {
      width: 0.42em;
      height: 0.42em;
      flex-shrink: 0;
      border-radius: 50%;
      margin-left: 0.12em;
      margin-right: 0.12em;
      object-fit: cover;
    }
    .name {
      color: #fff;
      font-weight: 500;
      margin-right: 0.5em;
      font-size: 0.16em;
      @include clamp(1)
    }
    .title-name {
      font-size: 0.12em;
      color: rgba(255, 255, 255, 0.8);
      @include clamp(1)

    }
  }

  .label-box {
    // width: 2.2em;
    font-size: 0.24em;
    font-weight: 700;
    line-height: 1;
    color:#EDB74B;
  }
}
</style>
