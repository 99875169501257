/**
 * 对象比较工具，相等返回true
 * @param objA
 * @param objB
 * @param a
 * @param b
 * @returns {boolean}
 * @constructor
 */
function Compare(objA, objB, a, b) {
  if (!isObj(objA) || !isObj(objB)) {
    console.log('not object', a, b, objA, objB)
    // console.log('typeof a', typeof objA)
    // console.log('typeof b', typeof objB)
    // console.log('a is null', objA === null)
    // console.log('b is null', objB === null)
    // console.log('not object', objA, objB)
    return false;
  } // 判断类型是否正确
  if (getLength(objA) !== getLength(objB)) {
    console.log('----------------------------')
    console.log('object keys length not match')
    console.log('----------------------------')
    console.log('objA keys', Object.keys(objA))
    console.log('----------------------------')
    console.log('objB keys', Object.keys(objB))
    console.log('----------------------------')
    console.log('objA object', objA)
    console.log('----------------------------')
    console.log('objB object', objB)
    console.log('----------------------------')
    return false;
  } // 判断长度是否一致
  return CompareObj(objA, objB, true);// 默认为true
}
function CompareObj(objA, objB, flag) {
  for (let key in objA) {
    // console.log('key', key, objA, objB)
    if (!flag) // 跳出整个循环
      break;
    if (!objB.hasOwnProperty(key)) {
      console.log('-----------------')
      console.log('objB lack of', key)
      console.log('-----------------')
      console.log('objA', Object.keys(objA), objA)
      console.log('-----------------')
      console.log('objB', Object.keys(objB), objB)
      console.log('-----------------')
      flag = false; break;
    }
    if (isArray(objA[key])) {
      if (!isArray(objB[key])) {
        flag = false;
        console.log('not array!')
        console.log('key', key)
        console.log('objB', objB)
        console.log('objB[key]', objB[key])
        break;
      }
      let oA = objA[key], oB = objB[key];
      if (oA.length !== oB.length) {
        flag = false;
        console.log(key, 'length not match.', oA, oB)
        console.log(objA, 'objA')
        console.log(objB, 'objB')
        break;
      }
      for (let k in oA) {
        if (!flag) // 这里跳出循环是为了不让递归继续
          break;
        /* 数组里可以是对象和其他，Compare方法要求必须是对象 */
        flag = CompareObj(oA[k], oB[k], flag);
      }
    } else if (isObj(objA[key])) {
      // console.log(key, JSON.stringify(objA[key]), JSON.stringify(objB[key]))
      // console.log(objB[key])
      flag = Compare(objA[key], objB[key], objA, objB)
    } else {
      /* 忽略某些字段 */
      if (key === 'isActive' && objA.component !== undefined && ['st-text', 'st-button', 'st-image'].includes(objA.component)) {
        /* 忽略 st-text 组件的 isActive 字段 */
      } else {
        // 打印不相等的键值对
        if (objA[key] !== objB[key]) {
          // console.log('value changed. ')
          // console.log(JSON.stringify(objA), JSON.stringify(objB))

          /**
           * 数据太长，没法直观查看，故不作打印
           */
          if (objA[key].length < 500 || typeof objA[key] !== 'string') {
            console.log('--------------from object compare--------------')
            console.log(key + ' changed. ', objA[key], ' -> ', objB[key])
            console.log('-----------------------------------------------')
            console.log('objA', objA)
            console.log('-----------------------------------------------')
            console.log('objB', objB)
            console.log('-----------------------------------------------')
          } else if (objA[key].length) {
            console.log('--------------from object compare--------------')
            console.log(key + ' changed. length from ', objA[key].length, ' -> ', objB[key].length)
            console.log('-----------------------------------------------')
            // console.log(objA[key].substring(objA[key].length - 250))
            // console.log(objB[key].substring(objB[key].length - 250))
            // console.log('-----------------------------------------------')
          } else {
            console.log(objA[key], objB[key], objA, objB)
          }
        }

        // 打印所有键值对
        // console.log(objB[key] === objA[key], key, objA[key], objB[key])

        if (objB[key] !== objA[key]) {
          if (key === 'bgColor') {
            if (objB[key].toLowerCase() !== objA[key].toLowerCase()) {
              flag = false;
              break;
            }
          } else {
            flag = false;
            break;
          }
        }
      }
    }
  }
  return flag;
}

function isObj(object) {
  // console.log('is null', object === null)
  return object && typeof (object) == 'object' && Object.prototype.toString.call(object).toLowerCase() === "[object object]";
}
function isArray(object) {
  return object && typeof (object) == 'object' && object.constructor === Array;
}
function getLength(object) {
  let count = 0;
  for (let i in object) count++;
  return count;
}

module.exports = Compare
