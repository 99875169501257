<template>
  <div
    class="user-increase flex-center"
    @mouseout="play"
    @mouseenter="pause"
    @mouseover="pause"
  >
    <div class="chart-box" v-if="!isEmpty">
      <!-- 会员统计 -->
      <div class="chart" :id="randomId" />
    </div>
    <div v-if="isEmpty" class="flex-1 flex-center">
      <data-empty />
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { GridComponent, LegendComponent } from "echarts/components";
import { LineChart } from "echarts/charts";
import { UniversalTransition } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";
import DataEmpty from "../../DataEmpty";
import { getOrganizationUsersData } from "../../../api/templates/member-stats";
import {
  getRecentDay_Date,
  getRecentMonth_Date,
} from "../../../tool/time-range";
import template from "@/modules/big-data/mixins/list-template";

echarts.use([
  GridComponent,
  LineChart,
  CanvasRenderer,
  LegendComponent,
  UniversalTransition,
]);

export default {
  name: "OrganizationUsers",
  components: { DataEmpty },
  mixins: [template],
  inject: ["getData", "grid", "disabled"],
  data() {
    return {
    };
  },
  computed: {
    config() {
      return (
        this.getData().config || {
          chart_type: {
            value: {
              key: "line_chart",
              name: "折线图",
            },
          },
        }
      );
    },
    // 不可能为空
    isEmpty() {
      let flag = !this.responseData?.length
      // console.log(flag)
      return flag;
    },
  },
  watch: {
    config: {
      handler(val) {
        // console.log(val)
        if (this.lastType && val.chart_type.value.key !== this.lastType && !this.isEmpty) {
          // console.log('member fee chart_type change', val.chart_type.value)
          this.myChart.clear();
          this.myChart = null;
          this.initOptions();
        }
      },
      deep: true,
    },
  },
  created() {
    // console.log(
    //   getRecentDay_Date(7),
    //   getRecentDay_Date(-1),
    //   getRecentMonth_Date(-1)
    // );
  },
  beforeDestroy() {
    this.stopAnimation();
  },
  methods: {
    getShowData(query, callback = () => {}) {
      this.stopAnimation();

      getOrganizationUsersData({
        ...query,
      })
        .then((res) => {
          this.responseData = res.data;
          if(!this.isEmpty) {
            this.$nextTick(() => {
            this.initOptions();
            // console.log(this.responseData, "会员统计");
          });
          }
        

          callback();
        })
        .catch((err) => {
          console.log(err);
          callback();
        });
    },
    initOptions() {
      const type = this.config.chart_type.value?.key || "line_chart";
      this.lastType = type;

      var chartDom = document.getElementById(this.randomId);
      // console.log(chartDom)
      if (this.myChart) {
        this.myChart.off("mouseover");
        this.myChart.off("mouseoout");
        this.myChart.off("mouseenter");
      }
      var myChart = echarts.init(chartDom, "dark");
      var option;

      if (type === "bar_chart") {
        option = this.getBarOption();
      } else if (type === "line_chart") {
        option = this.getLineOption();
      } else if (type === "pie_chart") {
        option = this.getPieOption();
      }
      // console.log(option, myChart);
      option && myChart.setOption(option);
      this.myChart = myChart;
      let that = this;
      myChart.on("mouseover", function () {
        console.log("echarts mouseover");
        that.pause();
      });
      myChart.on("mouseenter", function () {
        console.log("echarts mouseenter");
        that.pause();
      });
      // myChart.on('mouseout', function() {
      //   console.log('echarts mouseout')
      //   that.play()
      // })

      // TODO: 动画开关
      setTimeout(() => {
        if (!this.handle) this.animate();
      }, 3000);
    },
    animate() {
      if (this.grid && !this.disabled) return;
      if (this.grid) {
        this.buildTimer({
          each: (anchor) => {
            this.myChart.dispatchAction({
              type: "showTip",
              // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
              seriesIndex: 0,
              // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
              dataIndex: anchor,
              gridIndex: 0,
            });
          },
          getEndStatus: (anchor) => {
            let flag = anchor === this.responseData?.length;
            // console.log(flag);
            return flag;
          },
        });
      }
    },
    getLineOption() {
      return {
        backgroundColor: "rgba(3, 23, 26, .5)",
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
        },
        // dataZoom: [{
        //   type: 'slider',
        //   show: true, //flase直接隐藏图形
        //   xAxisIndex: [0],
        //   left: '9%', //滚动条靠左侧的百分比
        //   bottom: 5,
        //   start: 0,//滚动条的起始位置
        //   end: 50 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
        // }],
        grid: {
          left: "3%",
          right: "4%",
          bottom: "3%",
          containLabel: true,
        },
        toolbox: {
          show: false,
          feature: {
            saveAsImage: {},
          },
        },
        xAxis: {
          type: "category",
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: this.responseData.map((el) => el.name),
          axisLabel: {
            interval: 0, //代表显示所有x轴标签显示
          },
        },
        yAxis: {
          type: "value",
        },
        legend: {
          data: ["架构人数"],
        },
        series: (() => {
          // const color = [
          //   "#5470c6",
          //   "#91cc75",
          //   "#fac858",
          //   "#ee6666",
          //   "#73c0de",
          //   "#3ba272",
          //   "#fc8452",
          //   "#9a60b4",
          //   "#ea7ccc",
          // ];
          // return this.responseData.list.map((r, rI) => {
          //   const dataList = r.data.map((el) => el.count);
          //   return {
          //     name: this.config.user_type[rI].name,
          //     type: "line",
          //     // smooth: true,
          //     data: dataList,
          //     areaStyle: {
          //       opacity: 0.8,
          //       color: new echarts.graphic.LinearGradient(0, 0, 0, 2, [
          //         {
          //           offset: 0,
          //           color: color[rI],
          //         },
          //         {
          //           offset: 1,
          //           // color: 'rgba(255, 255, 255, 0)'
          //           color: "transparent",
          //         },
          //       ]),
          //     },
          //   };
          // });
          return [
            {
              name: "架构人数",
              type: "line",
              // smooth: true,
              data: this.responseData.map((el) => el.members_count),
              areaStyle: {
                opacity: 0.8,
                color: new echarts.graphic.LinearGradient(0, 0, 0, 2, [
                  {
                    offset: 0,
                    color: "#4bd2ed",
                  },
                  {
                    offset: 1,
                    // color: 'rgba(255, 255, 255, 0)'
                    color: "transparent",
                  },
                ]),
              },
            },
          ];
        })(),
      };
    },
    getBarOption() {
      // const color = [
      //   "#4bd2ed",
      //   "#4bed9c",
      //   "#fac858",
      //   "#ee6666",
      //   "#73c0de",
      //   "#3ba272",
      //   "#fc8452",
      //   "#9a60b4",
      //   "#ea7ccc",
      // ];
      return {
        backgroundColor: "rgba(3, 23, 26, .5)",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
          },
        },
        grid: {
          bottom: "15%",
          left: 50,
          right: 30,
        },
        // color: [
        //   '#5470c6', '#91cc75', '#fac858',
        //   '#ee6666', '#73c0de', '#3ba272',
        //   '#fc8452', '#9a60b4', '#ea7ccc'
        // ],
        // dataZoom: [{
        //   type: 'slider',
        //   show: true, //false直接隐藏图形
        //   xAxisIndex: [0],
        //   left: '9%', //滚动条靠左侧的百分比
        //   bottom: -5,
        //   start: 0,//滚动条的起始位置
        //   end: 50 //滚动条的截止位置（按比例分割你的柱状图x轴长度）
        // }],
        xAxis: {
          type: "category",
          // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          data: (() => {
            return this.responseData.map((r) => {
              return r.name;
            });
          })(),
          axisLabel: {
            interval: 0, //代表显示所有x轴标签显示
          },
        },
        yAxis: {
          type: "value",
        },
        legend: {
          // data: (() => {
          //   return this.responseData.map((r) => {
          //     return r.name;
          //   });
          // })(),
        },
        series: (() => {
          //
          let memberArr = () => {
            let dataList = this.responseData.map((item, index) => {
              return {
                value: item.members_count,
                itemStyle: {
                  color: "#4bd2ed",
                },
              };
            });
            return {
              type: "bar",
              data: dataList,
              // name: this.config.user_type.find((el) => el.key === r.key).name,
              // name: "已登录",
            };
          };

          return [memberArr()];
        })(),
      };
    },
    getPieOption() {
      return {
        backgroundColor: "rgba(3, 23, 26, .5)",
        tooltip: {
          trigger: "item",
          position: function (point, params, dom, rect, size) {
            // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
            // 提示框位置
            var x = 0; // x坐标位置
            var y = 0; // y坐标位置

            // 当前鼠标位置
            var pointX = point[0];
            var pointY = point[1];

            // 外层div大小
            // var viewWidth = size.viewSize[0];
            // var viewHeight = size.viewSize[1];

            // 提示框大小
            var boxWidth = size.contentSize[0];
            var boxHeight = size.contentSize[1];

            // boxWidth > pointX 说明鼠标左边放不下提示框
            if (boxWidth > pointX) {
              x = 5;
            } else {
              // 左边放的下
              x = pointX - boxWidth;
            }

            // boxHeight > pointY 说明鼠标上边放不下提示框
            if (boxHeight > pointY) {
              y = 5;
            } else {
              // 上边放得下
              y = pointY - boxHeight;
            }

            return [x, y];
          },
          formatter: function (parms) {
            return (
              parms.seriesName +
              "</br>" +
              "<div style='display: flex; flex-direction: column;min-width: 120px;'>" +
              "<div style='display: flex; justify-content: space-between;'>" +
              "<span>" +
              parms.marker +
              parms.data.name +
              "</span>" +
              "<span>" +
              parms.data.value +
              "人</span>" +
              "</div>" +
              "<div style='display: flex; justify-content: space-between;'>" +
              "<span>占比率</span>" +
              "<span>" +
              parms.percent +
              "%" +
              "</span>" +
              "</div>" +
              "</div>"
            );
          },
        },
        legend: {
          // top: '5%',
          left: "center",
          type: "scroll",
          bottom: "5%",
          right: "5%",
        },
        series: [
          {
            name: this.getData().name,
            type: "pie",
            radius: ["40%", "70%"],
            bottom: "10%",
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: "center",
            },
            emphasis: {
              label: {
                show: false,
                fontSize: "20",
                fontWeight: "bold",
              },
            },
            labelLine: {
              show: false,
            },
            data:
              // { value: 1048, name: 'Search Engine' },
              // { value: 735, name: 'Direct' },
              // { value: 580, name: 'Email' },
              // { value: 484, name: 'Union Ads' },
              // { value: 300, name: 'Video Ads' }
              (() => {
                return this.responseData.map((r) => {
                  return {
                    value: r.members_count,
                    name: r.name,
                  };
                });
              })(),
          },
        ],
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.user-increase {
  flex: 1;
  min-height: 0;
}
.chart-box {
  width: 100%;
  height: 100%;
  display: flex;
  padding: 0.16em;
  .chart {
    flex: 1;
  }
}
</style>
