import store from "@/base/store"; //vuex

export default {
  index: "member_photo",
  form: {
    data_type: "personal_user",
    data_show: {
      personal_user: {
        label: "",
        options: [""],
        value: "",
      },
      unit_user: {
        label: "",
        options: [""],
        value: "",
      },
    },
    chart_type: {
      value: {
        key: "pie_chart",
        name: "饼图",
      },
      options: [
        {
          key: "pie_chart",
          name: "饼图",
        },
      ],
    },
  },
  getRules(value) {
    return [
      {
        type: "select",
        field: "data_type",
        title: "会员类型",
        props: {
          style: "width: 100%;",
        },
        children: (() => {
          const options = [
            {
              label: "个人会员",
              value: "personal_user",
            },
            {
              label: "单位会员",
              value: "unit_user",
            },
          ];
          return options.map((op) => {
            return {
              type: "ElOption",
              props: {
                label: op.label,
                value: op.value,
              },
            };
          });
        })(),
        update: (v) => {
          const fApi = value();
          const dataShow = fApi.rule[1];
          dataShow.props.rule[0].display = v === "personal_user";
          dataShow.props.rule[1].display = v === "unit_user";
        },
      },
      {
        type: "object",
        field: "data_show",
        value: { personal_user: {}, unit_user: {} },
        display: true,
        props: {
          rule: [
            // {
            //   type: "div",
            //   props: {
            //     class: 'group-title'
            //   },
            //   children: [
            //     '个人会员数据设置'
            //   ]
            // },
            {
              type: "select",
              field: "personal_user",
              title: "数据设置" || "数据展示",
              display: true,
              props: {
                style: "width: 100%;",
                valueKey: "value",
                clearable: true,
              },
              children: (() => {
                const data_show = store.state.bigDataDesign.data_show;
                const options =
                  data_show.member_photo_personal_user?.list || [];
                return options.map((op) => {
                  return {
                    type: "ElOption",
                    props: {
                      label: op.label,
                      value: op,
                      key: op.value,
                    },
                  };
                });
              })(),
            },
            // {
            //   type: "div",
            //   props: {
            //     class: 'group-title'
            //   },
            //   children: [
            //     '单位会员数据设置'
            //   ]
            // },
            {
              type: "select",
              field: "unit_user",
              title: "数据设置" || "数据展示",
              display: false,
              props: {
                style: "width: 100%;",
                valueKey: "value",
                clearable: true,
              },
              children: (() => {
                const data_show = store.state.bigDataDesign.data_show;
                const options = data_show.member_photo_unit_user?.list || [];
                return options.map((op) => {
                  return {
                    type: "ElOption",
                    props: {
                      label: op.label,
                      value: op,
                      key: op.value,
                    },
                  };
                });
              })(),
            },
          ],
        },
      },
      // {
      //   type: "div",
      //   props: {
      //     class: 'group-title'
      //   },
      //   children: [
      //     '图表设置'
      //   ]
      // },
      {
        type: "chartPicker",
        field: "chart_type",
        title: "图表设置" || "选择图表",
        props: {},
      },
    ];
  },
};
