<template>
  <div class="template-card-content member-map flex-col flex-center">
    <!-- {{config}} -->
    <div
      class="chart-box flex-center"
      :style="{
        // top: `${getData().w >= 2 ? 0 : 10}em`,
        position: false && getData().w >= 2 ? 'absolute' : 'static',
      }"
    >
      <!--
       @mouseover="pause"
 @mouseout="play"
      -->
      <div class="chart" :id="randomId" @click="backDirectly"></div>
    </div>
    <div class="context">
      <!--      {{displayText}}{{displayText2}}-->
      <!--      <hr />-->
      <!--      {{level}}-->
      <div
        v-if="level > 1 && !loading && !(grid && !disabled)"
        class="btn back flex-center cursor-pointer"
        @click.stop="backDirectly()"
      >
        <span class="btn-name">返回</span>
      </div>
    </div>
    <div v-if="responseData" class="corner">
      <table class="corner-head" border-spacing="0" border="0">
        <tr>
          <td>地区</td>
          <td>人数</td>
          <td>占比</td>
        </tr>
      </table>
      <div class="corner-body">
        <el-scrollbar>
          <table border-spacing="0" border="0">
            <tr
              v-for="(item, index) in responseData[0].map_list"
              :key="item[item.length - 1]"
              @click="clickInstance(index)"
            >
              <td>
                <p class="no-wrap">{{ getAliasName(item[item.length - 1]) }}</p>
              </td>
              <td>{{ responseData[0].value_list[index] }}人</td>
              <td>{{ responseData[0].percent_list[index] }}%</td>
            </tr>
          </table>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts/core'
import {
  GeoComponent,
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
} from 'echarts/components'
import {
  CustomChart,
  EffectScatterChart,
  MapChart,
  ScatterChart,
} from 'echarts/charts'
import { CanvasRenderer } from 'echarts/renderers'
import { randomString } from '@/base/utils/tool'

// import address from '@/modules/big-data/data/address.json'
// import geoJson from '../../../data/china.json'
import { getHomeMemberMapData } from '@/modules/big-data/api/templates'

import localConfig from './member-map'
import { debounce } from 'lodash'
import axios from 'axios'

const baseUrl = window.serverConfig.VUE_APP_ADMINURL
const cachedFeatures = {}

let address = null
let geoJson = null

echarts.use([
  TitleComponent,
  ToolboxComponent,
  TooltipComponent,
  VisualMapComponent,
  GeoComponent,
  MapChart,
  CanvasRenderer,
  ScatterChart,
  EffectScatterChart,
  CustomChart,
])

let timer = null
let anchor = 0

export default {
  name: 'MemberMap',
  inject: ['getData', 'grid', 'disabled'],
  data() {
    return {
      randomId: randomString(16),

      // TODO: 方便测试，晚点删
      level: 1,
      loading: false,

      responseData: null,
    }
  },
  computed: {
    config() {
      return this.$mergeModel(localConfig.form, this.getData().config)
    },
  },
  watch: {
    config: {
      handler(val, oldVal) {
        if (
          JSON.stringify(oldVal.map_config) !== JSON.stringify(val.map_config)
        ) {
          this.getShowData()
        } else if (
          JSON.stringify(oldVal.data_show) !== JSON.stringify(val.data_show)
        ) {
          this.getShowData()
        } else if (oldVal.data_type !== val.data_type) {
          this.getShowData()
        }
      },
      deep: true,
    },
  },
  created() {
    this.initGlobalJson()
  },
  methods: {
    async initGlobalJson() {
      if (!geoJson)
        geoJson = await new Promise((resolve) => {
          axios
            .get(baseUrl + '/big-data/data/china.json?key=' + this.$store.state.tenant.id)
            .then(({ data }) => {
              resolve(data)
            })
            .catch((err) => {
              resolve(err)
            })
        })

      if (!address)
        address = await new Promise((resolve) => {
          axios
            .get(baseUrl + '/big-data/data/address.json?key=' + this.$store.state.tenant.id)
            .then(({ data }) => {
              resolve(data)
            })
            .catch((err) => {
              resolve(err)
            })
        })

      return Promise.resolve()
    },
    resize: debounce(function () {
      // if (this.myChart) {
      // setTimeout(() => {
      // this.myChart.resize()
      // }, 500)
      // }
    }),
    pause() {
      // 手动切换状态
      this.handle = true
      console.log('动画被鼠标事件打断')
      // TODO: 暂停动画，但不想将 anchor 进度清空
      if (timer) {
        clearInterval(timer)
        timer = null
      }
      this.myChart?.dispatchAction({
        type: 'hideTip',
      })
    },
    play() {
      this.handle = false
      // console.log('动画被鼠标事件打断后恢复')
      // this.getShowData()
      this.initOptions(false, this.map_config || [])
    },
    getShowData(query = {}, callback = () => {}) {
      this.stopAnimation()
      const allKeys = ['personal_user', 'unit_user'].filter(
        (el) => el === this.config.data_type
      )
      const keys = []
      allKeys.forEach((k) => {
        const data_show = this.config.data_show[k]
        if (data_show?.value) keys.push(k)
        else {
          // this[`data_show_${k}`] = ''
        }
      })
      this.isConfigEmpty = keys.length === 0
      if (this.isConfigEmpty) {
        callback()
        console.log('缺少数据展示配置')
        this.initOptions()
        return
      }

      if (this.myChart)
        this.myChart.showLoading('default', {
          text: 'loading',
          // color: '#c23531',
          color: 'rgba(75, 210, 237, 1)',
          // textColor: '#000',
          textColor: '#fff',
          // maskColor: 'rgba(255, 255, 255, 0.8)',
          maskColor: 'rgba(3, 23, 26, .5)',
          zlevel: 0,

          // 字体大小。从 `v4.8.0` 开始支持。
          fontSize: 12,
          // 是否显示旋转动画（spinner）。从 `v4.8.0` 开始支持。
          showSpinner: true,
          // 旋转动画（spinner）的半径。从 `v4.8.0` 开始支持。
          spinnerRadius: 10,
          // 旋转动画（spinner）的线宽。从 `v4.8.0` 开始支持。
          lineWidth: 5,
          // 字体粗细。从 `v5.0.1` 开始支持。
          fontWeight: 'normal',
          // 字体风格。从 `v5.0.1` 开始支持。
          fontStyle: 'normal',
          // 字体系列。从 `v5.0.1` 开始支持。
          fontFamily: 'sans-serif',
        })
      this.loading = true

      const params = {
        map_config: this.config.map_config,
        ...query,
      }
      params.map_config = this.getFinalMapConfig(params.map_config)

      const tasks = keys.map((t) => {
        return new Promise((resolve) => {
          getHomeMemberMapData({
            form_key: this.config.data_show[t]?.value,
            form_type: t,
            ...params,
          })
            .then((res) => {
              resolve({
                ...res.data,
                type: t,
                name: t === 'unit_user' ? '单位会员' : '个人会员',
              })
              this[`data_show_${t}`] = this.config.data_show[t]?.value
            })
            .catch((err) => {
              console.log(err)
              resolve([])
            })
        })
      })
      Promise.all(tasks)
        .then((res) => {
          this.responseData = res
          // const isRenderMap = this.handle ||
          //   res.length &&
          //   res.find(el => el.value_list.length !== 0 && el.value_list.find(v => !!v))
          const isRenderMap = this.handle || res.length
          if (isRenderMap) {
            this.$nextTick(() => {
              // 记录地图展示数据的“层级”，如果点击某一块展示下一级行政区划地图，则数值加1
              if (!query.map_config) {
                /* 初始化一些辅助的字段 */
                this.level = 1 // 最上层
                this.mapContext = [] // 记录点击/播放地图的轨迹
              }
              this.initOptions(false, params.map_config)
            })
          } else {
            // this.back()
          }
          callback()
        })
        .catch((err) => {
          console.log(err)
          callback()
        })
    },
    async initOptions(update = false, map_config = null) {
      // console.log('init map', map_config)

      if (!geoJson) await this.initGlobalJson()

      if (this.myChart) {
        this.myChart.off('click') // TODO：如果可以解绑事件监听的话
        this.myChart.off('mouseover')
        this.myChart.off('mouseout')

        echarts.dispose(this.myChart)
        this.myChart = update ? undefined : null
      }
      var chartDom = document.getElementById(this.randomId)
      var myChart = echarts.init(chartDom, 'dark')

      // myChart.showLoading();

      map_config = map_config || [
        // ['广东省', '珠海市'],
        // ['北京市'],
        // [ "天津市", "天津市" ],
        // [ "广东省", "广州市" ],
        // [ "广东省", "广州市", "花都区" ],
        // [ "广西壮族自治区", "南宁市", "兴宁区" ],
        // [ "天津市", "天津市", "和平区" ],
        // [ "山西省", "太原市", "小店区" ],
        // [ "广东省", "茂名市", "茂南区" ],
        // [ "广东省", "茂名市", "高州市" ],
        // [ "广东省", "茂名市", "信宜市" ],
        ...(this.config.map_config || []),
      ]

      map_config = this.getFinalMapConfig(map_config)

      const features = []
      if (map_config[0][0] === '全国') {
        // 全国
      } else {
        await Promise.all(
          map_config.map(
            (m) =>
              new Promise(async (resolve) => {
                // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
                const jsonData = this.findJSONData(m)
                if (!jsonData) {
                  resolve()
                  return
                }
                const codePath = jsonData.codePath
                // console.log(m, jsonData)
                // console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~')
                // console.log(m, codePath, 'forEach(())))))))))))))))))))))')
                try {
                  if (codePath.length !== 1) {
                    // const json = require('@/modules/big-data/data/100000/' + codePath[0] + '/' + codePath[1] + '/' + codePath[2] + '.json')
                    const json = await this.getJson(
                      [...codePath, undefined, undefined, undefined].slice(0, 3)
                    )
                    // echarts.registerMap("MAP", json)
                    // features.push(...json.features)

                    json.features.forEach((f) => {
                      if (
                        !features.find(
                          (fea) => fea.properties.adcode === f.properties.adcode
                        )
                      ) {
                        features.push(f)
                      }
                    })
                  } else {
                    const localFeatures = await this.getLocalFeatures(codePath)
                    localFeatures.forEach((f) => {
                      if (
                        !features.find(
                          (fea) => fea.properties.adcode === f.properties.adcode
                        )
                      ) {
                        features.push(f)
                      }
                    })
                  }
                } catch {
                  // console.log('没有三级', m, codePath)
                  try {
                    if (codePath.length !== 2) {
                      // const json = require('@/modules/big-data/data/100000/' + codePath[0] + '/' + codePath[1] + '.json')

                      const json = await this.getJson(
                        [...codePath, undefined, undefined, undefined].slice(
                          0,
                          2
                        )
                      )

                      const buildJson = {
                        type: 'FeatureCollection',
                        features: json.features.filter((f) => {
                          return (
                            f.properties.adcode.toString() ===
                            codePath[codePath.length - 1]
                          )
                        }),
                      }
                      if (buildJson.features.length === 0)
                        buildJson.features.push(...json.features)

                      // features.push(...buildJson.features)
                      buildJson.features.forEach((f) => {
                        if (
                          !features.find(
                            (fea) =>
                              fea.properties.adcode === f.properties.adcode
                          )
                        ) {
                          features.push(f)
                        }
                      })
                    } else {
                      const localFeatures = await this.getLocalFeatures(codePath)
                      localFeatures.forEach((f) => {
                        if (
                          !features.find(
                            (fea) =>
                              fea.properties.adcode === f.properties.adcode
                          )
                        ) {
                          features.push(f)
                        }
                      })
                    }
                  } catch (err) {
                    // console.log('没有二级', codePath)
                    // const json = require('@/modules/big-data/data/100000/' +
                    //   codePath[0] +
                    //   '.json')

                    const json = await this.getJson(codePath.slice(0, 1))

                    const buildJson = {
                      type: 'FeatureCollection',
                      features: json.features.filter((f) => {
                        return (
                          f.properties.adcode.toString() ===
                          codePath[codePath.length - 1]
                        )
                      }),
                    }
                    // console.log(buildJson)
                    if (buildJson.features.length === 0)
                      buildJson.features.push(...json.features)
                    // features.push(...buildJson.features)
                    buildJson.features.forEach((f) => {
                      if (
                        !features.find(
                          (fea) => fea.properties.adcode === f.properties.adcode
                        )
                      ) {
                        features.push(f)
                      }
                    })
                    // echarts.registerMap("MAP", buildJson)
                  }
                }

                resolve()
              })
          )
        )
      }

      // console.log(features.length, '------------')
      if (features.length) {
        echarts.registerMap('MAP', {
          type: 'FeatureCollection',
          features,
        })
      } else if (map_config[0][0] === '全国') {
        echarts.registerMap('MAP', geoJson)
      } else {
        this.$message.error('缺少地图数据，请联系开发人员')
        myChart.hideLoading()
        return
      }

      myChart.setOption(this.getGeoOption(map_config))
      // 实际渲染的
      this.map_config = map_config

      let that = this
      myChart.on(
        'mouseover',
        debounce(function () {
          // console.log('on mouse over')
          that.pause()
        }),
        300
      )
      myChart.on(
        'mouseout',
        debounce(function () {
          // console.log('on mouse out')
          that.play()
        }),
        300
      )
      myChart.on('click', function (params) {
        if (!that.grid) return
        if (that.grid && !that.disabled) return

        // console.log(params);
        that.clickMap = true // 锁定
        // console.log('update map click lock')

        const dataIndex = params.dataIndex
        // const feature = features[dataIndex]
        console.log('rrrrrr click rrrrrrr')
        console.log(features, dataIndex)
        console.log('rrrrrrrrrrrrrrrrrrrr')

        that.handleSub(features, dataIndex)
      })

      if (!this.handle) {
        // if (this.myChart === undefined) {
        /** TODO: 自动点进下个层级区域进行展示 */
        setTimeout(() => {
          this.animate(features.length ? features : geoJson.features)
        }, 1500)
        // }
      }

      this.myChart = myChart
      myChart.hideLoading()
      this.loading = false
    },
    getJson(codePath = []) {
      let url
      switch (codePath.length) {
        case 3:
          {
            url =
              baseUrl +
              '/big-data/data/100000/' +
              codePath[0] +
              '/' +
              codePath[1] +
              '/' +
              codePath[2] +
              '.json?key=' +
                this.$store.state.tenant.id

            if (cachedFeatures[codePath.join('.')]?.features?.length) {
              return Promise.resolve(cachedFeatures[codePath.join('.')])
            }
          }

          break
        case 2:
          {
            url =
              baseUrl +
              '/big-data/data/100000/' +
              codePath[0] +
              '/' +
              codePath[1] +
              '.json?key=' +
                this.$store.state.tenant.id

            if (cachedFeatures[codePath.join('.')]?.features?.length) {
              return Promise.resolve(cachedFeatures[codePath.join('.')])
            }
          }
          break
        case 1: {
          url = baseUrl + '/big-data/data/100000/' + codePath[0] + '.json?key=' + this.$store.state.tenant.id

          if (cachedFeatures[codePath.join('.')]?.features?.length) {
            return Promise.resolve(cachedFeatures[codePath.join('.')])
          }
          break
        }
        default:
      }

      if (url.indexOf('undefined') !== -1) return Promise.reject({
        msg: 'url is undefined'
      })

      if (cachedFeatures[codePath.join('.')]?.features?.length === 0) {
        return Promise.reject({
          msg: 'no json data'
        })
      }

      return new Promise((resolve, reject) => {
        axios
          .get(url)
          .then(({ data }) => {
            this.setJson(codePath, data)
            resolve(data)
          })
          .catch((err) => {
            // Error 404
            this.setJson(codePath, {
              features: []
            })
            reject(err)
          })
      })
    },
    setJson(codePath, json) {
      if (cachedFeatures[codePath.join('.')] == undefined)
        cachedFeatures[codePath.join('.')] = json
    },
    async handleSub(features, featureIndex) {
      let that = this
      console.log('^^^^^^^^^^^ handle sub ^^^^^^^^^^')
      const feature =
        (features && features.length && features[featureIndex]) ||
        geoJson.features[featureIndex]
      console.log('feature:', feature)
      const codePath = [
        ...feature.properties.acroutes,
        feature.properties.adcode,
      ]
      if (codePath.includes(100000) || codePath.includes('100000')) {
        codePath.shift()
      }
      /* 下一级的区块 */
      const children = await that.getSubFeatures(codePath)

      console.log('code path: ', codePath)
      console.log('map_config: ', that.map_config)
      if (that.map_config.length === 1) {
        // 设定为 false 此后就会直接触发 backDirectly 而无需手动调用，大概是冒泡的原因 ？
        that.clickMap = false
        // that.backDirectly() // important！放开这句话会导致重复执行 backDirectly 哦，这里只是给个踩坑提醒
        return
      }
      console.log('featureIndex: ', featureIndex)
      console.log(
        'children: ',
        children.map((c) => c.properties.name)
      )
      console.log(this.mapContext)
      console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^')
      const geoCodePath = this.handleGeoCodePath(codePath)
      if (
        (children.length > 1 && this.level === 1 && !this.handle) ||
        (this.handle && children.length)
      ) {
        const map_config2 = children.map((ch) => {
          let map_config_item = [
            // feature.properties.name,
            ...that.map_config[featureIndex],
            ch.properties.name,
          ]
          return Array.from(new Set(map_config_item))
        })

        const localFeatures =
            features ?? (await that.getLocalFeatures(codePath))

        this.mapContext.push({
          map_config: that.map_config,
          sub_map_config: map_config2,
          features: features || localFeatures || children,
          subFeatures: children,
          codePath: codePath,
          level: that.level,
          index: featureIndex,
        })

        // if (codePath.length === 2) {
        //   this.displayText = `${map_config2[0][0]}${map_config2[0][1]}`
        // } else if (codePath.length === 1) {
        //   this.displayText = `${map_config2[0][0]}`
        // }
        // this.displayText2 = ''
        if (featureIndex === 0 || this.handle) that.level++

        that.getShowData({
          map_config: map_config2,
        })
      } else {
        console.log('自动 back')
        // 到底了，应该返回上层，有可能是市、省、全国
        that.clickMap = false
        that.back()
      }
    },
    async getLocalFeatures(codePath) {
      let arr = []

      if (codePath.length === 1) {
        arr = geoJson.features.filter(
          (f) => f.properties.adcode == codePath[0]
        )
      } else if (codePath.length === 2) {
        try {
          // const json = require('@/modules/big-data/data/100000/' +
          //   codePath[0] +
          //   '.json')
          const json = await this.getJson(codePath.slice(0, 1))

          const features = json.features.filter((f) => {
            return f.properties.adcode == codePath[codePath.length - 1]
          })
          if (!features.length) {
            // ['北京市', '北京市'] 等直辖市
             arr = geoJson.features.filter(
              (f) => f.properties.adcode == codePath[0]
            )
          } else {
            arr = features
          }
        } catch (e) {
          console.log('err', e)
        }
      } else if (codePath.length === 3) {
      }

      return Promise.resolve(arr)
    },
    async getSubFeatures(codePath) {
      let arr = []

      if (codePath.length === 1) {
        try {
          // const json = require('@/modules/big-data/data/100000/' +
          //   codePath[0] +
          //   '.json')
          const json = await this.getJson(codePath.slice(0, 1))
          const features = json.features
          this.codePath = codePath
          // console.log('update code path', 1, features.map(f => f.properties.name))
          // TODO: 真实数据太多，不方便测试，先截取一部分
          // return features.slice(0, 2)
          arr = features
        } catch (e) {
          console.log(e)
        }
      } else if (codePath.length === 2) {
        try {
          // const json = require('@/modules/big-data/data/100000/' +
          //   codePath[0] +
          //   '/' +
          //   codePath[1] +
          //   '.json')
          const json = await this.getJson(codePath)
          const features = json.features
          this.codePath = codePath
          // TODO: 真实数据太多，不方便测试，先截取一部分
          // return features.slice(0, 2)
          arr = features
        } catch (e) {
          console.log(e)
          arr = await this.getLocalFeatures(codePath)
        }
      } else if (codePath.length === 3) {
        try {
          // const json = require('@/modules/big-data/data/100000/' +
          //   codePath[0] +
          //   '/' +
          //   codePath[1] +
          //   '.json')
          const json = await this.getJson(codePath.slice(0, 2))
          const features = json.features.filter(
            (j) => j.properties.adcode === codePath[2]
          )
          this.codePath = codePath
          // TODO: 真实数据太多，不方便测试，先截取一部分
          // return features.slice(0, 2)
          arr = features
        } catch (e) {
          console.log(e)
        }
      }
      return Promise.resolve(arr)
    },
    handleGeoCodePath(codePath) {
      let arr = JSON.parse(JSON.stringify(codePath))
      // TODO: 处理直辖市，因为 geo json 没有了一些 xxx100 的区号
      if (
        arr &&
        arr.length >= 2 &&
        arr[0] === arr[1] &&
        ['北京市', '天津市', '上海市'].includes(arr[0])
      ) {
        // 类如：北京市/北京市/东城区 要转为 北京市/东城区
        return codePath.slice(1)
      }
      return codePath
    },
    /**
     * 点击非地图区域，直接返回上层
     */
    backDirectly() {
      if (this.grid && !this.disabled) return
      if (!this.grid) return
      // 避免和 myChart 的 click 事件冲突
      if (this.clickMap) return (this.clickMap = false)
      if (this.level === 1) {
        console.log('已经没得返回了')
        return
      }
      this.level--
      if (this.level === 1) {
        this.getShowData()
      } else {
        // TODO: 返回上级（不是一级），可否根据 mapContext 来
        console.log(this.mapContext, 'back directly')
        // 删除当前层
        this.mapContext.splice(this.mapContext.length - 1, 1)
        // 上一层
        const up = this.mapContext[this.mapContext.length - 1]
        console.log(up.sub_map_config, 'mmmmmmmmmmmmmmmmmmmmm')
        this.getShowData({
          map_config: up.sub_map_config,
        })
      }
    },
    back: function () {
      // 避免和 myChart 的 click 事件冲突
      if (this.clickMap) return (this.clickMap = false)
      if (this.level == 2) {
        const leader = this.mapContext[this.level - 2]
        const playIndex = leader.index
        if (playIndex >= leader.map_config.length - 1) {
          this.level--
          this.back()
        } else {
          leader.index++
          this.map_config = leader.map_config
          this.handleSub(leader.features, playIndex + 1)
        }
      } else {
        // this.displayText = ''
        this.getShowData()
        console.log('结束一轮')
      }

      // if (!this.codePath) return
      // const block = JSON.stringify(this.codePath)
      // const block = this.codePath.join('')
      // const map_config = this.config.map_config
      // map_config.forEach(m => {
      // //   const jsonData = this.findJSONData(m)
      // //   // TODO：处理其他的直辖市
      // //   const codePath = jsonData.codePath.filter(c => !['110100'].includes(c.toString()))
      // //   console.log(jsonData.label, codePath, this.codePath)
      // //   if (codePath.join('') !== block && codePath.join('').indexOf(block) !== -1) {

      // //   }
      //   const current = this.map_config[0][0]
      //   if (m[0] === current) {
      //
      //   }
      // })
    },
    stopAnimation() {
      // this.displayText = ''
      this.displayText2 = ''
      if (timer) {
        clearInterval(timer)
      }
      this.myChart?.dispatchAction({
        type: 'hideTip',
      })
      if (this.responseData && this.responseData[0] && anchor > 0) {
        const newList = this.responseData[0].value_list.map((v, vI) => {
          return {
            index: vI,
            value: v,
          }
        })
        this.myChart?.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: newList[anchor - 1].index,
        })
      }
      timer = null
      anchor = 0
    },
    animate(list) {
      let that = this
      if (this.grid && !this.disabled) return
      if (list.length && this.grid) {
        const interval = 2500
        if (timer) clearInterval(timer)
        timer = setInterval(() => {
          // const max = this.map_config.length
          // 过滤掉 0 的数据
          const newList = this.responseData[0].value_list.map((v, vI) => {
            return {
              index: vI,
              value: v,
            }
          })
          // .filter(v => !!v.value)
          const max = newList.length
          // console.log(anchor, max, 'xxxxxxxxxxxxxxx')
          if (anchor >= max && timer) {
            this.stopAnimation()
            // this.$emit('update')
            console.log('动画结束', that.level)

            // if (!that.handle) {
            //   anchor = this.responseData[0].value_list.findIndex(v => !!v)
            //
            //   that.handleSub(list, anchor)
            // }

            that.animate(list)
            return
          }

          // const feature = list[anchor]
          if (anchor > 0) {
            this.myChart.dispatchAction({
              type: 'downplay',
              seriesIndex: 0,
              dataIndex: newList[anchor - 1].index,
            })
          }

          if (newList[anchor] !== undefined) {
            const feature = list[newList[anchor].index]
            if (feature) {
              this.myChart.dispatchAction({
                type: 'highlight',
                // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
                // seriesIndex?: number,
                seriesIndex: 0,
                // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
                // dataIndex?: number,
                // dataIndex: anchor,
                dataIndex: newList[anchor].index,
                // geoIndex: anchor,
                // geoIndex: anchor,
                // 可选，数据项名称，在有 dataIndex 的时候忽略
                // name?: string,
                // 本次显示 tooltip 的位置。只在本次 action 中生效。
                // 缺省则使用 option 中定义的 tooltip 位置。
                // position: number[] | string | Function,
              })
              this.myChart.dispatchAction({
                type: 'showTip',
                // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
                // seriesIndex?: number,
                seriesIndex: 0,
                // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
                // dataIndex?: number,
                // dataIndex: anchor,
                dataIndex: newList[anchor].index,
                // geoIndex: anchor,
                // geoIndex: anchor,
                // 可选，数据项名称，在有 dataIndex 的时候忽略
                // name?: string,
                // 本次显示 tooltip 的位置。只在本次 action 中生效。
                // 缺省则使用 option 中定义的 tooltip 位置。
                // position: number[] | string | Function,
              })
              // this.displayText2 = `${feature.properties.name}: ${newList[anchor].value}`
            }
          }

          anchor++
        }, interval)
      }
      // 打断施法 QAQ
      // setTimeout(() => {
      //   this.stopAnimation()
      // }, 60 * 1000 * 0.5)
    },
    clickInstance(index) {
      this.pause()
      // 过滤掉 0 的数据
      const newList = this.responseData[0].value_list.map((v, vI) => {
        return {
          index: vI,
          value: v,
        }
      })
      console.log(index, anchor)
      /* 先取消高亮 */
      if (anchor > -1 && newList[anchor]) {
        this.myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: newList[anchor].index,
        })
      }
      if (anchor > 0) {
        this.myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: newList[anchor - 1].index,
        })
      }
      if (anchor > 1) {
        this.myChart.dispatchAction({
          type: 'downplay',
          seriesIndex: 0,
          dataIndex: newList[anchor - 2].index,
        })
      }
      // if (index === anchor) return

      anchor = index
      this.myChart.dispatchAction({
        type: 'highlight',
        // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
        // seriesIndex?: number,
        seriesIndex: 0,
        // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
        // dataIndex?: number,
        // dataIndex: anchor,
        dataIndex: newList[anchor].index,
        // geoIndex: anchor,
        // geoIndex: anchor,
        // 可选，数据项名称，在有 dataIndex 的时候忽略
        // name?: string,
        // 本次显示 tooltip 的位置。只在本次 action 中生效。
        // 缺省则使用 option 中定义的 tooltip 位置。
        // position: number[] | string | Function,
      })
      this.myChart.dispatchAction({
        type: 'showTip',
        // 系列的 index，在 tooltip 的 trigger 为 axis 的时候可选。
        // seriesIndex?: number,
        seriesIndex: 0,
        // 数据项的 index，如果不指定也可以通过 name 属性根据名称指定数据项
        // dataIndex?: number,
        // dataIndex: anchor,
        dataIndex: newList[anchor].index,
        // geoIndex: anchor,
        // geoIndex: anchor,
        // 可选，数据项名称，在有 dataIndex 的时候忽略
        // name?: string,
        // 本次显示 tooltip 的位置。只在本次 action 中生效。
        // 缺省则使用 option 中定义的 tooltip 位置。
        // position: number[] | string | Function,
      })
    },
    /* 简化省市名称 */
    getAliasName(entireName) {
      let aliasName = entireName
      if (/^.*[省|市]$/.test(entireName)) {
        aliasName = entireName.slice(0, entireName.length - 1)
      } else if (/^[广西|西藏|新疆|香港|澳门|宁夏].*$/.test(entireName)) {
        aliasName = entireName.slice(0, 2)
      } else if (/^[内蒙古].*$/.test(entireName)) {
        aliasName = entireName.slice(0, 3)
      }
      return aliasName
    },
    getGeoOption(map_config) {
      // 立体效果
      let geoList = []
      // for (let index = 0; index < 10; index++) {
      //   geoList.push({
      //     map: "MAP",
      //     zoom: 1,
      //     zlevel: 0,
      //     label: {
      //       show: false,
      //       color: "#ffffff",
      //       fontSize: 16,
      //     },
      //     itemStyle: {
      //       // areaColor: '#07183F',
      //       areaColor: 'rgba(4,39,45,0.01)',
      //       // areaColor: '#efefef',
      //       shadowColor: '#1863DE',
      //       // shadowColor: 'rgba(75, 210, 237, 0.5)',
      //       // shadowColor: '#f1f1f1',
      //       shadowOffsetY: index * 1.5,
      //       shadowOffsetX: 0,
      //     },
      //   })
      // }

      // 先判断 展示会员，还是单位
      // const typeIndex = anchor >= this.config.map_config.length ? 1 : 0
      const typeIndex = 0
      // const dataIndex = typeIndex ? (anchor - map_config.length) : anchor
      const personal = (this.responseData && this.responseData[typeIndex]) || {
        name: '个人会员',
        map_list: map_config.map((m) => [this.getAliasName(m[m.length - 1])]),
        value_list: map_config.map((m) => 0),
        percent_list: map_config.map((m) => 0),
      }

      if (map_config[0][0] === '全国') map_config = personal.map_list
      if (personal.map_list[0][0] === '全国') {
        const allProvinces = geoJson.features.map((f) => [f.properties.name])
        personal.map_list = allProvinces
        map_config = allProvinces
        personal.value_list = allProvinces.map(() => 0)
        personal.percent_list = allProvinces.map(() => 0)
      }

      return {
        backgroundColor: 'rgba(3, 23, 26, .5)',
        // backgroundColor: 'var(--bg-color)',
        // backgroundColor: 'transparent',
        // title: {
        //   text: true ||  this.isConfigEmpty ? '' : (personal.name +
        //     ' - ' +
        //     this.config.data_show[typeIndex ? 'unit_user' : 'personal_user']?.label),
        //   subtext: '',
        //   top: 20,
        //   left: 20,
        //   sublink:
        //     'http://zh.wikipedia.org/wiki/%E9%A6%99%E6%B8%AF%E8%A1%8C%E6%94%BF%E5%8D%80%E5%8A%83#cite_note-12'
        // },
        tooltip: {
          trigger: 'item',
          position: 'top',
          // position: function (point, params, dom, rect, size) {
          //   // 鼠标坐标和提示框位置的参考坐标系是：以外层div的左上角那一点为原点，x轴向右，y轴向下
          //   // 提示框位置
          //   var x = 0; // x坐标位置
          //   var y = 0; // y坐标位置
          //
          //   // 当前鼠标位置
          //   var pointX = point[0];
          //   var pointY = point[1];
          //
          //   // 外层div大小
          //   // var viewWidth = size.viewSize[0];
          //   // var viewHeight = size.viewSize[1];
          //
          //   // 提示框大小
          //   var boxWidth = size.contentSize[0];
          //   var boxHeight = size.contentSize[1];
          //
          //   // boxWidth > pointX 说明鼠标左边放不下提示框
          //   if (boxWidth > pointX) {
          //     x = 5;
          //   } else { // 左边放的下
          //     x = pointX - boxWidth;
          //   }
          //
          //   // boxHeight > pointY 说明鼠标上边放不下提示框
          //   if (boxHeight > pointY) {
          //     y = 5;
          //   } else { // 上边放得下
          //     y = pointY - boxHeight;
          //   }
          //
          //   return [x, y];
          // },
          formatter: (params) => {
            if (!params.data) return
            const aliasName =
              params.data.name === '\n'
                ? this.getAliasName(this.getMapConfigProvinces(map_config)[0])
                : params.data.name
            // return '会员人数<br/>' + aliasName + '&nbsp;&nbsp;' + params.data.value + '人'

            return (
              params.seriesName +
              '</br>' +
              "<div style='display: flex; flex-direction: column;min-width: 120px;'>" +
              "<div style='display: flex; justify-content: space-between;'>" +
              '<span>' +
              aliasName +
              '</span>' +
              '<span>' +
              params.data.value +
              '人</span>' +
              '</div>' +
              "<div style='display: flex; justify-content: space-between;'>" +
              '<span>占比率</span>' +
              '<span>' +
              params.data.percent +
              '%' +
              '</span>' +
              '</div>' +
              '</div>'
            )
          },
        },
        toolbox: {
          show: false,
          orient: 'vertical',
          left: 'right',
          top: 'center',
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        visualMap: {
          // show: false,
          min: 0,
          max: Math.max(...personal.value_list) || 9999,
          left: 20,
          bottom: 20,
          text: ['High', 'Low'],
          // text: ['多', '少'],
          realtime: false,
          calculable: true,
          inRange: {
            // color: ['lightskyblue', 'yellow', 'orangered']
            // color: ['rgba(237,183,75,0.1)', 'rgba(130,100,250,0.1)', 'rgba(75,237,223,0.1)', 'rgba(75,210,237,0.1)', 'rgba(75,237,156,0.1)']
            // color: ['rgba(237,183,75,0.5)', 'rgba(130,100,250,0.5)', 'rgba(75,237,223,0.5)', 'rgba(75,210,237,0.5)', 'rgba(75,237,156,0.5)']
            // color: ['rgba(237,183,75,0.7)', 'rgba(130,100,250,0.7)', 'rgba(75,237,223,0.7)', 'rgba(75,210,237,0.7)', 'rgba(75,237,156,0.7)']
            // color: ['rgba(237,183,75,1)', 'rgba(130,100,250,1)', 'rgba(75,237,223,1)', 'rgba(75,210,237,1)', 'rgba(75,237,156,1)'],
            // color: [
            //   '#5470c6', '#91cc75', '#fac858',
            //   '#ee6666', '#73c0de', '#3ba272',
            //   '#fc8452', '#9a60b4', '#ea7ccc'
            // ],
            // colorAlpha: [0.1, 0.1, 0.1, 0.1, 0.1],
            // colorAlpha: [0.3, 0.3, 0.3, 0.3, 0.3],
            // colorAlpha: [0.6, 0.6, 0.6, 0.6, 0.6],
            // colorAlpha: [0.5, 0.5, 0.5, 0.5, 0.5],

            color: [
              '#0d2e35',
              '#4BD2ED',
              '#4BEDDF',
              '#4BED9C',
              '#EDB74B',
              '#ED4B66',
            ],
          },
        },
        geo: geoList,
        series: [
          {
            seriesIndex: 0,
            // (personal.name + ' - ' + this.config.data_show[typeIndex ? 'unit_user' : 'personal_user']?.label)||
            name: '会员人数',
            type: 'map',
            // geoIndex: 0, //指定geo属性后，series-map.map 属性，以及 series-map.itemStyle 等样式配置不再起作用，而是采用 geo 中的相应属性。
            map: 'MAP',
            label: {
              show: false,
              color: 'white',
            },
            z: 2,
            coordinateSystem: 'geo',
            showLegendSymbol: true,
            zoom: 1,
            selectedMode: true,
            data: (() => {
              if (!map_config) return []
              const isManyCities =
                !this.isContainTwoProvincesAtLeast(map_config) &&
                this.isContainTwoCitiesAtLeast(map_config)
              return map_config.map((m) => {
                const current = personal.map_list.findIndex((mm) => {
                  return JSON.stringify(mm) === JSON.stringify(m)
                })
                const entireName = m[m.length - 1]
                let aliasName = this.getAliasName(entireName)

                return {
                  percent:
                    (personal.percent_list && personal.percent_list[current]) ||
                    0,
                  value: personal.value_list[current],
                  // name: m[m.length - 1],
                  // name: isManyCities && m.length === 1 ? '\n' : (m[m.length - 1]),
                  // name: isManyCities && m.length === 1 ? '\n' : current.toString(),
                  // name: isManyCities && m.length === 1 ? '\n' : ('test' + current.toString()),
                  name: isManyCities && m.length === 1 ? '\n' : aliasName,
                  ...this.calcStyle(
                    personal.value_list[current],
                    Math.max(...personal.value_list)
                  ),
                }
              })
            })(),
            // 自定义名称映射
            nameMap: (() => {
              const map = {
                /* 右边是 series.data.name 实际展示的值 */
                // '广州市': '广州'
              }
              personal.map_list.forEach((m) => {
                const entireName = m[m.length - 1]
                let aliasName = this.getAliasName(entireName)
                if (aliasName !== entireName) map[entireName] = aliasName
              })
              if (!map_config) return []
              const isManyCities =
                !this.isContainTwoProvincesAtLeast(map_config) &&
                this.isContainTwoCitiesAtLeast(map_config)
              if (isManyCities) {
                const entireName = this.getMapConfigProvinces(map_config)[0]
                map[entireName] = '\n'
              }
              return map
            })(),
            // emphasis: {
            //   itemStyle: {
            /* highlight 和 downplay action 时的区域底色 */
            //     areaColor: 'red'
            //   }
            // }
          },
        ],
        // geo: {
        //   map: "MAP",//上面引入的数据名
        //   show: true,
        //   roam: false,//关闭拖拽
        //   label: {  //地图显示的地点名
        //     show: true,
        //     color: "#fff",
        //     fontSize: 16,
        //   },
        //   itemStyle: {
        // //     // areaColor: "#99CBF9", //地图区域的颜色(没有数据时会按照这个颜色显示)
        // //     // areaColor: "rgba(75,237,223,0.1)", //地图区域的颜色(没有数据时会按照这个颜色显示)
        // //     borderColor: "#fff",  //地图区域的边框
        // //     borderWidth: 0.6,
        //     areaColor: 'transparent'
        //   },
        // //   emphasis: {  //高亮的显示设置
        // //     label: {
        // //       color: "#fff",
        // //     },
        // // //     itemStyle: {
        // // //       // areaColor: "rgba(75,237,223,0.1)",
        // // //     },
        // //   },
        //   select: { //选中显示设置
        //     label: {
        //       color: "#fff",
        //     },
        //     itemStyle: {
        //       areaColor: "rgba(75,237,223,0.3)",
        //     },
        //   },
        // }
      }
    },
    calcStyle(val, max = 999) {
      const styleList = []
      // for (let i = 0; i < 20; i++) {
      let i = Math.floor(val / (max / 5)) - 1
      if (i < 0) i = 0
      // const colorList = [
      //   [237, 183, 75],
      //   [130, 100, 250],
      //   [75, 237, 223],
      //   [75, 210, 237],
      //   [75, 237, 156]
      // ]

      // const min = 100 * i
      // const max = 100 * i * i + 100
      // console.log(val, min, max)
      // if (val < max && val >= min) {
      // console.log('========================')

      // const RGB = colorList[i] || [0, 0, 0]
      // const R = RGB[0]
      // const G = RGB[1]
      // const B = RGB[2]

      styleList.push({
        itemStyle: {
          // select: {
          //   areaColor: `rgba(${R},${G},${B},0.5)`,
          // },
          areaColor: '#0d2e35',
          emphasis: {
            // areaColor: `rgba(${R},${G},${B},0.5)`,
            // areaColor: 'black',
            // borderWidth: 1
          },
          normal: {
            // areaColor: `rgba(${R},${G},${B},0.1)`,
            // areaColor: 'rgba(3, 23, 26, .5)',
            //   borderColor: `rgb(${R},${G},${B})`,
            //   borderWidth: 0.6
            // borderColor: 'white'
            // borderColor: '#062326',
            // borderColor: colorList[Math.round(max / val)]
            // borderColor: 'transparent',
            borderColor: '#000',
          },
          // select: {
          //   areaColor: 'black',
          // }
        },
        emphasis: {
          label: {
            show: true,
            color: '#fff',
          },
        },
        label: {
          // 地图显示的地点名
          show: true,
          fontSize: 15,
          // color: `rgb(${R},${G},${B})`,
          color: val === 0 ? '#4BD2ED' : '#fff',
          // emphasis: {
          //   color: '#fff'
          // }
        },
      })
      // break
      // }
      // }
      return styleList[0]
    },
    findJSONData(arr, level = 1, children = null, codePath = []) {
      // console.log(arr, arr[0] === arr[1])
      // TODO: 处理直辖市，这里直辖市也要转成三级的形式，否则会匹配不到
      const cityList = ['北京市', '天津市', '上海市', '重庆市']
      if (
        arr &&
        arr.length === 2 &&
        arr[0] !== arr[1] &&
        cityList.includes(arr[0])
      ) {
        // 类如：北京市/东城区，会在最前面加"北京市"，得到"北京市/北京市/东城区"
        arr = [arr[0], ...arr]
      } else if (arr && arr.length === 1 && cityList.includes(arr[0])) {
        // 类如；北京市，会在最前面加"北京市"，得到"北京市/北京市"
        arr = [arr[0], ...arr]
      }
      if (children && children.length === 0) return {}
      children = children || address || []
      for (let i = 0; i < children.length; i++) {
        // console.log(level, children[i].label, arr[level - 1])
        if (children[i].label === arr[level - 1]) {
          if (level === arr.length) {
            codePath.push(children[i].value)
            // console.log('最后一级', codePath)
            return {
              ...children[i],
              codePath,
            }
          }
          codePath.push(children[i].value)
          return this.findJSONData(
            arr,
            level + 1,
            children[i]?.children || [],
            codePath
          )
        }
      }
    },
    /**
     * @deprecated
     * @param label
     * @param children
     */
    findCode(label, children = null) {
      if (children && children.length === 0) return -1
      children = children || address
      for (let i = 0; i < children.length; i++) {
        if (children[i].label === label) {
          return children[i].value
        }
        this.findCode(label, children[i]?.children || [])
      }
    },
    getFinalMapConfig(c) {
      const config = JSON.parse(JSON.stringify(c))
      if (config.length === 0) {
        const defaultMapConfig = [['全国']]
        config.push(...defaultMapConfig)
      } else {
        // config.forEach(mc => {
        //   if (mc.length > 1) {
        //     if (!config.find(mm => mm[0] === mc[0] && mm.length === 1)) {
        //       console.log('没有勾选到省', mc[0])
        //       config.push([mc[0]])
        //     }
        //   }
        // })
        if (this.isContainTwoProvincesAtLeast(config)) {
          return this.getMapConfigProvinces(config).map((p) => [p])
        } else if (
          this.isContainTwoCitiesAtLeast(config) &&
          !(this.level > 1)
        ) {
          console.log('都是同一个省，但是有多个市', this.grid)
          return [
            [this.getMapConfigProvinces(config)[0]],
            ...this.getMapConfigCities(config).map((c) => {
              return [this.getMapConfigProvinces(config)[0], c]
            }),
          ]
        }
      }
      return config
    },
    /* 看看 map_config 到底涵盖了几个省份 */
    getMapConfigProvinces(c) {
      return Array.from(new Set(c.map((cc) => cc[0])))
    },
    /* 看看 map_config 到底涵盖了几个城市，前提：都是同一个省的 */
    getMapConfigCities(c) {
      return Array.from(
        new Set(c.map((cc) => cc[1] || '').filter((el) => !!el))
      )
    },
    isContainTwoProvincesAtLeast(c) {
      const pList = this.getMapConfigProvinces(c)
      return pList.length > 1
    },
    isContainTwoCitiesAtLeast(c) {
      const cList = this.getMapConfigCities(c)
      return cList.length > 1
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@/modules/big-data/scss/template.scss';

.context {
  position: absolute;
  /* 相对于自身的 font-size */
  top: 0;
  right: 0.16em;
  z-index: 20;
}

.chart-box {
  /*position: absolute;*/
  width: 100%;
  height: 100%;
  /*top: 0;*/
}

.corner {
  /*width: 230px;*/
  width: 14.375em;
  border-radius: 0.5em;
  border: 0.0625em solid rgba(75, 210, 237, 0.1);
  background: rgba(8, 21, 24, 0.7);
  font-size: 0.16em;
  overflow: hidden auto;

  position: absolute;
  /* 相对于自身的 font-size */
  right: 1em;
  bottom: 1em;
  z-index: 20;

  .corner-head {
    color: rgba(255, 255, 255, 0.4);
    line-height: 2.5625em;
  }

  .corner-body {
    /* <!--height: 170px - 41px;-->*/
    height: 8.0625em;

    tr:hover {
      background: linear-gradient(
        90deg,
        rgba(75, 210, 237, 0.3) 0%,
        rgba(75, 210, 237, 0.05) 100%
      );
      cursor: pointer;

      td:first-child {
        display: flex;
        /*align-items: center;*/

        &:before {
          content: '';
          width: 0.125em;
          height: 2.25em;
          background: var(--hover-color);
          display: inline-block;
          margin-left: -0.75em;
          margin-right: 0.625em;
        }
      }
    }

    .no-wrap {
      @include nowrap();
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;

    td {
      padding: 0 0.75em;
      line-height: 2.25;
      white-space: nowrap;
    }
  }

  tr > td:first-child {
    max-width: 5.5625em; // 89px
  }
}
</style>
