<template>
  <div class="svg-border go-border-box">
    <!-- black bottom layer -->
    <svg class="svg-border" :width="w" :height="h">
      <defs>
        <radialGradient id="grad2" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
          <!-- 内圈 -->
          <stop offset="90%" style="stop-color:inherit;stop-opacity:0" />
          <!-- 外圈 -->
<!--          <stop offset="100%" style="stop-color:rgba(75,210,237,0.12);stop-opacity:0.15" />-->
          <stop offset="100%" style="stop-color:rgba(75, 210, 237, 0.1);stop-opacity:0.01" />
        </radialGradient>
      </defs>
      <polygon :fill="'url(#grad2)' || backgroundColor" :points="`
      0, ${cornerW}
      ${cornerW}, 0
      ${w - cornerW}, 0
      ${w}, ${cornerW}
      ${w}, ${h - cornerW}
      ${w - cornerW}, ${h}
      ${cornerW}, ${h}
      0, ${h - cornerW}
      `" />
    </svg>
    <!-- solid blue line with animation of fill color changing -->
    <svg
        :width="w"
        :height="h"
        :key="item"
        v-for="item in borders"
        :class="`border-item ${item}`"
        :style="{'--b-w': borderW + 'px'}"
    >
      <!-- 定义一个渐变 -->
<!--      <defs>-->
<!--        <linearGradient id="grad1" x1="0%" y1="0%" x2="50%" y2="70%">-->
<!--          <stop offset="0%" style="stop-color:rgba(75, 210, 237, 1);stop-opacity:1" />-->
<!--          <stop offset="100%" style="stop-color:transparent;stop-opacity:1" />-->
<!--        </linearGradient>-->
<!--      </defs>-->
      <polygon
          :points="`
              0, ${cornerW}
      ${cornerW}, 0
      ${w / 2}, 0
      ${w / 2}, ${borderW}
      ${cornerW + 2}, ${borderW}
      ${borderW}, ${cornerW + 2}
      ${borderW}, ${(h) / 2}
      0, ${(h) / 2}
          `"
      >
        <!--
        fill="url(#grad1)"
        -->
        <!-- 动画：属性 attributeName 在 values（用分号隔开） 之间变化 -->
<!--        <animate-->
<!--            attributeName="fill"-->
<!--            values="rgba(75, 210, 237, 0);rgba(75, 210, 237, 0.7);rgba(75, 210, 237, 0)"-->
<!--            :dur="`${dur}s`"-->
<!--            begin="0s"-->
<!--            repeatCount="indefinite"-->
<!--        />-->
      </polygon>
    </svg>
    <!-- blue line width linear gradient and animation of opacity changing -->
    <svg
        :width="w"
        :height="h"
        :key="item"
        v-for="item in borders2"
        :class="`border-item2 ${item}`"
        :style="{'--b-w': borderW + 'px'}"
    >
      <defs>
        <linearGradient id="grad1" x1="0%" y1="0%" x2="50%" y2="70%">
          <stop offset="0%" style="stop-color:rgba(75, 210, 237, 1);stop-opacity:1" />
          <stop offset="100%" style="stop-color:transparent;stop-opacity:1" />
        </linearGradient>
      </defs>
      <polygon
          :points="`
              0, ${cornerW}
      ${cornerW}, 0
      ${w / 2}, 0
      ${w / 2}, ${borderW}
      ${cornerW + 2}, ${borderW}
      ${borderW}, ${cornerW + 2}
      ${borderW}, ${(h) / 2}
      0, ${(h) / 2}
          `"
          fill="url(#grad1)"
      >
<!--        <animate-->
<!--            attributeName="opacity"-->
<!--            values="0.5;1;0.5"-->
<!--            :dur="`${dur}s`"-->
<!--            begin="0s"-->
<!--            repeatCount="indefinite"-->
<!--        />-->
      </polygon>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'MemberMapBorder',
  props: {
    w: {
      type: Number,
      default: 0
    },
    h: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      borders: ['left-top', 'right-top', 'left-bottom', 'right-bottom'],
      borders2: ['left-top2', 'right-top2', 'left-bottom2', 'right-bottom2'],
      backgroundColor: 'rgba(3, 23, 26, 1)',
      dur: 1.5,
      cornerW: 24,
      borderW: 3,
    }
  },
  created() {
    // console.log('border created')
  },
  mounted() {
    // console.log('border mounted')
  }
}
</script>

<style lang="scss" scoped>
  $namespace: 'go';

  @mixin go($block) {
    $B: $namespace + '-' + $block;
    .#{$B} {
      @content;
    }
  }

  @include go('border-box') {
    .border-item, .border-item2 {
      position: absolute;
      top: 0;
      left: 0;
    }
    .right-top, .right-top2 {
      right: 0;
      transform: rotateY(180deg);
    }
    .left-bottom, .left-bottom2 {
      bottom: 0;
      /*transform: rotateX(180deg) translateY(var(--b-w));*/
      transform: rotateX(180deg);
    }
    .right-bottom, .right-bottom2{
      right: 0;
      bottom: 0;
      /*transform: rotateX(180deg) rotateY(180deg) translateY(var(--b-w));*/
      transform: rotateX(180deg) rotateY(180deg);
    }
  }
</style>
