<template>
  <div class="data-empty flex-center">
    <span class="text">该板块暂无数据</span>
  </div>
</template>

<script>
export default {
  name: "DataEmpty",
}
</script>

<style lang="scss" scoped>
  .data-empty {
    width: 1.89em;
    height: .53em;
    border-radius: .08em;
    background: rgba(0,0,0,0.8);
    backdrop-filter: blur(32px);
    margin: 0 auto;

    .text {
      color: rgba(255,255,255,1);
      font-size: .18em;
      text-align: center;
    }
  }
</style>
