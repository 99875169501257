import api from "@/base/utils/request";

export const getHomeArticleData = (data) => {
  return api({
    url: '/admin/data_view/homePage/articleData',
    method: 'post',
    data,
    notCancel: true
  })
}

export const getHomeActivityData = (data) => {
  return api({
    url: '/admin/data_view/homePage/activityData',
    method: 'post',
    data,
    notCancel: true
  })
}

export const getHomeCircleData = (data) => {
  return api({
    url: '/admin/data_view/homePage/cycCircleData',
    method: 'post',
    data,
    notCancel: true
  })
}

export const getHomeClassroomData = (data) => {
  return api({
    url: '/admin/data_view/homePage/courseData',
    method: 'post',
    data,
    notCancel: true
  })
}

export const getHomeCircleTopicData = (data) => {
  return api({
    url: '/admin/data_view/homePage/circleTopicData',
    method: 'post',
    data,
    notCancel: true
  })
}

export const getHomeCompanyCategoryData = () => {
  return api({
    url: '/admin/data_view/homePage/companyData',
    method: 'post',
    notCancel: true
  })
}

/**
 * 用户增长趋势
 * @param data
 * @return {*}
 */
export const getHomeUserIncreaseData = (data) => {
  return api({
    url: '/admin/data_view/homePage/userIncreaseData',
    method: 'post',
    data,
    notCancel: true
  })
}

/**
 * 新增会员
 * @param data
 * @return {*}
 */
export const getHomeNewMemberData = data => {
  return api({
    url: '/admin/data_view/homePage/newMemberData',
    method: 'post',
    data,
    notCancel: true
  })
}

/**
 * 会员画像
 * @param data
 */
export const getHomeMemberPhotoData = (data) => {
  return api({
    url: '/admin/data_view/homePage/userPhotoData',
    method: 'post',
    data,
    notCancel: true
  })
}

export const getHomeMemberMapData = (data) => {
  return api({
    url: '/admin/data_view/homePage/memberMapData',
    method: 'post',
    data,
    notCancel: true
  })
}

/**
 * 会费收入
 */
export const getHomeMemberFeeData = (data) => {
  return api({
    url: '/admin/data_view/homePage/memberFee',
    method: 'post',
    data,
    notCancel: true
  })
}

export const getHomeHomeOverviewData = (data) => {
  return api({
    url: '/admin/data_view/homePage/overview',
    method: 'post',
    data,
    notCancel: true
  })
}

export const getHomeCirclePostData = (data) => {
  return api({
    url: '/admin/data_view/homePage/circlePostData',
    method: 'post',
    data,
    notCancel: true
  })
}
