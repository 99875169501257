<template>
  <div v-if="responseData" class="template-card-content article-post flex-col"
       @mouseover="pause"
       @mouseout="play"
  >
    <template-card-summary v-if="!isEmpty"
                           :diff_count="responseData.diff_count"
                           :status="responseData.status"
                           :compare_type="compareType"
                           @sort-by-time="toggleDesc">
      <span class="total">共发布 <span class="emphasis">{{responseData.total}}</span> 个</span>
    </template-card-summary>
    <component :is="grid?'el-scrollbar':'div'" v-if="!isEmpty" class="dynamic flex-1">
      <div class="list-wrapper" :class="{
        two: getData().w >= 2,
        grid,
        'flex-col': !grid
      }"
           @mouseover="pause"
           @mouseout="play"
      >
        <div v-for="item in (grid ?responseData.list.data: responseData.list.data.slice(0, 1))" class="grid-item flex" :id="item.id">
          <div class="bg flex-col flex-between">
            <div class="flex-between" style="width: 100%;">
              <span class="corner top-left" />
              <span class="corner top-right" />
            </div>
            <div class="flex-between" style="width: 100%;">
              <span class="corner bottom-left" />
              <span class="corner bottom-right" />
            </div>
          </div>
          <div class="flex-col flex-1" style="">
            <div class="line-one flex">
              <div class="flex-col flex-between flex-1" style="align-items: flex-start;">
                <p class="paragraph">{{item.content}}</p>
                <div class="line-two flex-between">
                  <div class="user flex-align-center">
                    <img class="avatar" :src="fixImageUrl(item.avatar || defaultAvatar, 100)" style="object-fit: cover;" />
                    <span class="user-name">{{item.nickname}} 发布</span>
                  </div>
                  <span class="time">{{item.create_time}}</span>
                </div>
              </div>
              <img v-if="item.images && item.images.length" class="photo" :src="item.images[0].url" />
            </div>

            <div class="vector" />
            <div class="footer">
              <div class="flex-center">
                <img src="../../../assets/like.png" class="icon" />
                <span class="value">{{item.like_count}}</span>
              </div>
              <div class="flex-center">
                <img src="../../../assets/comment.png" class="icon" />
                <span class="value">{{item.comments_count}}</span>
              </div>
              <div class="flex-center">
                <img src="../../../assets/share.png" class="icon" />
                <span class="value">{{item.comments_count}}</span>
              </div>
              <div class="flex-center">
                <img src="../../../assets/hot.png" class="icon" />
                <span class="value hot">{{item.comments_count}}</span>
              </div>
            </div>
          </div>
<!--          <div class="icon-box">-->
<!--            <img class="icon" :src="fixImageUrl(item.cover, 200)" draggable="false" />-->
<!--          </div>-->

        </div>
      </div>
    </component>
    <div v-if="isEmpty" class="flex-1 flex-center">
      <data-empty />
    </div>
    <div v-if="!isEmpty" class="blur" />
  </div>
</template>

<script>
import { getHomeCirclePostData } from "@/modules/big-data/api/templates";
import { randomString } from "@/base/utils/tool";
import DataEmpty from "@/modules/big-data/components/DataEmpty";
import TemplateCardSummary from "@/modules/big-data/components/TemplateCardSummary";
import template from "@/modules/big-data/mixins/list-template";

export default {
  name: 'circle-post',
  mixins: [template],
  components: {TemplateCardSummary, DataEmpty},
  inject: ['getData', 'grid', 'disabled'],
  props: {

  },
  data() {
    return {
      compareType: 0,
      defaultAvatar: window.serverConfig.VUE_APP_ADMINURL + "/cyc/images/wxapps/icons/avatar1.png",
    }
  },
  methods: {
    getShowData(query, callback) {
      this.stopAnimation()
      const queryObj = {
        compare_type: 0, // 周、月、年
        start_time: -1, // 开始时间
        end_time: -1,
        ...(this.queryObj || {}),
        is_desc: this.desc??1,	// 是否倒序
        page: 1, // 页码
        ...query,
      }
      getHomeCirclePostData(queryObj).then(res => {
        res.data.list.data = res.data.list.data.map(el => ({
          ...el,
          id: randomString(5)
        }))
        this.responseData = res.data
        this.queryObj = queryObj
        this.compareType = queryObj.compare_type

        if (!this.isEmpty) {
          // 动画开关
          setTimeout(() => {
            this.animate()
          }, 1500)
          callback()
        } else {
          // const next = (queryObj.compare_type + 1) % 3
          //
          // this.$emit('update', {
          //   compare_type: next
          // })
          callback()
        }
      }).catch(err => {
        console.log(err)
        callback()
      })
    },
    animate() {
      if (this.grid && !this.disabled) return
      const list = this.listData
      if (list.length && this.grid) {
        this.buildTimer()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  @import "~@/modules/big-data/scss/template.scss";

  .list-wrapper {
    width: 100%;
    height: 100%;
    padding: 0 .16em;

    &.two {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: .08em;

      .grid-item {
        margin-top: 0;
      }
    }

    .grid-item {
      padding: .12em .12em 0 0.12em;
      margin-top: .08em;
      position: relative;
      /*border: .02em solid rgba(60,168,190,1);*/
      border: 0.005em solid rgba(60,168,190,.5);

      &:first-child {
        margin-top: 0;
      }

     /* .top, .left, .right, .bottom {
        position: absolute;
        !*background-color: var(--bg-color);*!
        background-color: #0A333A;
      }

      .top, .bottom {
        !*height: .04em;*!
        !*width: calc(100% - 0.16em * 2);*!
      }

      .top {
        top: -0.03em;
        left: .16em;
      }

      .bottom {
        bottom: -0.03em;
        right: .16em;
      }

      .left, .right {
        !*height: calc(100% - 0.16em * 2);*!
        !*width: .04em;*!
      }

      .left {
        left: -0.03em;
        top: .16em;
      }

      .right {
        right: -.03em;
        top: .16em;
      }*/

      .bg {
        background: rgba(75,210,237,0.1);
        position: absolute;
/*        top: -0.035em;
        left: -0.035em;*/
/*        top: -.005em;
        left: -.005em;*/
        top: 0;
        left: 0;
        /*width: calc(100% + .005em * 2);*/
        /*height: calc(100% + .005em * 2);*/
        width: 100%;
        height: 100%;
        /*border: 0.005em solid rgba(60,168,190,.5);*/
      }
    }
  }

  .value {
    font-size: .18em;
    line-height: 1.278;
    font-weight: bolder;
    white-space: nowrap;

    &.hot {
      color: #EDB74B;
    }
  }

  .line-one {
    margin-bottom: .12em;

    .paragraph {
      color: rgba(255,255,255,1);
      font-size: .14em;
      font-weight: bold;
      @include clamp(3);
      min-width: 0;
/*      height: 1em * 1.5 * 3;*/
    }

    .photo {
      width: .9em;
      height: .9em;
      margin-left: .08em;
      flex-shrink: 0;
      object-fit: cover;
    }
  }

  .line-two {
    margin-top: .12em;
    width: 100%;

    .time {
      color: rgba(255,255,255,1);
      font-size: .14em;
      font-weight: bolder;
      opacity: 0.8;
    }
  }

  .user {
    .avatar {
      background-color: #D9D9D9;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: .08em;
      flex-shrink: 0;
    }
  }

  .user-name {
    opacity: 0.8;
    color: rgba(255,255,255,1);
    font-size: .14em;
    font-weight: bold;
  }

  .corner {
    width: .16em;
    height: .16em;
    position: relative;

    &:before, &:after {
      content: "";
      background-color: rgba(60,168,190,1);
      display: block;
      position: absolute;
    }

    &:before {
      width: .04em;
      height: 100%;
      left: 0;
    }

    &:after {
      width: 100%;
      height: .04em;
      bottom: 0;
    }

    &.top-left {
      transform: rotate(90deg);
    }

    &.top-right {
      transform: rotate(180deg);
    }

    &.bottom-right {
      transform: rotate(270deg);
    }
  }

  .vector {
    width: 100%;
    height: 0;
    opacity: 0.10000000149011612;
    border: 0.01em solid rgba(60,168,190,1);
    margin-top: .12em;
  }

  .footer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: .11em 0 .12em;

    &>.flex-center:last-child {
      .value {
        color: #EDB74B;
      }
    }

    .icon {
      width: .16em;
      height: .16em;
      flex-shrink: 0;
      margin-right: 0.08em;
      /*background-color: #cccccc;*/
    }

    .value {
      color: rgba(255,255,255,1);
      font-size: .12em;
      font-weight: bold;
    }
  }
</style>
