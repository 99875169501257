var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-increase flex-center",on:{"mouseout":_vm.play,"mouseenter":_vm.pause,"mouseover":_vm.pause}},[(!_vm.isEmpty)?_c('div',{staticClass:"chart-box"},[_c('div',{staticClass:"left-tips"},[_c('div',{staticClass:"label-box"},[_c('div',{staticClass:"top login"},[_vm._v(" 已登录人数"),_c('span',[_vm._v(_vm._s(this.responseData.login_compare.total))]),_vm._v("人 ")]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"ratio"},[_vm._v(" 环比 "),(this.responseData.login_compare.status)?_c('span',{style:({
                color:
                  this.responseData.login_compare.status == 1
                    ? '#f0c475'
                    : '#fc9697',
              })},[_vm._v(_vm._s(this.responseData.login_compare.diff_count)+"%")]):_c('span',[_vm._v(_vm._s(this.responseData.login_compare.diff_count)+"%")]),(this.responseData.login_compare.status === 2)?_c('img',{staticClass:"down-icon",attrs:{"src":require("../../../assets/users-stat/down.png")}}):(this.responseData.login_compare.status === 1)?_c('img',{staticClass:"up-icon",attrs:{"src":require("../../../assets/users-stat/up.png")}}):_vm._e()])])]),_c('div',{staticClass:"label-box"},[_c('div',{staticClass:"top no_login"},[_vm._v(" 未登录人数 "),_c('span',[_vm._v(_vm._s(this.responseData.no_login_compare.total))]),_vm._v(" 人 ")]),_c('div',{staticClass:"bottom"},[_c('div',{staticClass:"ratio"},[_vm._v(" 环比 "),(this.responseData.no_login_compare.status)?_c('span',{style:({
                color:
                  this.responseData.no_login_compare.status == 1
                    ? '#f0c475'
                    : '#fc9697',
              })},[_vm._v(_vm._s(this.responseData.no_login_compare.diff_count)+"%")]):_c('span',[_vm._v(_vm._s(this.responseData.no_login_compare.diff_count)+"%")]),(this.responseData.no_login_compare.status === 2)?_c('img',{staticClass:"down-icon",attrs:{"src":require("../../../assets/users-stat/down.png")}}):(this.responseData.no_login_compare.status === 1)?_c('img',{staticClass:"up-icon",attrs:{"src":require("../../../assets/users-stat/up.png")}}):_vm._e()])])])]),_c('div',{staticClass:"chart",attrs:{"id":_vm.randomId}})]):_vm._e(),(_vm.isEmpty)?_c('div',{staticClass:"flex-1 flex-center"},[_c('data-empty')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }