<template>
  <div v-if="responseData" class="home-overview">
    <div
      class="grid"
      :class="{
        two: getData().w >= 2,
      }"
    >
      <div v-for="item in options" class="grid-item flex-align-center">
        <img
          class="icon"
          :src="responseData.list[item.key].icon"
          draggable="false"
        />
        <div class="flex-col">
          <div class="line-one flex">
            <span class="label">{{ item.name }}</span>
            <span class="value">{{ responseData.list[item.key].total }}</span>
          </div>
          <!--
 "status": 0,//环比状态，0：无变化，1：上升，2：下降
"diff_count": 0, //上升or下降的值
-->
          <div v-if="compare_type !== 5" class="line-two flex" style="margin-top: 0.12em">
            <span class="label">{{ '环比' || typeArr[compare_type] }}</span>
            <span
              class="value"
              :class="{
                up: responseData.list[item.key].status === 1,
                down: responseData.list[item.key].status === 2,
              }"
              >{{ responseData.list[item.key].diff_count }}%<img
                v-if="responseData.list[item.key].status === 2"
                class="down-icon"
                src="../../../assets/down.png" /><img
                v-else-if="responseData.list[item.key].status === 1"
                class="up-icon"
                src="../../../assets/up.png"
            /></span>
            <!--            <i class="el-icon-top" style="font-size: .20em;" />-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getHomeHomeOverviewData } from "@/modules/big-data/api/templates";

var timer = null;
var anchor = 0;

export default {
  name: "home-overview",
  inject: ["getData", "grid", "disabled"],
  data() {
    return {
      responseData: null,
      // 环比类型：0：周，1：月，2：年，3：近三月，4：自定义开始结束时间, 5：至今
      typeArr: ["周环比", "月环比", "年环比", "近三个月环比", "", "至今"],
      compare_type: 0,
    };
  },
  computed: {
    options() {
      // const options = this.$store.state.bigDataDesign.data_show['home_overview'] || []
      // return [...options.map((r) => {
      //   return r.list[0]
      // })]
      const data_show = this.getData().config.data_show || [];
      if (!data_show.length || !this.grid) {
        return [
          {
            name: "会员",
            key: "member_data",
          },
          {
            name: "活动",
            key: "activity_data",
          },
          {
            name: "企业",
            key: "company_data",
          },
          {
            name: "资讯",
            key: "article_data",
          },
        ];
      }
      return data_show;
    },
  },
  watch: {
    options: {
      handler(val, oldVal) {
        if (JSON.stringify(val) !== JSON.stringify(oldVal)) {
          this.responseData = null;
          this.$emit("update", {
            data_keys: val.map((el) => el.key),
            compare_type: this.compare_type,
          });
        }
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.stopAnimation();
  },
  methods: {
    stopAnimation() {
      // if (timer) clearInterval(timer)
      // timer = null
      // anchor = 0
    },
    /**
     * @deprecated
     */
    animate() {
      if (!this.grid && this.disabled) return;
      const max = this.typeArr.length;
      const typeIndex = anchor % max;

      setTimeout(() => {
        this.responseData = null;
        this.$emit("update", {
          data_keys: this.options.map((el) => el.key),
          compare_type: typeIndex,
        });
      }, 5500);
    },
    getShowData(query = {}, callback = () => {}) {
      // 假数据
      if (!this.grid) {
        const baseUrl = window.serverConfig.VUE_APP_ADMINURL;
        this.responseData = {
          list: {
            member_data: {
              total: 302,
              status: 2,
              diff_count: 2800,
              icon: baseUrl + "/admin/images/data_view/user.png",
            },
            activity_data: {
              total: 86,
              status: 1,
              diff_count: 200,
              icon: baseUrl + "/admin/images/data_view/company.png",
            },
            article_data: {
              total: 109,
              status: 0,
              diff_count: 0,
              icon: baseUrl + "/admin/images/data_view/user.png",
            },
            company_data: {
              total: 185,
              status: 2,
              diff_count: 200,
              icon: baseUrl + "/admin/images/data_view/company.png",
            },
          },
          update_time: 1672210475,
        };
        callback();
        return;
      }
      // 停止动画
      this.stopAnimation();
      // 请求参数
      const queryObj = {
        compare_type: 0,
        ...query,
        data_keys: this.options.map((el) => el.key),
      };
      this.compare_type = queryObj.compare_type;
      anchor = (this.compare_type + 1) % this.typeArr.length;
      getHomeHomeOverviewData(queryObj)
        .then((res) => {
          this.responseData = res.data;

          callback();
          // 轮流切换 周、月、年环比
          // this.animate()
        })
        .catch((err) => {
          console.log(err);
          callback();
        });
    },
    /**
     * @deprecated
     * @param key 
     * @return {any}
     */
    getIcon(key) {
      const ICON_MAP = {
        member_data: "home_overview_2",
        company_data: "home_overview_1",
        article_data: "",
        account_total_price: "home_overview_3",
      };
      if (ICON_MAP[key]) {
        return require("@/modules/big-data/assets/" + ICON_MAP[key] + ".png");
      }
      return require("@/modules/big-data/assets/home_overview_4.png");
    },
  },
};
</script>

<style lang="scss" scoped>
.home-overview {
  padding: 0.22em 0.1em 0.21em 0.16em;
  flex: 1;
  min-height: 0;
}

.grid {
  display: grid;
  /*grid-template-rows: repeat(2, 1fr);*/
  grid-template-columns: auto auto;
  width: 100%;
  height: 100%;
  grid-row-gap: 0.24em;
  /*grid-column-gap: .28em;*/

  &.two {
    /*grid-template-columns: repeat(4, 1fr);*/
  }

  .grid-item {
    padding-right: 0.12em;
  }
}

.icon {
  width: 0.68em;
  height: 0.68em;
  flex-shrink: 0;
  margin-right: 0.08em;
}

.label {
  font-size: 0.14em;
  line-height: 1.357;
  /* 相对自身的 font-size */
  margin-right: 0.857em;
  white-space: nowrap;
}

.value {
  font-size: 0.18em;
  /*line-height: 1.278;*/
  line-height: 1;
  font-weight: bolder;
  white-space: nowrap;

  &.up {
    color: var(--hover-color);
  }

  &.down {
    color: $danger;
  }
}

.line-two {
  .label {
    font-weight: bold;
  }
}

.line-one,
.line-two {
  align-items: flex-end;
}

.down-icon,
.up-icon {
  width: 0.857em;
  height: 0.857em;
  /*margin-left: 0.857em;*/
  display: inline-block;
  vertical-align: center;
  margin-left: 0.4em;
}

.up-icon {
  animation: 0.7s up infinite;
}

.down-icon {
  animation: 0.7s down infinite;
}

@keyframes up {
  from {
    transform: translateY(0%);
    opacity: 1;
  }

  to {
    transform: translateY(-25%);
    opacity: 0.85;
  }
}

@keyframes down {
  from {
    transform: translateY(0%);
    opacity: 1;
  }

  to {
    transform: translateY(25%);
    opacity: 0.85;
  }
}
</style>
