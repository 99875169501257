import api from "../../../base/utils/request";
import download from "@/base/utils/download";

export const getIndex = data => {
  return api({
    url: '/admin/admin/log/index',
    method: 'post',
    data
  })
}

export const getModules = data => {
  return api({
    url: '/admin/admin/log/modules',
    method: 'post',
    data
  })
}

// 导出
export const logDocument = data => {
  return download({
    url: "/admin/admin/log/export",
    method: "post",
    data,
    download: true
  });
};

export const getTester = () => {
  return api({
    url: '/admin/cyc/user_view/testUserId',
    method: 'post'
  })
}
