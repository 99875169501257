import store from "@/base/store"; //vuex
import { randomString } from "@/base/utils/tool";

export default {
  index: "member_interact",
  form: {
    data_show: [""],
    data_show_options: [{ name: "", key: "" }],
    users_limit: 9,
  },
  getRules(value) {
    // console.log(value, "value");
    return [
      // {
      //   type: "object",
      //   field: "data_show",
      //   value: { personal_user: {}, unit_user: {} },
      //   display: true,
      //   props: {
      //     rule: [
      //       {
      //         type: "select",
      //         field: "personal_user",
      //         title: "数据展示",
      //         display: true,
      //         props: {
      //           style: "width: 100%;",
      //           valueKey: "value",
      //           clearable: true,
      //         },
      //         children: (() => {
      //           return options.map((op) => {
      //             return {
      //               type: "ElOption",
      //               props: {
      //                 label: op.name,
      //                 value: op.key,
      //                 key: op.value,
      //               },
      //             };
      //           });
      //         })(),
      //       },

      //       {
      //         type: "select",
      //         field: "unit_user",
      //         title: "数据设置" || "数据展示",
      //         display: false,
      //         props: {
      //           style: "width: 100%;",
      //           valueKey: "value",
      //           clearable: true,
      //         },
      //         children: (() => {
      //           const data_show = store.state.bigDataDesign.data_show;
      //           const options = data_show.member_photo_unit_user?.list || [];
      //           return options.map((op) => {
      //             return {
      //               type: "ElOption",
      //               props: {
      //                 label: op.label,
      //                 value: op,
      //                 key: op.value,
      //               },
      //             };
      //           });
      //         })(),
      //       },
      //     ],
      //   },
      // },
      {
        type: "select",
        field: "data_show",
        title: "数据展示",
        value: [],
        options: (() => {
          const data_show = store.state.bigDataDesign.data_show;
          // console.log(data_show, "data_show");
          const options = data_show.member_interact.list || [];
          let arr = options.map((op, i) => {
            return {
              label: op.name,
              value: op.key,
              key: op.key,
            };
          });
          // console.log(arr);
          return arr;
        })(),
        props: {
          multiple: true,
          "collapse-tags": true,
        },
      },
      {
        type: "input-number",
        field: "users_limit",
        title: "人数展示",
        props: {
          min: 1,
          max: 30,
        },
      },
    ];
  },
};
