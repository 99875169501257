<template>
  <div class="single-member flex-align-center">
    <span class="status flex-center">
      <i class="el-icon-time" />
      <span class="status-text">{{ item.join_time }}</span>
    </span>
    <img class="avatar" :src="fixImageUrl(item.avatar || defaultAvatar, 150)" />
    <div class="label-box">
      <div class="con">
        <div class="box" :id="`${item.id}_news`">
          <p
            :id="`${item.id}_${item.name}`"
            class="label"
            :style="{
              paddingRight: `${gridData.w * 5}em`,
            }"
          >
            {{ item.name }} 加入协会，职务为 {{ item.position_name }}
          </p>
          <p
            class="label"
            :style="{
              paddingRight: `${gridData.w * 5}em`,
            }"
          >
            {{ item.name }} 加入协会，职务为 {{ item.position_name }}
          </p>
          <p class="label">
            {{ item.name }} 加入协会，职务为 {{ item.position_name }}
          </p>
          <!-- 占位 -->
          <div class="fill"></div>
        </div>
      </div>
      <div class="touch-box" :id="`${item.id}_touch_box`"></div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";

export default {
  name: "SingleMember",
  props: {
    item: Object,
    handle: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["getData", "grid", "disabled"],

  data() {
    return {
      defaultAvatar:
        window.serverConfig.VUE_APP_ADMINURL +
        "/cyc/images/wxapps/icons/avatar1.png",
    };
  },
  watch: {
    handle(newValue, oldValue) {
      // this.handleMoveDebounce(newValue, oldValue)
      this.handleMove(newValue, oldValue);
    },
  },
  computed: {
    gridData() {
      return this.getData();
    },
  },
  created() {
    // this.handleMoveDebounce = debounce(this.handleMove, 1500)
  },
  mounted() {
    // if (this.item.id === 'VXelknj9') {
    //   this.NewsLeft(); // 与 scrollIntoView 冲突
    // setTimeout(() => {
    this.NewsLeft();
    console.log(this.getData());
    // }, 3500)
    // }
  },
  methods: {
    handleMove(newValue, oldValue) {
      console.log("watch handle newValue", newValue, oldValue);
      if (newValue === true) {
        // this.reset()
        // setTimeout(() => {
        //   this.NewsLeft()
        // }, 1000)
      } else {
        // if (this.timer) clearInterval(this.timer)
      }
    },
    NewsLeft() {
      var speed = 12;
      var MyMar = null;
      // 滚动大盒子
      var newsLabelBox = document.getElementById(`${this.item.id}_touch_box`);
      var news = document.getElementById(this.item.id + "_news");
      // 一条的长度
      var newsLab = document.getElementById(
        `${this.item.id}_${this.item.name}`
      );
      // var new1 = document.getElementById(this.item.id + '_' + this.item.name)

      let that = this;
      function marquee() {
        // if (news.scrollLeft + news.offsetWidth >= (new1.offsetWidth * 2)) {
        if (news.scrollLeft >= newsLab.offsetWidth || !that.handle) {
          news.scrollLeft = 0;
        } else {
          news.scrollLeft++;
          // console.log(news.scrollLeft, newsLab.offsetWidth);
        }
      }

      // MyMar = setInterval(marquee, speed)
      newsLabelBox.onmouseover = function (e) {
        console.log("鼠标停留在会员组件", that.handle);
        // e.preventDefault();

        that.reset();
        // news.onmouseleave = null
        // news.onmouseout = null
        if (MyMar) clearInterval(MyMar);
        MyMar = setInterval(marquee, speed);
        that.timer = MyMar;
        that.$emit("stop");
      };
      newsLabelBox.onmouseout = function (e) {
        console.log("鼠标离开会员组件");
        that.reset();
        // news.onmouseover = null
        // news.onmouseleave = null
        news.scrollLeft = 0;
        that.$emit("start");
      };
      this.timer = MyMar;
    },
    reset() {
      if (this.timer) clearInterval(this.timer);
      this.timer = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-1 {
  flex: 1;
  min-width: 0;
  width: 100%;
}

.single-member {
  width: 100%;
  overflow: hidden;

  .status {
    min-width: 0.73em;
    height: 0.32em;
    background-color: rgba(75, 237, 156, 0.1);
    color: rgba(75, 237, 156, 1);
    padding: 0 0.12em 0 0.08em;
    flex-shrink: 0;

    .el-icon-time {
      font-size: 0.16em;
      margin-right: 0.35em;
    }

    .status-text {
      font-size: 0.14em;
      font-weight: bolder;
    }
  }

  .label-box {
    /*padding-right: .16em;*/
    /*overflow: auto;*/
    /*overflow-x: auto;*/
    /*overflow-y: hidden;*/
    flex: 1;
    display: flex;
    margin-right: 0.16em;
    overflow: hidden;
    position: relative;
    .con {
      max-width: 0;
      .box {
        display: flex;
        width: 99999px;
        white-space: nowrap;
        overflow: hidden;
        .label {
          font-size: 0.16em;
          font-weight: bold;
          line-height: 1;
          /*    @include nowrap();*/
          /*width: 0;*/
          /*float: left;*/
        }
        
        .fill {
          padding-right: 100%;
          // 目的：让子元素超出flex 容器宽度
        }
      }
    }
    .touch-box {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      // background: pink;
    }
  }

  .avatar {
    width: 0.32em;
    height: 0.32em;
    flex-shrink: 0;
    border-radius: 50%;
    margin-left: 0.12em;
    margin-right: 0.08em;
    object-fit: cover;
  }
}
</style>
