import api from "@/base/utils/request";

//获取用户详情
export const MemberDetail = data => {
  return api({
    url: "/admin/cyc/user_view/detail",
    method: "post",
    data
  });
};

// 获取用户加入的组织架构
export const MemberMessage = data => {
  return api({
    url: "/admin/cyc/user_view/myPosition",
    method: "post",
    data
  });
};

//修改用户组织架构
export const ChangePrePosition = data => {
  return api({
    url: "/admin/cyc/user/PrepareUserSave",
    method: "post",
    data
  });
};

// 获取选择列表数据
export const SelectOptions = data => {
  return api({
    url: "/admin/cyc/user/getConstant",
    method: "post",
    data
  });
};

// 获取组织架构列表
export const organizationList = data => {
  return api({
    url: "/admin/cyc/Organization/joinables",
    method: "post",
    data
  });
};

// 获取上级组织列表
export const ParentList = data => {
  return api({
    url: "/admin/cyc/Organization/OrganizationsTree",
    method: "post",
    data
  });
};

// 获取某组织会内职务
export const PostOptions = data => {
  return api({
    url: "/admin/cyc/position/organizationPositions",
    method: "post",
    data
  });
};

// 新增/降级/晋升/修改职务
export const ChangePosition = data => {
  return api({
    url: "/admin/cyc/user_op/changeUser",
    method: "post",
    data
  });
};

// 判断修改职务等级
export const changePositionFee = data => {
  return api({
    url: "/admin/cyc/user_op/changePositionFee",
    method: "post",
    data
  });
};

// 修改会费状态
export const ChangePayStatus = data => {
  return api({
    url: "/admin/cyc/user_op/payStatus",
    method: "post",
    data
  });
};

// 会员基础数据验证
export const userValidate = data => {
  return api({
    url: "/admin/cyc/user_op/validate",
    method: "post",
    data
  });
};

// 保存会员数据
export const SaveUser = data => {
  return api({
    url: "/admin/cyc/user_op/save",
    method: "post",
    data
  });
};

// 设为展示
export const onShow = data => {
  return api({
    url: "/admin/cyc/user_op/onShow",
    method: "post",
    data
  });
};

// 我的企业列表
export const CompanyList = data => {
  return api({
    url: "/admin/cyc/user_view/myCompanies",
    method: "post",
    data
  });
};

// 我的资源列表
export const myCirclesList = data => {
  return api({
    url: "/admin/cyc/user_view/myCircles",
    method: "post",
    data
  });
};

// 获取分类选择列表
export const CategoryList = data => {
  return api({
    url: "/admin/cyc_circle/category/all",
    method: "post",
    data
  });
};

// 我的活动列表
export const myActivityList = data => {
  return api({
    url: "/admin/cyc/user_view/myActivities",
    method: "post",
    data
  });
};

// 获取留言列表
export const MyAskList = data => {
  return api({
    url: "/admin/cyc/user_view/myAsks",
    method: "post",
    data
  });
};

// 我的订单列表
export const myOrderList = data => {
  return api({
    url: "/admin/cyc/user_view/myOrders",
    method: "post",
    data
  });
};

// 获取职务记录
export const PositionRecords = data => {
  return api({
    url: "/admin/cyc/user_view/myPositionRecords",
    method: "post",
    data
  });
};

// 获取被举报记录
export const MyReported = data => {
  return api({
    url: "/admin/cyc/user_view/myReported",
    method: "post",
    data
  });
};

// 获取转账凭证数据
export const myCredential = data => {
  return api({
    url: "/admin/cyc/credential/index",
    method: "post",
    data
  });
};

// 邀请好友收益
export const InviteRevenue = data => {
  return api({
    url: "/admin/cyc/user_view/myEarnings",
    method: "post",
    data
  });
};

// 成功邀请好友
export const InviteFriends = data => {
  return api({
    url: "/admin/cyc/user_view/myInvite",
    method: "post",
    data
  });
};


// 获取会员规则
export const getRule = () => {
  return api({
      url: '/admin/cyc/settings/getCycMemberConfigConfig',
      method: 'post'
  });
};
// 获取弹窗选择数据列表
export const dataList = (data) => {
  return api({
    url: "/admin/index/dialog/index",
    method: 'post',
    data,
    notCancel: true
  })
}

// 企业关联会员
export const assocUser = (data) => {
  return api({
    url: "/admin/cyc/company_data/assocUser",
    method: 'post',
    data,
    notCancel: true
  })
}
//获取选择会员列表
export const SearchMemberList = (data) => {
  return api({
    url: "/admin/cyc/user_view/selectInvitedUsers",
    method: "post",
    data,
    notCancel: true,
  });
};

// 获取所属架构/职务列表
export const positionLevel = (data) => {
  return api({
    url: "/admin/cyc/position/positionLevel",
    method: "post",
    data,
  });
};
//添加被邀请人
export const  addInviteUser  = (data)=>{
  return api({
    url: "/admin/cyc/user_view/addInviteUser",
    method: "post",
    data,
    notCancel:true
  })
} 
export const delInvite = (id)=>{
  return api({
    url: "/admin/cyc/user_view/delInvite",
    method: "post",
    data:{id},
  })
}