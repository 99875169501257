import api from "@/base/utils/request";

// 获取活跃会员组件数据
export const getMemberInteractData = (data) => {
  return api({
    url: '/admin/data_view/MemberStats/memberInteractData',
    method: 'post',
    data,
    notCancel: true
  })
}
// 获取会员统计组件数据
export const getUsersStatData = (data) => {
  return api({
    url: '/admin/data_view/MemberStats/usersStat',
    method: 'post',
    data,
    notCancel: true
  })
}
// 获取游客统计组件数据
export const getNonUsersStatData = (data) => {
  return api({
    url: '/admin/data_view/MemberStats/nonUserStats',
    method: 'post',
    data,
    notCancel: true
  })
}
// 获取架构人数组件数据
export const getOrganizationUsersData = (data) => {
  return api({
    url: '/admin/data_view/MemberStats/organizationUsers',
    method: 'post',
    data,
    notCancel: true
  })
}
// 获取职务人数组件数据
export const getPositionUsersData = (data) => {
  return api({
    url: '/admin/data_view/MemberStats/positionUsers',
    method: 'post',
    data,
    notCancel: true
  })
}
